html,body{
   
    width: 100%;
    height: 100%;
    overflow: hidden;
    overflow-y: auto !important;
    margin: 0;
    padding: 0;
	font-family: 'Montserrat', sans-serif;
}

a,  a:hover,a:focus{
    text-decoration: none !important;
}
.text-right {
    text-align: right;
}
.lds-roller {
	display: inline-block;
	position: relative;
	width: 80px;
	height: 80px;
	left: 45%;
	top: 20vh;
  }
  .lds-roller div {
	animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	transform-origin: 40px 40px;
  }
  .lds-roller div:after {
	content: " ";
	display: block;
	position: absolute;
	width: 7px;
	height: 7px;
	border-radius: 50%;
	background: rgb(90, 84, 187);
	margin: -4px 0 0 -4px;
  }
  .lds-roller div:nth-child(1) {
	animation-delay: -0.036s;
  }
  .lds-roller div:nth-child(1):after {
	top: 63px;
	left: 63px;
  }
  .lds-roller div:nth-child(2) {
	animation-delay: -0.072s;
  }
  .lds-roller div:nth-child(2):after {
	top: 68px;
	left: 56px;
  }
  .lds-roller div:nth-child(3) {
	animation-delay: -0.108s;
  }
  .lds-roller div:nth-child(3):after {
	top: 71px;
	left: 48px;
  }
  .lds-roller div:nth-child(4) {
	animation-delay: -0.144s;
  }
  .lds-roller div:nth-child(4):after {
	top: 72px;
	left: 40px;
  }
  .lds-roller div:nth-child(5) {
	animation-delay: -0.18s;
  }
  .lds-roller div:nth-child(5):after {
	top: 71px;
	left: 32px;
  }
  .lds-roller div:nth-child(6) {
	animation-delay: -0.216s;
  }
  .lds-roller div:nth-child(6):after {
	top: 68px;
	left: 24px;
  }
  .lds-roller div:nth-child(7) {
	animation-delay: -0.252s;
  }
  .lds-roller div:nth-child(7):after {
	top: 63px;
	left: 17px;
  }
  .lds-roller div:nth-child(8) {
	animation-delay: -0.288s;
  }
  .lds-roller div:nth-child(8):after {
	top: 56px;
	left: 12px;
  }
  @keyframes lds-roller {
	0% {
	  transform: rotate(0deg);
	}
	100% {
	  transform: rotate(360deg);
	}
  }
  
.progress{
    position: relative;
    height: 10px;
    width: 1110%;
    border: 10px solid #f4a261;
    border-radius: 15px;
}
.progress .color{
    position: absolute;
    background-color: #148f4b;
    width: 0px;
    height: 10px;
    border-radius: 15px;
    animation: progres 4s infinite linear;    
}
@keyframes progres{
    0%{
      width: 0%;
    }
    25%{
        width: 50%;
    }
    50%{
        width: 75%;
    }
    75%{
        width: 85%;
    }
    100%{
        width: 100%;
    }
};



h1, h2, h3, h4, h5, h6 {
font-weight: 500 !important;
}
p{
	font-size: 13px !important;
}
ul,ol{
	list-style-type: none;
	padding-left:0;
	margin-bottom:0;
}
.btn{
	border-radius: 0 !important;
	font-weight: 400 !important;
}
.ml-2 {
    margin-left: 1rem;
}
/* reset closed */

/* common style closed */
.main-header {
	background: #666ee8;
}

.main-header .header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.main-header .header .menu-icon {
	display: flex;
	padding: 11px 0px;
	align-items: center;
}
.main-header .header .menu-icon {
    -webkit-text-stroke: 0.2px #5A8DEE !important;
    color: #fff;
	font-size: 24px;
}
.logo-text{
    padding-left: 1rem;
    letter-spacing: 0.01rem;
    font-size:1.37rem;
}

.round {
	border-radius: 100%;
}

.prof-item {
	display: flex;
	align-items: center;
}
.user-nav span {
	color: #fff;
    display: inline-block;
	text-align: right;
	margin-right: 0.2rem;
}
.stroke-primary{
    -webkit-text-stroke:0.2px #5A8DEE;
    color: #fff;
}
.widget-right {
	display: flex;
	align-items: center;
}
.screen-resizer {
    margin-right: 30px;
}
.screen-resizer span {
	font-size: 32px;
	color: #475F7B;
	vertical-align: middle;
	cursor: pointer;
	-webkit-text-stroke: 0.2px #FFFFFF;
	display: inline-block;
}

.menu-horizontal{
    background-color: #ffffff;
    border-bottom: 1px solid #DFE3E7;
}
.nav-item {
	margin-right: 10px;
	position: relative;
}

.nav-item .nav-link {
	display: flex;
	align-items: center;
	font-size: 15px;
	color: #151617;
	padding: 10px 0;
	position: relative;
	padding-right: 18px;
	font-weight: 400;
	padding: 0.565rem calc(1rem - 0.0625rem);
}
.active{
  /* background-color: #e5edfc !important; */
  color: #5a8dee !important;
  border-radius: 0.3125rem;
}
.nav-item .nav-link .material-icons{
    margin-right: 0.4rem;
	font-size: 16px;
}
.nav-item .nav-link .material-icons.expand-row {
	position: absolute;
	right: 0;
	margin-right: 0;
	top: 15px;
}
.menu-list {
	margin-bottom: 12px;
}
.menu-list:last-child{
	margin-bottom:0;
}
.menu-list .nav-link{
	padding: 0 10px;
}
.nav-item.visible .cus-dropdown{
	display: block;
}
.navbar-expand {
    flex-wrap: wrap !important;
    justify-content: flex-start;
}
/* .nav-item.visible .expand-row {
	transform: rotate(-180deg);
} */
.cus-dropdown {
	min-width: 204px;
	border: 1px solid #DFE3E7;
	padding: 0.7rem 0;
	position: absolute;
	background-color: #fff;
	box-shadow: -7px 8px 16px 0px rgb(55 70 95 / 20%);
	border-radius: 0px;
	top: 100%;
	left:0;
	right: auto;
	display: none;
}
.menu-horizontal {
	background-color: #ffffff;
	border-bottom: 1px solid #DFE3E7;
	position: fixed;
	top: 60px;
	z-index: 999 !important;
	left: 0;
	right: 0;
	bottom: auto;
	width: 100%;
}

.primary-text{
	color: #5a8dee;
}
.mob-logo {
	padding: 10px 0px 0;
	display: none;
}
.close-btn {
	position: absolute;
	right: 8px;
	top: 15px;
	display: none;
}
.header-navheading {
    padding: 5px 10px 0;
    text-align: center;
}
.profile.dropdown-menu {
    right: 7px;
    top: 52px;
	border-radius: 0px !important;
}
.profile.dropdown-menu {
    min-width: 12rem !important;
	display: block;
}
.dropdown-menu::before {
    content: '';
    width: 10px;
    height: 10px;
    background: #fff;
    position: absolute;
    top: -6px;
    right: 11px;
    transform: rotate(45deg);
    border-top: 1px solid #e1e6f1;
    border-left: 1px solid #e1e6f1;
}
@media only screen and (min-width: 768px) {
.chat-dropdown.dropdown-menu.show {
    transform: translate(-113px, 37px) !important;
}}
/* .nav-item .nav-link .expand-row {
	
} */
.main-content {
	background-color: #eff1f9 !important;
    padding-top: 20px;
    padding-bottom: 30px;
	min-height: calc(100vh - 155px);
	padding-left: 20px !important;
    padding-right: 20px !important;
}
.main-title {
	font-size: 18px;
	padding-top: 5px;
}
.MuiTypography-h6 {
    font-size: 1rem !important;
    color: #000 !important;
}
.footer-area {
    /* position: fixed; */
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #fff;
    text-align: center;
 }
 footer p {
	font-size: 12px;
 }
 .card {
	box-shadow: none !important;
	border: 0px solid rgba(0,0,0,.125) !important;
	border-radius: 0px !important;
 }
 .MuiPaper-elevation2 {
    box-shadow: none !important;
}
 .card-header {
    padding: 0.5rem 1rem;
    margin-bottom: 0;
    background-color: transparent !important;
    border-bottom: 0px solid rgba(0,0,0,.125) !important;
}
 .card-title {
	font-family: 'Montserrat', sans-serif;
	font-weight: 600 !important;
 }
 .user-info {
    font-weight: 500;
    min-width: 65px !important;
}
 .product-info {
    font-weight: 500;
    min-width: 125px !important;
}
.quotation-info {
    font-weight: 500;
    min-width: 120px !important;
}
.product-image {
    max-height: 85px;
    object-fit: cover;
    margin: 10px 0;
    min-height: 85px;
}
.card-text {
    font-size: 14px;
    display: flex;
    justify-content: flex-start;
}
 .roundedcircle {
    border-radius: 50%;
}
.user-detail {
    font-size: 16px;
    font-weight: 500;
    margin-left: 15px;
    vertical-align: top;
    text-transform: capitalize;
}
.dropdown-toggle::after{
	display: none !important;
}
.dropdown-toggle.btn {
    padding: 0 !important;
    width: 30px;
    height: 30px;
    border-radius: 50% !important;
}
.dropdown-menu{
	min-width: 8rem !important;
}
.dropdown-item{
	font-size: 14px;
}
.dropdown-toggle.btn-light {
	color: #000;
    background-color: transparent;
    border-color: transparent;
}
.dropdown-toggle.btn-light:hover {
	color: #000;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
	box-shadow: none !important;
}
.btn-check:focus+.btn-warning, .btn-warning:focus,
.btn-check:focus+.btn-info, .btn-info:focus, .form-select:focus ,
.btn-check:active+.btn-success:focus, .btn-check:checked+.btn-success:focus, 
.btn-success.active:focus, .btn-success:active:focus, 
.show>.btn-success.dropdown-toggle:focus,
.btn-check:focus + .btn, .btn:focus {
    box-shadow: none !important;
}
.form-control.is-open:focus {
    /* border-color: #ffca2c; */
	background: transparent;
}
.form-control.is-open{
	background: transparent;
}
.form-control:focus {
    border-color: #666ee8 !important;
}
 label {
    margin-bottom: 8px;
}
.form-control:focus, .btn-check:focus+.btn-primary, .btn-primary:focus {
	box-shadow: none !important;
}
.form-control {
	margin-bottom: 10px !important;
	border-radius: 0 !important;
}
.form-control[type=file] {
    height: fit-content !important;
    margin-top: 10px;
}
.form-select {
	border-radius: 0px !important;
}
.btn-upload {
    padding: 8px 40px !important;
    background-color: #dee2e6 !important;
    border-radius: 0 !important;
    color: #212529 !important;
	height: 42px;
    width: 150px;
}
.card.product {
    background: linear-gradient(281deg, #b4b8ff, #dbe4ff);
}
.custom-file-input {
	z-index: 9;
  }
.upload-img {
    display: inline-block;
    width: 60px;
    height: 60px;
    object-fit: cover;
    object-position: 50% 50%;
    border: 3px solid #f7f7f7;
    margin-left: 10px;
    vertical-align: middle;
	background-color: #f8f9fa;
	overflow: hidden;
}
.modal-content {
	border-radius: 0 !important;
}
.MuiMenuItem-root {
    font-size: 0.8rem !important;
    line-height: 1 !important;
}
.MuiTableCell-head {
    font-weight: 600 !important;
}
.MuiTypography-h6 {
    font-weight: 600;
    font-family: Montserrat, sans-serif !important;
}
.MuiPaper-rounded {
    border-radius: 0px !important;
}
.MuiDataGrid-columnHeaderTitle {
    font-weight: 600 !important;
}
.MuiDataGrid-columnHeaders {
    background-color: #5a8dee;
    color: #fff;
}
.MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel {
    padding-top: 12px;
}
/* .MuiTableCell-root {
    padding: 5px 10px !important;
} */
li.go2449291367.go57541571 {
    color: #222;
    border-bottom: 4px solid #666ee8;
    font-weight: 500;
}
li.go2449291367.go2593884988{
	color: #222;
    border-bottom: 4px solid #666ee8;
    font-weight: 500;
}
.line {
    border: 1px solid #ebebeb;
    margin: 15px 0;
}
.css-1s2u09g-control {
    border-radius: 0 !important;
}
.css-1pahdxg-control:hover{
	border-radius: 0 !important;
}
.css-1pahdxg-control:focus{
	border-radius: 0 !important;
}
.next-btn, .submit-btn {
    margin-right: 25px;
}
.prev-btn{
	margin-left: 25px;
}
td.MuiTableCell-root {
	font-size: 13px !important;
	font-weight: 400 !important;
	padding: 5px 16px !important;
	line-height: 1.2 !important;
}
th.MuiTableCell-root {
    font-size: 13px !important;
    font-weight: 600 !important;
    padding: 8px 16px !important;
    line-height: 1.2 !important;
}
@media(max-width:1199.98px){
	.main-content {
		padding-top: 10px;
	   }
	.hide-menu{
		margin-left: -260px;
	}
	.close-btn {
		display:block;
	}
	.mob-logo {
		padding: 10px 0px 0;
		display: block;
		margin-bottom: 10px;
	}
	.mobile-nav {
		position: absolute;
		top: 0px;
	}
	.menu-horizontal{
		width: 260px;
		min-height: 100vh;
		color: #727E8C;
    	background: #F2F4F4;
    	border-right:1px solid #DFE3E7;
		top: 0;
	}
	.nav-item {
		margin-right: 0;
		position: relative;
		width: 100%;
	}
	.cus-dropdown {
		min-width: 100%;
		position: static;
		top: 100%;
		left: 0;
		right: 0;
		width: 100%;
		background-color: #fff;
		border: 0px solid #dfe3e7;
		border-radius: 0px;
		 box-shadow: none;
	}
	.sticky-top {
		position: absolute !important;
	}
	.navbar {
		position: absolute !important;
	}
}
@media (min-width: 600px){
.MuiToolbar-gutters {
    padding-left: 16px !important;
    padding-right: 16px !important;
}}

@media (max-width: 767px){
.MuiInputBase-root{
	width: 100% !important;
}}



.magnifierContainer {
	display: none;
  }
  
  .close, .open {
    display: none;
}
.search-input{
	display: none;
}
  
  @media screen and (max-width: 600px) {
	.magnifierContainer {
	  display: block;
	  margin-right: 6px;
	}
	
	.open {
		display: block;
		
	  }
	  .close {
		display: none;
		
	  }
  }

  .nav-pills .nav-item .nav-link{
	display:  inline-block;
  }
  .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    color: #fff !important;
    background-color: #666ee8 !important;
}

.nav-pills .nav-link {
    background: 0 0;
    border: 0;
    border-radius: 0 !important;
}
.nav-pills .nav-item, .nav-pills .show>.nav-link {
    background-color: #fcfcfc ;
}
nav-fill .nav-item .nav-link, .nav-justified .nav-item .nav-link {
    font-size: 18px;
}
.border-right {
    border-right: 1px solid #e1e6f1 !important;
}
.description-block {
    display: block;
    margin: 5px 0;
    text-align: center;
}
.form-check-input {
	margin-top: 0 !important;
 }
 .form-check-input:focus{
	 box-shadow: none !important;
 }
 .daterangepicker .calendar-table th, .daterangepicker .calendar-table td{
	 font-size: 12px !important;
	 line-height: 10px !important;
 }
 .daterangepicker{
	 width: auto !important;
	 right: 36px !important;
 }
 .daterangepicker .drp-calendar {
    max-width: 356px !important;
}

.daterangepicker td.active, .daterangepicker td.active:hover {
    color: #fff !important;
}
.daterangepicker:after{
	right: 11px;
}
.daterangepicker:before{
	right: 10px;
}
.btn-outline-primary {
    color: #212529 !important;
    border-color: #dbd8d8f5 !important;
	line-height: 1.6 !important;
}
.btn-check:focus+.btn-outline-primary, .btn-outline-primary:focus{
	box-shadow: none !important;
}
.badge {
    
    font-size: .75em !important;
    vertical-align: middle !important;
    border-radius: 0px !important;
	font-weight: 500 !important;
}
.cart-quantity {
    position: relative;
}
.notification-icon {
    height: 16px;
    width: 16px;
    border-radius: 50%;
    line-height: 15px;
    font-size: 10px;
    text-align: center;
    position: absolute;
    top: -3px;
    right: -5px;
}
.modal-title {
    font-weight: 400 !important;
}
.custom-table {
	border: 1px solid #e1e6f1;
	font-size: 14px !important;
	padding: 10px 16px !important;
}
.custom-table-header {
	border: 1px solid #e1e6f1;
	font-size: 12px !important;
	padding: 10px 16px !important;
}
.table>:not(:first-child) {
    border-top: 0px solid #d0d0d0 !important;
}
@media screen and (max-width: 40em){
.responsiveTable tbody tr {
    border: 1px solid #e1e6f1 !important;
    margin-bottom: 20px !important;
}
.responsiveTable td.pivoted {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
	font-size: 14px !important;
}
.responsiveTable td .tdBefore {
    font-size: 12px !important;
}
}
.description-list {
    text-align: left;
    font-size: 12px;
    margin: 0;
	padding-left: 0 !important;
}
.css-ha3bif-MuiTimelineItem-root:before {
	display: none !important;
}
.css-18y2uza-MuiTypography-root-MuiTimelineContent-root {
    font-size: 12px !important;
    line-height: 1.2 !important;
    padding: 1px 8px !important;
}
.css-ha3bif-MuiTimelineItem-root {
    min-height: 30px !important;
}
.css-v6mo1y-MuiTimelineDot-root {
    border-width: 0px !important;
    padding: 3px !important;
    margin: 3px 0 !important;
}
.cs-search {
    background-color: #ffffff !important;
    border-radius: 0em !important;
    border: 1px solid #ddd !important;
	margin-bottom: 0.2em !important;
}
.cs-search__input{
	background-color: #ffffff !important;
}
.MuiSvgIcon-root {
	font-size: 16px !important;
}
.accordion-button:focus {
	box-shadow: none !important;
}
.accordion-item:first-of-type {
    border-top-right-radius: 0rem !important;
    border-top-left-radius: 0rem !important;
}
.accordion-item:last-of-type {
    border-bottom-right-radius: 0rem !important;
    border-bottom-left-radius: 0rem !important;
}
/* .accordion-button {
	background-color: #e3e3e3 !important;
} */


/*  ----- 7.3 Chat   ----- */

@media (max-width: 991.98px) {
	.main-content-body-show .main-header-menu-icon {
		display: none;
	}
	.main-content-body-show .main-header-left .main-header-arrow {
		display: block;
	}
	.main-content-body-show .main-content-left-chat {
		display: none;
	}
	.main-content-body-show .main-content-body-chat {
		display: flex;
	}
}
.main-content-left-chat {
	width: 100%;
	position: relative;
	display: flex;
	flex-direction: column;
	padding: 0;
}
.main-nav-line-chat {
	border-bottom: 1px solid #e1e6f1;
	padding-bottom: 5px;
	flex-shrink: 0;
	flex-direction: row;
	padding-left: 20px;
}
.main-nav-line-chat .nav-link {
	font-size: 13px;
	font-weight: 500;
	color: #3b4863;
	text-transform: uppercase;
}
.main-nav-line-chat .nav-link:hover, .main-nav-line-chat .nav-link:focus, .main-nav-line-chat .nav-link:active {
	outline: none;
}
.main-nav-line-chat .nav-link+.nav-link {
	margin-top: 0;
	margin-left: 30px;
}
.main-nav-line-chat .nav-link.active {
	color: #f53163;
}
.main-nav-line-chat .nav-link.active::before {
	top: auto;
	left: 0;
	right: 0;
	width: auto;
	bottom: -6px;
	background-color: #f53163;
}
.main-chat-contacts-wrapper {
	padding: 10px 20px;
	flex-shrink: 0;
	border-bottom: 1px solid #e1e6f1;
	overflow: hidden;
}
@media (min-width: 992px) {
	.main-chat-contacts-wrapper {
		padding: 10px 15px 10px 0;
	}
}
.main-chat-contacts-wrapper .main-content-label, .main-chat-contacts-wrapper .card-table-two .card-title, .card-table-two .main-chat-contacts-wrapper .card-title, .main-chat-contacts-wrapper .card-dashboard-eight .card-title, .card-dashboard-eight .main-chat-contacts-wrapper .card-title {
	margin-bottom: 15px;
	line-height: 1;
}
.main-chat-contacts-wrapper .lSSlideOuter, .main-chat-contacts-wrapper .lSSlideWrapper {
	overflow: visible;
}
.main-chat-contacts {
	padding-left: 20px;
	display: flex;
}
.main-chat-contacts .lslide {
	display: flex;
	flex-direction: column;
	align-items: center;
	float: none;
}
.main-chat-contacts small {
	font-size: 11px;
	width: 36px;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	margin-top: 3px;
	text-align: center;
}
.main-chat-contacts-more {
	width: 36px;
	height: 36px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 100%;
	background-color: #f53163;
	font-size: 12px;
	font-weight: 500;
	color: #fff;
}
@media (min-width: 992px) {
	.main-chat-list {
		height: calc(100vh - 316px);
		position: relative;
		overflow: auto;
	}
}
.main-chat-list .media {
	padding: 12px 20px;
	border: 1px solid transparent;
	position: relative;
}
@media (min-width: 992px) {
	.main-chat-list .media {
		padding: 12px 15px;
	}
}
.main-chat-list .media .main-img-user {
	flex-shrink: 0;
	top: 3px;
}
.main-chat-list .media+.media {
	margin-top: -1px;
}
.main-chat-list .media+.media {
	border-top: 1px solid #e1e6f1;
}
.main-chat-list .media.new {
	background-color: #fff;
}
.main-chat-list .media.new .main-img-user span {
	display: flex;
}
.main-chat-list .media.new .media-contact-name span:first-child {
	font-weight: 500;
	color:#334151;
}
.main-chat-list .media.new .media-body p {
	color:#334151;
}
.main-chat-list .media:hover, .main-chat-list .media:focus {
	cursor: pointer;
	background-color: #f4f5fb;
	border-top-color: #e1e6f1;
	border-bottom-color: #e1e6f1;
}
.main-chat-list .media:hover:first-child, .main-chat-list .media:focus:first-child {
	border-top-color: transparent;
}
.main-chat-list .media.selected {
	background-color: #f1f2f9;
	border-top-color: #e1e6f1;
	border-bottom-color: #e1e6f1;
}
.main-chat-list .media.selected:first-child {
	border-top-color: transparent;
}
.main-chat-list .media.selected::after {
	display: block;
}
.main-chat-list .media.selected .media-contact-name span:first-child {
	color:#334151;
}
.main-chat-list .media.selected .media-body p {
	color:#334151;
}
.main-chat-list .main-img-user span {
	position: absolute;
	top: 0;
	left: -2px;
	width: 14px;
	height: 14px;
	display: none;
	align-items: center;
	justify-content: center;
	border-radius: 100%;
	font-size: 9px;
	font-weight: 500;
	color: #fff;
	background-color: #ff473d;
	box-shadow: 0 0 0 2px #fff;
}
.main-chat-list .media-body {
	margin-left: 15px;
}
.main-chat-list .media-body p {
	font-size: 13px;
	margin-bottom: 0;
	color: #99a6b7;
	line-height: 1.35;
}
.main-chat-list .media-contact-name {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 2px;
}
.main-chat-list .media-contact-name span:first-child {
	display: block;
	font-size: 14px;
	font-weight: 500;
	color:#334151;
}
.main-chat-list .media-contact-name span:last-child {
	display: block;
	font-size: 11px;
	color: #99a6b7;
}
.main-content-body-chat {
	flex-direction: column;
}
@media (min-width: 992px) {
	.main-content-body-chat {
		display: flex;
	}
}
.main-chat-header {
	flex-shrink: 0;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding:15px 12px;
	border-bottom: 1px solid #e1e6f1;
}
.main-chat-header .nav {
	margin-left: auto;
	align-items: center;
}
.main-chat-header .nav-link {
	padding: 0;
	color: #99a6b7;
	font-size: 16px;
	/*display: none;*/
}
.main-chat-header .nav-link:first-child {
	display: block;
}
@media (min-width: 576px) {
	.main-chat-header .nav-link {
		display: block;
	}
	.main-chat-header .nav-link:first-child {
		display: none;
	}
}
.main-chat-header .nav-link i {
	line-height: 0;
}
.main-chat-header .nav-link .typcn {
	line-height: .9;
}
.main-chat-header .nav-link .typcn::before {
	width: auto;
}
.main-chat-header .nav-link:hover, .main-chat-header .nav-link:focus {
	color: #f53163;
}
.main-chat-header .nav-link+.nav-link {
	margin-left: 15px;
}
.main-chat-msg-name {
	margin-left: 15px;
}
.main-chat-msg-name h6 {
	margin-bottom: 0px;
	font-size: 15px;
	font-weight: 700;
	color:#334151;
}
.main-chat-msg-name small {
	font-size: 12px;
	color: #99a6b7;
}
.main-chat-body {
	position: relative;
}
@media (min-width: 992px) {
	.main-chat-body {
		padding-bottom: 0;
		height: calc(100vh - 406px);
		overflow: auto;
	}
}
.main-chat-body .content-inner {
	padding: 20px;
}
.main-chat-body .media+.media {
	margin-top: 20px;
}
.main-chat-body .media.flex-row-reverse .media-body {
	margin-left: 0;
	margin-right: 20px;
	align-items: flex-end;
}
@media (min-width: 576px) {
	.main-chat-body .media.flex-row-reverse .media-body {
		margin-left: 55px;
	}
}
.main-chat-body .media.flex-row-reverse .main-msg-wrapper {
	background-color: rgb(135, 96, 251,0.2);
	color: #1e1635;
	position: relative;
}
.main-chat-body .media.flex-row-reverse .main-msg-wrapper:before,  
.main-chat-body .media.flex-row-reverse .main-msg-wrapper:after{
	right: auto;
    left: 100%;
    border-right-color: transparent;
    border-left-color: rgb(135, 96, 251,0.2);
}
.main-chat-body .media.flex-row-reverse .main-msg-wrapper:before{
	margin-top: -6px;
}
.main-chat-body .media.flex-row-reverse .main-msg-wrapper:after{
	border-width: 8px;
    margin-top: -8px;
}
.main-msg-wrapper{
	position:relative
}
.main-msg-wrapper:before,  
.main-msg-wrapper:after{
	border-color: transparent #f1f2f9 transparent transparent;
    border-image: none;
    border-style: solid;
    border-width: medium;
    content: " ";
    height: 0;
    pointer-events: none;
    position: absolute;
    right: 100%;
    top: 50%;
    width: 0;
}
.main-msg-wrapper:before{
	margin-top: -6px;
}
.main-msg-wrapper:after{
	border-width: 8px;
    margin-top: -8px;
}
.main-chat-body .media-body {
	margin-left: 20px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}
@media (min-width: 576px) {
	.main-chat-body .media-body {
		margin-right: 55px;
	}
}
.main-chat-body .media-body>div:last-child {
	font-size: 11px;
	color: #99a6b7;
	display: flex;
	align-items: center;
}
.main-chat-time {
	display: block;
	position: relative;
	text-align: center;
	margin: 20px 0;
}
.main-chat-time:first-of-type {
	margin-top: 0;
}
.main-chat-time span {
	font-size: 10px;
	font-weight: 500;
	text-transform: uppercase;
	letter-spacing: 1px;
	background: #e1e6f1;
    padding: 5px;
}
.main-msg-wrapper {
	padding: 10px 15px;
	background-color: #f1f2f9;
	font-size: 13px;
	margin-bottom: 5px;
	display: inline-block;
}
.main-chat-footer {
	flex-shrink: 0;
	display: flex;
	align-items: center;
	height: 50px;
	padding: 0 20px;
	border-top: 1px solid #e1e6f1;
	background-color: #fff;
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
}
@media (min-width: 992px) {
	.main-chat-footer {
		position: relative;
		bottom: auto;
		left: auto;
		right: auto;
	}
}
.main-chat-footer .nav {
	align-items: center;
}
.main-chat-footer .nav-link {
	padding: 0;
	color: #99a6b7;
	font-size: 16px;
	display: none;
}
.main-chat-footer .nav-link+.nav-link {
	margin-left: 10px;
}
@media (min-width: 576px) {
	.main-chat-footer .nav-link {
		display: block;
	}
}
.main-chat-footer .form-control {
	flex: 1;
	margin: 0 10px;
	padding-left: 0;
	padding-right: 0;
	border-width: 0;
}
@media (min-width: 576px) {
	.main-chat-footer .form-control {
		margin-left: 20px;
	}
}
@media (min-width: 768px) {
	.main-chat-footer .form-control {
		margin: 0 20px;
	}
}
.main-chat-footer .form-control:hover, .main-chat-footer .form-control:focus {
	box-shadow: none;
}
.main-msg-send {
	font-size: 20px;
	color: #f53163;
}
.dot-label {
    width: 8px;
    height: 8px;
    display: inline-block;
    border-radius: 100%;
    margin-right: 5px;
}
.bg-success {
    background-color: #03c895 !important;
}
.notify-badge {
    position: absolute;
    border-radius: 50px;
    width: 16px;
    height: 16px;
    top: -10px;
    right: -11px;
    line-height: 15px;
    text-align: center;
    padding: 0;
    font-size: 12px;
    background-color: #fc0021;
    vertical-align: bottom;
    border-radius: 50% !important;
    color: #fff;
}
::-webkit-scrollbar {
	width: 3px;
  }
::-webkit-scrollbar-thumb {
background: rgb(219, 217, 217);
}

.main-img-user.online img {
    display: flex;
    justify-content: flex-start;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border: 1px solid;
}

.media {
    display: flex;
    justify-content: flex-start;
}
.media-body {
    flex: 1;
}
@media (max-width: 992px){
.main-chat-footer {
    position: inherit;
}}
/*  ----- 7.6 Mail   ----- */

.main-content-mail .container, .main-content-mail .container-fluid {
	padding: 20px 0 0;
}
@media (min-width: 992px) {
	.main-content-mail .container, .main-content-mail .container-fluid {
		padding: 0;
	}
}
@media (min-width: 576px) {
	.main-content-left-mail {
		width: 300px;
	}
}
@media (min-width: 992px) {
	.main-content-left-mail {
		width: 230px;
		display: block;
		padding: 0;
		border-right: 1px solid #e1e6f1;
	}
}
.main-content-left-mail .btn-compose {
	margin-bottom: 20px;
	text-transform: uppercase;
	font-weight: 500;
	font-size: 11px;
	padding: 0 20px;
	letter-spacing: 2px;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 38px;
}
@media (min-width: 992px) {
	.main-content-left-mail .btn-compose {
		margin-right: 25px;
	}
}
.main-mail-menu {
	position: relative;
	padding-right: 0;
}
.main-mail-menu .nav-link {
	height: 38px;
}
@media (min-width: 992px) {
	.main-mail-menu .nav-link {
		font-size: 13px;
	}
}
.main-mail-menu .nav-link i {
	font-size: 20px;
}
.main-mail-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 20px;
	padding: 0 20px;
}
@media (min-width: 992px) {
	.main-mail-header {
		padding: 0 0 0 25px;
		margin-bottom: 25px;
	}
}
.main-mail-header>div:first-child p {
	font-size: 13px;
	margin-bottom: 0;
}
@media (min-width: 768px) {
	.main-mail-header>div:last-child {
		display: flex;
		align-items: center;
	}
}
.main-mail-header>div:last-child>span {
	font-size: 12px;
	margin-right: 10px;
}
.main-mail-header .btn-group .btn, .main-mail-header .btn-group .sp-container button, .sp-container .main-mail-header .btn-group button {
	font-size: 21px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 38px;
	height: 38px;
	line-height: 0;
	padding: 0;
	position: relative;
	z-index: 1;
	border-color: #e1e6f1;
	background-color: #fff;
}
.main-mail-header .btn-group .btn:hover, .main-mail-header .btn-group .sp-container button:hover, .sp-container .main-mail-header .btn-group button:hover, .main-mail-header .btn-group .btn:focus, .main-mail-header .btn-group .sp-container button:focus, .sp-container .main-mail-header .btn-group button:focus {
	color:#334151;
	background-color: #f1f2f9;
}
.main-mail-header .btn-group .btn.disabled, .main-mail-header .btn-group .sp-container button.disabled, .sp-container .main-mail-header .btn-group button.disabled {
	background-color: #fff;
	color: #e1e6f1;
	border-color: #e1e6f1;
	z-index: 0;
}
.main-mail-header .btn-group .btn.disabled:focus, .main-mail-header .btn-group .sp-container button.disabled:focus, .sp-container .main-mail-header .btn-group button.disabled:focus, .main-mail-header .btn-group .btn.disabled:active, .main-mail-header .btn-group .sp-container button.disabled:active, .sp-container .main-mail-header .btn-group button.disabled:active {
	box-shadow: none;
}
.main-mail-header .btn-group .btn+.btn, .main-mail-header .btn-group .sp-container button+.btn, .sp-container .main-mail-header .btn-group button+.btn, .main-mail-header .btn-group .sp-container .btn+button, .sp-container .main-mail-header .btn-group .btn+button, .main-mail-header .btn-group .sp-container button+button, .sp-container .main-mail-header .btn-group button+button {
	margin-left: -2px;
}
.main-mail-options {
	padding: 5px 10px 5px 20px;
	border: 1px solid #e1e6f1;
	border-left-width: 0;
	border-right-width: 0;
	display: none;
	align-items: center;
	justify-content: flex-end;
}
@media (min-width: 992px) {
	.main-mail-options {
		padding-left: 25px;
		display: flex;
		justify-content: space-between;
	}
}
.main-mail-options .btn, .main-mail-options .sp-container button, .sp-container .main-mail-options button {
	font-size: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 36px;
	height: 36px;
	background-color: transparent;
}
.main-mail-options .btn:hover, .main-mail-options .sp-container button:hover, .sp-container .main-mail-options button:hover, .main-mail-options .btn:focus, .main-mail-options .sp-container button:focus, .sp-container .main-mail-options button:focus {
	background-color: #e1e6f1;
}
.main-mail-options .btn i, .main-mail-options .sp-container button i, .sp-container .main-mail-options button i {
	line-height: 0;
}
.main-mail-options .btn i.typcn, .main-mail-options .sp-container button i.typcn, .sp-container .main-mail-options button i.typcn {
	line-height: .75;
}
.main-mail-options .btn.disabled, .main-mail-options .sp-container button.disabled, .sp-container .main-mail-options button.disabled {
	background-color: transparent;
	color: #99a6b7;
}
.main-mail-list {
	border-top: 1px solid #e1e6f1;
	max-height: 400px;
    overflow: scroll;
}
@media (min-width: 992px) {
	.main-mail-list {
		border-top-width: 0;
	}
}
.main-mail-item {
	padding: 10px 15px;
	border-top: 1px solid #e1e6f1;
	border-bottom: 1px solid #e1e6f1;
	background-color: #f4f5fb;
	position: relative;
	display: flex;
	align-items: center;
	width: 100vw;
}
@media (min-width: 576px) {
	.main-mail-item {
		padding: 10px 20px;
	}
}
@media (min-width: 992px) {
	.main-mail-item {
		width: auto;
		padding: 10px 25px;
	}
}
.main-mail-item+.main-mail-item {
	margin-top: -1px;
}
.main-mail-item:first-child {
	border-top-width: 0;
}
.main-mail-item .main-img-user, .main-mail-item .main-avatar {
	flex-shrink: 0;
	margin-right: 15px;
}
.main-mail-item .main-img-user::after, .main-mail-item .main-avatar::after {
	display: none;
}
.main-mail-item:hover, .main-mail-item:focus {
	background-color: #f1f2f9;
}
.main-mail-item.unread {
	background-color: #fff;
}
.main-mail-item.selected {
	background-color: white;
}
.main-mail-checkbox {
	margin-right: 15px;
	display: none;
}
@media (min-width: 992px) {
	.main-mail-checkbox {
		display: block;
	}
}
.main-mail-star {
	margin-right: 15px;
	font-size: 18px;
	line-height: .9;
	color: #e1e6f1;
	position: absolute;
	bottom: 10px;
	right: 0;
}
@media (min-width: 992px) {
	.main-mail-star {
		position: relative;
		bottom: auto;
		top: 2px;
	}
}
.main-mail-star.active {
	color: #ffc107;
}
.main-mail-body {
	width: calc(100% - 80px);
	cursor: pointer;
}
@media (min-width: 992px) {
	.main-mail-body {
		max-width: 460px;
		margin-right: 15px;
		flex: 1;
	}
}
@media (min-width: 1200px) {
	.main-mail-body {
		max-width: 640px;
	}
}
.main-mail-from {
	font-size: 13px;
}
@media (min-width: 576px) {
	.main-mail-subject {
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		width: 100%;
	}
}
.main-mail-subject strong {
	font-weight: 500;
	font-size: 14px;
	color:#334151;
	display: block;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	width: 100%;
}
@media (min-width: 576px) {
	.main-mail-subject strong {
		display: inline;
		width: auto;
		white-space: normal;
		text-overflow: inherit;
		overflow: visible;
	}
}
.main-mail-subject span {
	font-size: 13px;
	color: #99a6b7;
	display: none;
}
@media (min-width: 576px) {
	.main-mail-subject span {
		display: inline;
	}
}
.main-mail-attachment {
	margin-right: 5px;
	font-size: 12px;
	line-height: .9;
	display: none;
}
@media (min-width: 992px) {
	.main-mail-attachment {
		display: block;
	}
}
.main-mail-date {
	font-size: 11px;
	position: absolute;
	top: 12px;
	right: 15px;
	color: #99a6b7;
	margin-left: auto;
}
@media (min-width: 992px) {
	.main-mail-date {
		position: static;
		font-size: 13px;
	}
}
.main-mail-compose {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color:rgba(0, 0, 0, 0.8);
	z-index: 1000;
	display: none;
}
.main-mail-compose>div {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
}
@media (min-width: 992px) {
	.main-mail-compose .container {
		padding: 0;
	}
}
.main-mail-compose-box {
	box-shadow:  0 8px 16px 0 rgba(162, 169, 204,.24);
	border-radius: 0px;
}
.main-mail-compose-header {
	padding: 10px 20px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: #f1f2f9;
	color:#334151;
	font-size: 14px;
	font-weight: 500;
	border-bottom:1px solid #e1e6f1;
	border-top-left-radius: 0px;
	border-top-right-radius: 0px;
}
@media (min-width: 992px) {
	.main-mail-compose-header {
		padding: 15px 20px;
	}
}
.main-mail-compose-header .nav-link {
	color: #99a6b7;
	font-size: 14px;
	line-height: 1;
	padding: 0;
	transition: all 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
	.main-mail-compose-header .nav-link {
		transition: none;
	}
}
.main-mail-compose-header .nav-link:hover, .main-mail-compose-header .nav-link:focus {
	color:#334151;
}
.main-mail-compose-header .nav-link+.nav-link {
	margin-left: 15px;
}
.main-mail-compose-header .nav-link:nth-child(2) {
	display: none;
}
@media (min-width: 768px) {
	.main-mail-compose-header .nav-link:nth-child(2) {
		display: block;
	}
}
.main-mail-compose-body {
	background-color: #fff;
	padding: 20px;
	border-bottom-right-radius: 0px;
	border-bottom-left-radius: 0px;
}
@media (min-width: 992px) {
	.main-mail-compose-body {
		padding: 15px;
	}
}
.main-mail-compose-body .form-group {
	display: flex;
	align-items: center;
}
.main-mail-compose-body .form-group>div {
	flex: 1;
	margin-left: 10px;
}
.main-mail-compose-body .form-group .form-label {
	margin: 0;
	color:#334151;
}
.main-mail-compose-body .form-group .form-control {
	border-width: 0;
	border-radius: 0;
	padding: 0;
}
.main-mail-compose-body .form-group .form-control:focus {
	box-shadow: none !important;
}
.main-mail-compose-body .form-group+.form-group {
	border-top: 1px solid #e1e6f1;
	padding-top: 1rem;
}
.main-mail-compose-body .form-group:last-child {
	display: block;
}
@media (min-width: 576px) {
	.main-mail-compose-body .form-group:last-child {
		display: flex;
		justify-content: space-between;
	}
}
.main-mail-compose-body .form-group:last-child .btn, .main-mail-compose-body .form-group:last-child .sp-container button, .sp-container .main-mail-compose-body .form-group:last-child button {
	width: 100%;
	margin-top: 15px;
	padding-left: 20px;
	padding-right: 20px;
}
@media (min-width: 576px) {
	.main-mail-compose-body .form-group:last-child .btn, .main-mail-compose-body .form-group:last-child .sp-container button, .sp-container .main-mail-compose-body .form-group:last-child button {
		width: auto;
		margin-top: 0;
	}
}
.main-mail-compose-body .form-group .nav-link {
	padding: 0;
	font-size: 18px;
	line-height: 0;
	color:#334151;
	position: relative;
	transition: all 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
	.main-mail-compose-body .form-group .nav-link {
		transition: none;
	}
}
.main-mail-compose-body .form-group .nav-link:hover, .main-mail-compose-body .form-group .nav-link:focus {
	color:#334151;
}
.main-mail-compose-body .form-group .nav-link+.nav-link {
	margin-left: 15px;
}
.main-mail-compose-compress, .main-mail-compose-minimize {
	top: auto;
	left: auto;
	bottom: 0;
	right: 30px;
	width: 560px;
	height: auto;
	background-color: transparent;
}
.main-mail-compose-compress .container, .main-mail-compose-minimize .container {
	max-width: none;
	padding: 0;
}
.main-mail-compose-minimize .main-mail-compose-body {
	display: none;
}
.main-img-user img {
	width: 40px;
	height: 40px;
	border-radius: 50%;
}
.css-md26zr-MuiInputBase-root-MuiOutlinedInput-root {
	width: 100% !important;
}
.card-img, .card-img-top {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
}
.card-img, .card-img-bottom {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}
.card-img, .card-img-bottom, .card-img-top {
    object-fit: fill;
}

.nav-tabs {
    border-bottom: 0px solid #dee2e6 !important;
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: #fff !important;
    background-color: #666ee8 !important;
    border-color: #dee2e6 #dee2e6 #fff;
}
.nav-tabs .nav-link {
    background: #fff !important;
}
.nav-item .nav-link {
    display: inline-block;
	border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
	border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}
[data-rmiz-overlay] {
    background: rgb(0 0 0 / 54%) !important;
}

.nav-tabs .nav-link{
	margin: 6px 0;
}

/* .printsection {
	margin: 0 auto;
	background-color: #ffffff;
  } */

 @media print {
	.qrcode-print{
		width: 105mm;
	}
  } 
  @media print {
	html, body {
	  height: initial !important;
	  overflow: initial !important;
	  -webkit-print-color-adjust: exact;
	}
  }
  
  @page {
	size: auto;
	margin: 8mm;
  }
  /* @media print { html, body { height: initial !important; overflow: initial !important; }} */
 /* Chat Conversation Loader */
  .lds-ripple {
	display: inline-block;
	position: relative;
	width: 80px;
	height: 80px;
	left: 45%;
	top: 25%;
  }
  .lds-ripple div {
	position: absolute;
	border: 4px solid red;
	opacity: 1;
	border-radius: 50%;
	animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }
  .lds-ripple div:nth-child(2) {
	animation-delay: -0.5s;
  }
  @keyframes lds-ripple {
	0% {
	  top: 36px;
	  left: 36px;
	  width: 0;
	  height: 0;
	  opacity: 0;
	}
	4.9% {
	  top: 36px;
	  left: 36px;
	  width: 0;
	  height: 0;
	  opacity: 0;
	}
	5% {
	  top: 36px;
	  left: 36px;
	  width: 0;
	  height: 0;
	  opacity: 1;
	}
	100% {
	  top: 0px;
	  left: 0px;
	  width: 72px;
	  height: 72px;
	  opacity: 0;
	}
  }

/* Searchbar Full Screen start*/
  #search {
	position: fixed;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.8);
	-webkit-transition: all 0.5s ease-in-out;
	-moz-transition: all 0.5s ease-in-out;
	-o-transition: all 0.5s ease-in-out;
	-ms-transition: all 0.5s ease-in-out;
	transition: all 0.5s ease-in-out;
	-webkit-transform: translate(0px, -100%) scale(0, 0);
	-moz-transform: translate(0px, -100%) scale(0, 0);
	-o-transform: translate(0px, -100%) scale(0, 0);
	-ms-transform: translate(0px, -100%) scale(0, 0);
	transform: translate(0px, -100%) scale(0, 0);
	/* opacity: 0; */
	z-index: 999;
  }
  #search input[type=search] {
	position: absolute;
	top: 50%;
	width: 100%;
	color: white;
	background: rgba(0, 0, 0, 0);
	font-size: 30px;
	font-weight: 400;
	text-align: center;
	border: 1px;
	margin: 0px auto;
	margin-top: -30px;
	padding-left: 30px;
	padding-right: 30px;
	outline: none;
  }
  #search .btn {
	position: absolute;
	top: 50%;
	left: 50%;
	margin-top: 61px;
	margin-left: -45px;
	background-color: limegreen;
	border: black;
  }
  #search .btn.close {
    position: fixed;
    top: 15px;
    right: 0px;
    color: #fff;
    background-color: limegreen;
    border-color: green;
    opacity: 1;
    padding: 0px 0px;
    font-size: 21px;
    width: 30px;
    height: 30px;
    left: 98.5%;
}
  #search {
	-webkit-transform: translate(0px, 0px) scale(1, 1);
	-moz-transform: translate(0px, 0px) scale(1, 1);
	-o-transform: translate(0px, 0px) scale(1, 1);
	-ms-transform: translate(0px, 0px) scale(1, 1);
	transform: translate(0px, 0px) scale(1, 1);
	opacity: 1;
  }
  #search ::placeholder {
	font-weight: 500;
	text-transform: uppercase;
	font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  }
  /* Searchbar Full Screen End*/

  /* React Paginate css start */

  .pagination {
	margin: 15px auto;
	display: flex;
	list-style: none;
	outline: none;
  }
  .pagination > .active > a{
	background-color: #47ccde ;
	border-color: #47ccde ;
	color: #fff;
  }
  .pagination > li > a{
	border: 1px solid #47ccde ;
	padding: 5px 10px;
	outline: none;
	cursor: pointer;
  }
  .pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus{
	background-color: #47ccde ;
	border-color: #47ccde;
	outline: none ;
  }
  .pagination > li > a, .pagination > li > span{
	color: #47ccde
  }
  .pagination > li:first-child > a, .pagination > li:first-child > span, .pagination > li:last-child > a, .pagination > li:last-child > span{
	border-radius: unset
  }
   /* React Paginate css end */

   .MuiIcon-root {
    font-size: 1rem !important;
   }