td {
    font-size: 14px !important;
    font-weight: 400 !important;
    padding: 8px 16px !important;
    line-height: 1.2;
}
th {
    font-size: 14px !important;
    font-weight: 600 !important;
    padding: 8px 16px ;
    line-height: 1.2;
}


.dashboard-table td {
    font-size: 15px !important;
    font-weight: 400 !important;
    padding: 12px 16px !important;
    line-height: 1.2;
}
.dashboard-table th {
    font-size: 15px !important;
    font-weight: 600 !important;
    padding: 12px 16px ;
    line-height: 1.2;
}