.alert-white,.btn-white {
    border-color: #fff!important
}

.btn-outline-white.btn-glow,.btn-white.btn-glow {
    box-shadow: 0 1px 20px 1px rgba(255,255,255,.6)
}

.white {
    color: #fff!important
}

.bg-white {
    background-color: #fff!important
}

.bg-white .card-footer,.bg-white .card-header {
    background-color: transparent
}

.alert-white,.selectboxit.selectboxit-btn.bg-white {
    background-color: #fff!important
}

.alert-white {
    color: #a6a6a6!important
}

.alert-white .alert-link {
    color: #919191!important
}

.overlay-white {
    background: #fff;
    background: rgba(255,255,255,.8)
}

.btn-white,.color-info.white {
    background-color: #fff!important
}

.btn-white {
    color: #FFF
}

.btn-white:active,.btn-white:focus,.btn-white:hover {
    color: #FFF!important
}

.btn-outline-white {
    border-color: #fff;
    background-color: transparent;
    color: #fff
}

.btn-outline-white:hover {
    background-color: #fff;
    color: #FFF!important
}

.alert-black,.alert-black .alert-link,.black {
    color: #000!important
}

input[type=checkbox].bg-white+.custom-control-label:before,input[type=radio].bg-white+.custom-control-label:before {
    background-color: #fff!important
}

input:focus~.bg-white {
    box-shadow: 0 0 0 .075rem #fff,0 0 0 .21rem #fff!important
}

.btn-black.btn-glow,.btn-outline-black.btn-glow {
    box-shadow: 0 1px 20px 1px rgba(0,0,0,.6)
}

.border-white {
    border: 1px solid #fff!important
}

.alert-black,.btn-black {
    border-color: #000!important
}

.bullet.bullet-white {
    background-color: #fff
}

.bg-black {
    background-color: #000!important
}

.bg-black .card-footer,.bg-black .card-header {
    background-color: transparent
}

.selectboxit.selectboxit-btn.bg-black {
    background-color: #000!important
}

.alert-black {
    background-color: #242424!important
}

.overlay-black {
    background: #000;
    background: rgba(0,0,0,.8)
}

.btn-black,.color-info.black {
    background-color: #000!important
}

.btn-black {
    color: #FFF
}

.btn-black:active,.btn-black:focus,.btn-black:hover {
    color: #FFF!important
}

.btn-outline-black {
    border-color: #000;
    background-color: transparent;
    color: #000
}

.btn-outline-black:hover {
    background-color: #000;
    color: #FFF!important
}

input[type=checkbox].bg-black+.custom-control-label:before,input[type=radio].bg-black+.custom-control-label:before {
    background-color: #000!important
}

input:focus~.bg-black {
    box-shadow: 0 0 0 .075rem #fff,0 0 0 .21rem #000!important
}

.border-black {
    border: 1px solid #000!important
}

.bullet.bullet-black {
    background-color: #000
}

.bg-primary.bg-lighten-5,.btn-primary.btn-lighten-5 {
    background-color: #d5d7f9!important
}

.primary.lighten-5 {
    color: #d5d7f9!important
}

.bg-primary.bg-lighten-5.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(213,215,249,.6)
}

.btn-primary.btn-lighten-5 {
    border-color: #3a44e1!important
}

.btn-primary.btn-lighten-5:hover {
    border-color: #3a44e1!important;
    background-color: #232fde!important
}

.btn-primary.btn-lighten-5:active,.btn-primary.btn-lighten-5:focus {
    border-color: #232fde!important;
    background-color: #1e29ca!important
}

.btn-outline-primary.btn-outline-lighten-5 {
    border-color: #d5d7f9!important;
    color: #d5d7f9!important
}

.btn-outline-primary.btn-outline-lighten-5:hover {
    background-color: #d5d7f9!important
}

.border-primary.border-lighten-5 {
    border: 1px solid #d5d7f9!important
}

.border-top-primary.border-top-lighten-5 {
    border-top: 1px solid #d5d7f9!important
}

.border-bottom-primary.border-bottom-lighten-5 {
    border-bottom: 1px solid #d5d7f9!important
}

.border-left-primary.border-left-lighten-5 {
    border-left: 1px solid #d5d7f9!important
}

.border-right-primary.border-right-lighten-5 {
    border-right: 1px solid #d5d7f9!important
}

.overlay-primary.overlay-lighten-5 {
    background: #d5d7f9;
    background: rgba(213,215,249,.8)
}

.bg-primary.bg-lighten-4,.btn-primary.btn-lighten-4 {
    background-color: #bfc2f5!important
}

.primary.lighten-4 {
    color: #bfc2f5!important
}

.bg-primary.bg-lighten-4.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(191,194,245,.6)
}

.btn-primary.btn-lighten-4 {
    border-color: #3a44e1!important
}

.btn-primary.btn-lighten-4:hover {
    border-color: #3a44e1!important;
    background-color: #232fde!important
}

.btn-primary.btn-lighten-4:active,.btn-primary.btn-lighten-4:focus {
    border-color: #232fde!important;
    background-color: #1e29ca!important
}

.btn-outline-primary.btn-outline-lighten-4 {
    border-color: #bfc2f5!important;
    color: #bfc2f5!important
}

.btn-outline-primary.btn-outline-lighten-4:hover {
    background-color: #bfc2f5!important
}

.border-primary.border-lighten-4 {
    border: 1px solid #bfc2f5!important
}

.border-top-primary.border-top-lighten-4 {
    border-top: 1px solid #bfc2f5!important
}

.border-bottom-primary.border-bottom-lighten-4 {
    border-bottom: 1px solid #bfc2f5!important
}

.border-left-primary.border-left-lighten-4 {
    border-left: 1px solid #bfc2f5!important
}

.border-right-primary.border-right-lighten-4 {
    border-right: 1px solid #bfc2f5!important
}

.overlay-primary.overlay-lighten-4 {
    background: #bfc2f5;
    background: rgba(191,194,245,.8)
}

.bg-primary.bg-lighten-3,.btn-primary.btn-lighten-3 {
    background-color: #a9adf2!important
}

.primary.lighten-3 {
    color: #a9adf2!important
}

.bg-primary.bg-lighten-3.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(169,173,242,.6)
}

.btn-primary.btn-lighten-3 {
    border-color: #3a44e1!important
}

.btn-primary.btn-lighten-3:hover {
    border-color: #3a44e1!important;
    background-color: #232fde!important
}

.btn-primary.btn-lighten-3:active,.btn-primary.btn-lighten-3:focus {
    border-color: #232fde!important;
    background-color: #1e29ca!important
}

.btn-outline-primary.btn-outline-lighten-3 {
    border-color: #a9adf2!important;
    color: #a9adf2!important
}

.btn-outline-primary.btn-outline-lighten-3:hover {
    background-color: #a9adf2!important
}

.border-primary.border-lighten-3 {
    border: 1px solid #a9adf2!important
}

.border-top-primary.border-top-lighten-3 {
    border-top: 1px solid #a9adf2!important
}

.border-bottom-primary.border-bottom-lighten-3 {
    border-bottom: 1px solid #a9adf2!important
}

.border-left-primary.border-left-lighten-3 {
    border-left: 1px solid #a9adf2!important
}

.border-right-primary.border-right-lighten-3 {
    border-right: 1px solid #a9adf2!important
}

.overlay-primary.overlay-lighten-3 {
    background: #a9adf2;
    background: rgba(169,173,242,.8)
}

.bg-primary.bg-lighten-2,.btn-primary.btn-lighten-2 {
    background-color: #9298ef!important
}

.primary.lighten-2 {
    color: #9298ef!important
}

.bg-primary.bg-lighten-2.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(146,152,239,.6)
}

.btn-primary.btn-lighten-2 {
    border-color: #3a44e1!important
}

.btn-primary.btn-lighten-2:hover {
    border-color: #3a44e1!important;
    background-color: #232fde!important
}

.btn-primary.btn-lighten-2:active,.btn-primary.btn-lighten-2:focus {
    border-color: #232fde!important;
    background-color: #1e29ca!important
}

.btn-outline-primary.btn-outline-lighten-2 {
    border-color: #9298ef!important;
    color: #9298ef!important
}

.btn-outline-primary.btn-outline-lighten-2:hover {
    background-color: #9298ef!important
}

.border-primary.border-lighten-2 {
    border: 1px solid #9298ef!important
}

.border-top-primary.border-top-lighten-2 {
    border-top: 1px solid #9298ef!important
}

.border-bottom-primary.border-bottom-lighten-2 {
    border-bottom: 1px solid #9298ef!important
}

.border-left-primary.border-left-lighten-2 {
    border-left: 1px solid #9298ef!important
}

.border-right-primary.border-right-lighten-2 {
    border-right: 1px solid #9298ef!important
}

.overlay-primary.overlay-lighten-2 {
    background: #9298ef;
    background: rgba(146,152,239,.8)
}

.bg-primary.bg-lighten-1,.btn-primary.btn-lighten-1 {
    background-color: #7c83eb!important
}

.primary.lighten-1 {
    color: #7c83eb!important
}

.bg-primary.bg-lighten-1.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(124,131,235,.6)
}

.bg-primary.bg-glow,.btn-outline-primary.btn-glow,.btn-primary.btn-glow {
    box-shadow: 0 1px 20px 1px rgba(102,110,232,.6)
}

.btn-primary.btn-lighten-1 {
    border-color: #3a44e1!important
}

.btn-primary.btn-lighten-1:hover {
    border-color: #3a44e1!important;
    background-color: #232fde!important
}

.btn-primary.btn-lighten-1:active,.btn-primary.btn-lighten-1:focus {
    border-color: #232fde!important;
    background-color: #1e29ca!important
}

.btn-outline-primary.btn-outline-lighten-1 {
    border-color: #7c83eb!important;
    color: #7c83eb!important
}

.btn-outline-primary.btn-outline-lighten-1:hover {
    background-color: #7c83eb!important
}

.border-primary.border-lighten-1 {
    border: 1px solid #7c83eb!important
}

.border-top-primary.border-top-lighten-1 {
    border-top: 1px solid #7c83eb!important
}

.border-bottom-primary.border-bottom-lighten-1 {
    border-bottom: 1px solid #7c83eb!important
}

.border-left-primary.border-left-lighten-1 {
    border-left: 1px solid #7c83eb!important
}

.border-right-primary.border-right-lighten-1 {
    border-right: 1px solid #7c83eb!important
}

.alert-primary,.btn-primary {
    border-color: #666ee8!important
}

.overlay-primary.overlay-lighten-1 {
    background: #7c83eb;
    background: rgba(124,131,235,.8)
}

.primary {
    color: #666ee8!important
}

.bg-primary {
    background-color: #666ee8!important
}

.bg-primary .card-footer,.bg-primary .card-header {
    background-color: transparent
}

.selectboxit.selectboxit-btn.bg-primary {
    background-color: #666ee8!important
}

.alert-primary {
    background-color: #a4a9f1!important;
    color: #141b87!important
}

.alert-primary .alert-link {
    color: #0f1464!important
}

.overlay-primary {
    background: #666ee8;
    background: rgba(102,110,232,.8)
}

.btn-primary,.color-info.primary {
    background-color: #666ee8!important
}

.btn-primary {
    color: #FFF
}

.btn-primary:hover {
    border-color: #7c83eb!important;
    background-color: #7c83eb!important;
    color: #FFF!important
}

.btn-primary:active,.btn-primary:focus {
    border-color: #232fde!important;
    background-color: #232fde!important;
    color: #FFF!important
}

.btn-outline-primary {
    border-color: #666ee8;
    background-color: transparent;
    color: #666ee8
}

.btn-outline-primary:hover {
    background-color: #666ee8;
    color: #FFF!important
}

input[type=checkbox].bg-primary+.custom-control-label:before,input[type=radio].bg-primary+.custom-control-label:before {
    background-color: #666ee8!important
}

.border-primary {
    border: 1px solid #666ee8!important
}

.border-top-primary {
    border-top: 1px solid #666ee8
}

.border-bottom-primary {
    border-bottom: 1px solid #666ee8
}

.border-left-primary {
    border-left: 1px solid #666ee8
}

.border-right-primary {
    border-right: 1px solid #666ee8
}

.bullet.bullet-primary {
    background-color: #666ee8
}

.bg-primary.bg-darken-1,.btn-primary.btn-darken-1 {
    background-color: #5059e5!important
}

.primary.darken-1 {
    color: #5059e5!important
}

.bg-primary.bg-darken-1.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(80,89,229,.6)
}

.btn-primary.btn-darken-1 {
    border-color: #3a44e1!important
}

.btn-primary.btn-darken-1:hover {
    border-color: #3a44e1!important;
    background-color: #232fde!important
}

.btn-primary.btn-darken-1:active,.btn-primary.btn-darken-1:focus {
    border-color: #232fde!important;
    background-color: #1e29ca!important
}

.btn-outline-primary.btn-outline-darken-1 {
    border-color: #5059e5!important;
    color: #5059e5!important
}

.btn-outline-primary.btn-outline-darken-1:hover {
    background-color: #5059e5!important
}

.border-primary.border-darken-1 {
    border: 1px solid #5059e5!important
}

.border-top-primary.border-top-darken-1 {
    border-top: 1px solid #5059e5!important
}

.border-bottom-primary.border-bottom-darken-1 {
    border-bottom: 1px solid #5059e5!important
}

.border-left-primary.border-left-darken-1 {
    border-left: 1px solid #5059e5!important
}

.border-right-primary.border-right-darken-1 {
    border-right: 1px solid #5059e5!important
}

.overlay-primary.overlay-darken-1 {
    background: #5059e5;
    background: rgba(80,89,229,.8)
}

.bg-primary.bg-darken-2,.btn-primary.btn-darken-2 {
    background-color: #3a44e1!important
}

.primary.darken-2 {
    color: #3a44e1!important
}

.bg-primary.bg-darken-2.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(58,68,225,.6)
}

.btn-primary.btn-darken-2 {
    border-color: #3a44e1!important
}

.btn-primary.btn-darken-2:hover {
    border-color: #3a44e1!important;
    background-color: #232fde!important
}

.btn-primary.btn-darken-2:active,.btn-primary.btn-darken-2:focus {
    border-color: #232fde!important;
    background-color: #1e29ca!important
}

.btn-outline-primary.btn-outline-darken-2 {
    border-color: #3a44e1!important;
    color: #3a44e1!important
}

.btn-outline-primary.btn-outline-darken-2:hover {
    background-color: #3a44e1!important
}

.border-primary.border-darken-2 {
    border: 1px solid #3a44e1!important
}

.border-top-primary.border-top-darken-2 {
    border-top: 1px solid #3a44e1!important
}

.border-bottom-primary.border-bottom-darken-2 {
    border-bottom: 1px solid #3a44e1!important
}

.border-left-primary.border-left-darken-2 {
    border-left: 1px solid #3a44e1!important
}

.border-right-primary.border-right-darken-2 {
    border-right: 1px solid #3a44e1!important
}

.overlay-primary.overlay-darken-2 {
    background: #3a44e1;
    background: rgba(58,68,225,.8)
}

.primary.darken-3 {
    color: #232fde!important
}

.bg-primary.bg-darken-3 {
    background-color: #232fde!important
}

.bg-primary.bg-darken-3.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(35,47,222,.6)
}

.btn-primary.btn-darken-3,.btn-primary.btn-darken-3:hover {
    border-color: #3a44e1!important;
    background-color: #232fde!important
}

.btn-primary.btn-darken-3:active,.btn-primary.btn-darken-3:focus {
    border-color: #232fde!important;
    background-color: #1e29ca!important
}

.btn-outline-primary.btn-outline-darken-3 {
    border-color: #232fde!important;
    color: #232fde!important
}

.btn-outline-primary.btn-outline-darken-3:hover {
    background-color: #232fde!important
}

.border-primary.border-darken-3 {
    border: 1px solid #232fde!important
}

.border-top-primary.border-top-darken-3 {
    border-top: 1px solid #232fde!important
}

.border-bottom-primary.border-bottom-darken-3 {
    border-bottom: 1px solid #232fde!important
}

.border-left-primary.border-left-darken-3 {
    border-left: 1px solid #232fde!important
}

.border-right-primary.border-right-darken-3 {
    border-right: 1px solid #232fde!important
}

.overlay-primary.overlay-darken-3 {
    background: #232fde;
    background: rgba(35,47,222,.8)
}

.bg-primary.bg-darken-4,.btn-primary.btn-darken-4 {
    background-color: #1e29ca!important
}

.primary.darken-4 {
    color: #1e29ca!important
}

.bg-primary.bg-darken-4.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(30,41,202,.6)
}

.btn-primary.btn-darken-4 {
    border-color: #3a44e1!important
}

.btn-primary.btn-darken-4:hover {
    border-color: #3a44e1!important;
    background-color: #232fde!important
}

.btn-primary.btn-darken-4:active,.btn-primary.btn-darken-4:focus {
    border-color: #232fde!important;
    background-color: #1e29ca!important
}

.btn-outline-primary.btn-outline-darken-4 {
    border-color: #1e29ca!important;
    color: #1e29ca!important
}

.btn-outline-primary.btn-outline-darken-4:hover {
    background-color: #1e29ca!important
}

.border-primary.border-darken-4 {
    border: 1px solid #1e29ca!important
}

.border-top-primary.border-top-darken-4 {
    border-top: 1px solid #1e29ca!important
}

.border-bottom-primary.border-bottom-darken-4 {
    border-bottom: 1px solid #1e29ca!important
}

.border-left-primary.border-left-darken-4 {
    border-left: 1px solid #1e29ca!important
}

.border-right-primary.border-right-darken-4 {
    border-right: 1px solid #1e29ca!important
}

.overlay-primary.overlay-darken-4 {
    background: #1e29ca;
    background: rgba(30,41,202,.8)
}

.bg-primary.bg-accent-1,.btn-primary.btn-accent-1 {
    background-color: #efefff!important
}

.primary.accent-1 {
    color: #efefff!important
}

.bg-primary.bg-accent-1.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(239,239,255,.6)
}

.btn-primary.btn-accent-1 {
    border-color: #3a44e1!important
}

.btn-primary.btn-accent-1:hover {
    border-color: #3a44e1!important;
    background-color: #232fde!important
}

.btn-primary.btn-accent-1:active,.btn-primary.btn-accent-1:focus {
    border-color: #232fde!important;
    background-color: #1e29ca!important
}

.btn-outline-primary.btn-outline-accent-1 {
    border-color: #efefff!important;
    color: #efefff!important
}

.btn-outline-primary.btn-outline-accent-1:hover {
    background-color: #efefff!important
}

.border-primary.border-accent-1 {
    border: 1px solid #efefff!important
}

.border-top-primary.border-top-accent-1 {
    border-top: 1px solid #efefff!important
}

.border-bottom-primary.border-bottom-accent-1 {
    border-bottom: 1px solid #efefff!important
}

.border-left-primary.border-left-accent-1 {
    border-left: 1px solid #efefff!important
}

.border-right-primary.border-right-accent-1 {
    border-right: 1px solid #efefff!important
}

.overlay-primary.overlay-accent-1 {
    background: #efefff;
    background: rgba(239,239,255,.8)
}

.bg-primary.bg-accent-2,.btn-primary.btn-accent-2 {
    background-color: #dbdbff!important
}

.primary.accent-2 {
    color: #dbdbff!important
}

.bg-primary.bg-accent-2.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(219,219,255,.6)
}

.btn-primary.btn-accent-2 {
    border-color: #3a44e1!important
}

.btn-primary.btn-accent-2:hover {
    border-color: #3a44e1!important;
    background-color: #232fde!important
}

.btn-primary.btn-accent-2:active,.btn-primary.btn-accent-2:focus {
    border-color: #232fde!important;
    background-color: #1e29ca!important
}

.btn-outline-primary.btn-outline-accent-2 {
    border-color: #dbdbff!important;
    color: #dbdbff!important
}

.btn-outline-primary.btn-outline-accent-2:hover {
    background-color: #dbdbff!important
}

.border-primary.border-accent-2 {
    border: 1px solid #dbdbff!important
}

.border-top-primary.border-top-accent-2 {
    border-top: 1px solid #dbdbff!important
}

.border-bottom-primary.border-bottom-accent-2 {
    border-bottom: 1px solid #dbdbff!important
}

.border-left-primary.border-left-accent-2 {
    border-left: 1px solid #dbdbff!important
}

.border-right-primary.border-right-accent-2 {
    border-right: 1px solid #dbdbff!important
}

.overlay-primary.overlay-accent-2 {
    background: #dbdbff;
    background: rgba(219,219,255,.8)
}

.bg-primary.bg-accent-3,.btn-primary.btn-accent-3 {
    background-color: #bcbfff!important
}

.primary.accent-3 {
    color: #bcbfff!important
}

.bg-primary.bg-accent-3.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(188,191,255,.6)
}

.btn-primary.btn-accent-3 {
    border-color: #3a44e1!important
}

.btn-primary.btn-accent-3:hover {
    border-color: #3a44e1!important;
    background-color: #232fde!important
}

.btn-primary.btn-accent-3:active,.btn-primary.btn-accent-3:focus {
    border-color: #232fde!important;
    background-color: #1e29ca!important
}

.btn-outline-primary.btn-outline-accent-3 {
    border-color: #bcbfff!important;
    color: #bcbfff!important
}

.btn-outline-primary.btn-outline-accent-3:hover {
    background-color: #bcbfff!important
}

.border-primary.border-accent-3 {
    border: 1px solid #bcbfff!important
}

.border-top-primary.border-top-accent-3 {
    border-top: 1px solid #bcbfff!important
}

.border-bottom-primary.border-bottom-accent-3 {
    border-bottom: 1px solid #bcbfff!important
}

.border-left-primary.border-left-accent-3 {
    border-left: 1px solid #bcbfff!important
}

.border-right-primary.border-right-accent-3 {
    border-right: 1px solid #bcbfff!important
}

.overlay-primary.overlay-accent-3 {
    background: #bcbfff;
    background: rgba(188,191,255,.8)
}

.bg-primary.bg-accent-4,.btn-primary.btn-accent-4 {
    background-color: #a2a6ff!important
}

.primary.accent-4 {
    color: #a2a6ff!important
}

.bg-primary.bg-accent-4.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(162,166,255,.6)
}

.btn-primary.btn-accent-4 {
    border-color: #3a44e1!important
}

.btn-primary.btn-accent-4:hover {
    border-color: #3a44e1!important;
    background-color: #232fde!important
}

.btn-primary.btn-accent-4:active,.btn-primary.btn-accent-4:focus {
    border-color: #232fde!important;
    background-color: #1e29ca!important
}

.btn-outline-primary.btn-outline-accent-4 {
    border-color: #a2a6ff!important;
    color: #a2a6ff!important
}

.btn-outline-primary.btn-outline-accent-4:hover {
    background-color: #a2a6ff!important
}

input:focus~.bg-primary {
    box-shadow: 0 0 0 .075rem #fff,0 0 0 .21rem #a2a6ff!important
}

.border-primary.border-accent-4 {
    border: 1px solid #a2a6ff!important
}

.border-top-primary.border-top-accent-4 {
    border-top: 1px solid #a2a6ff!important
}

.border-bottom-primary.border-bottom-accent-4 {
    border-bottom: 1px solid #a2a6ff!important
}

.border-left-primary.border-left-accent-4 {
    border-left: 1px solid #a2a6ff!important
}

.border-right-primary.border-right-accent-4 {
    border-right: 1px solid #a2a6ff!important
}

.overlay-primary.overlay-accent-4 {
    background: #a2a6ff;
    background: rgba(162,166,255,.8)
}

.bg-secondary.bg-lighten-5,.btn-secondary.btn-lighten-5 {
    background-color: #afb2bd!important
}

.secondary.lighten-5 {
    color: #afb2bd!important
}

.bg-secondary.bg-lighten-5.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(175,178,189,.6)
}

.btn-secondary.btn-lighten-5 {
    border-color: #545766!important
}

.btn-secondary.btn-lighten-5:hover {
    border-color: #545766!important;
    background-color: #484b58!important
}

.btn-secondary.btn-lighten-5:active,.btn-secondary.btn-lighten-5:focus {
    border-color: #484b58!important;
    background-color: #3d3f4a!important
}

.btn-outline-secondary.btn-outline-lighten-5 {
    border-color: #afb2bd!important;
    color: #afb2bd!important
}

.btn-outline-secondary.btn-outline-lighten-5:hover {
    background-color: #afb2bd!important
}

.border-secondary.border-lighten-5 {
    border: 1px solid #afb2bd!important
}

.border-top-secondary.border-top-lighten-5 {
    border-top: 1px solid #afb2bd!important
}

.border-bottom-secondary.border-bottom-lighten-5 {
    border-bottom: 1px solid #afb2bd!important
}

.border-left-secondary.border-left-lighten-5 {
    border-left: 1px solid #afb2bd!important
}

.border-right-secondary.border-right-lighten-5 {
    border-right: 1px solid #afb2bd!important
}

.overlay-secondary.overlay-lighten-5 {
    background: #afb2bd;
    background: rgba(175,178,189,.8)
}

.bg-secondary.bg-lighten-4,.btn-secondary.btn-lighten-4 {
    background-color: #a1a4b2!important
}

.secondary.lighten-4 {
    color: #a1a4b2!important
}

.bg-secondary.bg-lighten-4.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(161,164,178,.6)
}

.btn-secondary.btn-lighten-4 {
    border-color: #545766!important
}

.btn-secondary.btn-lighten-4:hover {
    border-color: #545766!important;
    background-color: #484b58!important
}

.btn-secondary.btn-lighten-4:active,.btn-secondary.btn-lighten-4:focus {
    border-color: #484b58!important;
    background-color: #3d3f4a!important
}

.btn-outline-secondary.btn-outline-lighten-4 {
    border-color: #a1a4b2!important;
    color: #a1a4b2!important
}

.btn-outline-secondary.btn-outline-lighten-4:hover {
    background-color: #a1a4b2!important
}

.border-secondary.border-lighten-4 {
    border: 1px solid #a1a4b2!important
}

.border-top-secondary.border-top-lighten-4 {
    border-top: 1px solid #a1a4b2!important
}

.border-bottom-secondary.border-bottom-lighten-4 {
    border-bottom: 1px solid #a1a4b2!important
}

.border-left-secondary.border-left-lighten-4 {
    border-left: 1px solid #a1a4b2!important
}

.border-right-secondary.border-right-lighten-4 {
    border-right: 1px solid #a1a4b2!important
}

.overlay-secondary.overlay-lighten-4 {
    background: #a1a4b2;
    background: rgba(161,164,178,.8)
}

.bg-secondary.bg-lighten-3,.btn-secondary.btn-lighten-3 {
    background-color: #9397a6!important
}

.secondary.lighten-3 {
    color: #9397a6!important
}

.bg-secondary.bg-lighten-3.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(147,151,166,.6)
}

.btn-secondary.btn-lighten-3 {
    border-color: #545766!important
}

.btn-secondary.btn-lighten-3:hover {
    border-color: #545766!important;
    background-color: #484b58!important
}

.btn-secondary.btn-lighten-3:active,.btn-secondary.btn-lighten-3:focus {
    border-color: #484b58!important;
    background-color: #3d3f4a!important
}

.btn-outline-secondary.btn-outline-lighten-3 {
    border-color: #9397a6!important;
    color: #9397a6!important
}

.btn-outline-secondary.btn-outline-lighten-3:hover {
    background-color: #9397a6!important
}

.border-secondary.border-lighten-3 {
    border: 1px solid #9397a6!important
}

.border-top-secondary.border-top-lighten-3 {
    border-top: 1px solid #9397a6!important
}

.border-bottom-secondary.border-bottom-lighten-3 {
    border-bottom: 1px solid #9397a6!important
}

.border-left-secondary.border-left-lighten-3 {
    border-left: 1px solid #9397a6!important
}

.border-right-secondary.border-right-lighten-3 {
    border-right: 1px solid #9397a6!important
}

.overlay-secondary.overlay-lighten-3 {
    background: #9397a6;
    background: rgba(147,151,166,.8)
}

.bg-secondary.bg-lighten-2,.btn-secondary.btn-lighten-2 {
    background-color: #85899b!important
}

.secondary.lighten-2 {
    color: #85899b!important
}

.bg-secondary.bg-lighten-2.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(133,137,155,.6)
}

.btn-secondary.btn-lighten-2 {
    border-color: #545766!important
}

.btn-secondary.btn-lighten-2:hover {
    border-color: #545766!important;
    background-color: #484b58!important
}

.btn-secondary.btn-lighten-2:active,.btn-secondary.btn-lighten-2:focus {
    border-color: #484b58!important;
    background-color: #3d3f4a!important
}

.btn-outline-secondary.btn-outline-lighten-2 {
    border-color: #85899b!important;
    color: #85899b!important
}

.btn-outline-secondary.btn-outline-lighten-2:hover {
    background-color: #85899b!important
}

.border-secondary.border-lighten-2 {
    border: 1px solid #85899b!important
}

.border-top-secondary.border-top-lighten-2 {
    border-top: 1px solid #85899b!important
}

.border-bottom-secondary.border-bottom-lighten-2 {
    border-bottom: 1px solid #85899b!important
}

.border-left-secondary.border-left-lighten-2 {
    border-left: 1px solid #85899b!important
}

.border-right-secondary.border-right-lighten-2 {
    border-right: 1px solid #85899b!important
}

.overlay-secondary.overlay-lighten-2 {
    background: #85899b;
    background: rgba(133,137,155,.8)
}

.bg-secondary.bg-lighten-1,.btn-secondary.btn-lighten-1 {
    background-color: #777b8f!important
}

.secondary.lighten-1 {
    color: #777b8f!important
}

.bg-secondary.bg-lighten-1.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(119,123,143,.6)
}

.bg-secondary.bg-glow,.btn-outline-secondary.btn-glow,.btn-secondary.btn-glow {
    box-shadow: 0 1px 20px 1px rgba(107,111,130,.6)
}

.btn-secondary.btn-lighten-1 {
    border-color: #545766!important
}

.btn-secondary.btn-lighten-1:hover {
    border-color: #545766!important;
    background-color: #484b58!important
}

.btn-secondary.btn-lighten-1:active,.btn-secondary.btn-lighten-1:focus {
    border-color: #484b58!important;
    background-color: #3d3f4a!important
}

.btn-outline-secondary.btn-outline-lighten-1 {
    border-color: #777b8f!important;
    color: #777b8f!important
}

.btn-outline-secondary.btn-outline-lighten-1:hover {
    background-color: #777b8f!important
}

.border-secondary.border-lighten-1 {
    border: 1px solid #777b8f!important
}

.border-top-secondary.border-top-lighten-1 {
    border-top: 1px solid #777b8f!important
}

.border-bottom-secondary.border-bottom-lighten-1 {
    border-bottom: 1px solid #777b8f!important
}

.border-left-secondary.border-left-lighten-1 {
    border-left: 1px solid #777b8f!important
}

.border-right-secondary.border-right-lighten-1 {
    border-right: 1px solid #777b8f!important
}

.overlay-secondary.overlay-lighten-1 {
    background: #777b8f;
    background: rgba(119,123,143,.8)
}

.secondary {
    color: #6b6f82!important
}

.bg-secondary {
    background-color: #6b6f82!important
}

.bg-secondary .card-footer,.bg-secondary .card-header {
    background-color: transparent
}

.selectboxit.selectboxit-btn.bg-secondary {
    background-color: #6b6f82!important
}

.alert-secondary {
    border-color: #6b6f82!important;
    background-color: #9094a4!important;
    color: #1a1b20!important
}

.alert-secondary .alert-link {
    color: #08080a!important
}

.overlay-secondary {
    background: #6b6f82;
    background: rgba(107,111,130,.8)
}

.btn-secondary,.color-info.secondary {
    background-color: #6b6f82!important
}

.btn-secondary {
    border-color: #6b6f82!important;
    color: #FFF
}

.btn-secondary:hover {
    border-color: #777b8f!important;
    background-color: #777b8f!important;
    color: #FFF!important
}

.btn-secondary:active,.btn-secondary:focus {
    border-color: #484b58!important;
    background-color: #484b58!important;
    color: #FFF!important
}

.btn-outline-secondary {
    border-color: #6b6f82;
    background-color: transparent;
    color: #6b6f82
}

.btn-outline-secondary:hover {
    background-color: #6b6f82;
    color: #FFF!important
}

input[type=checkbox].bg-secondary+.custom-control-label:before,input[type=radio].bg-secondary+.custom-control-label:before {
    background-color: #6b6f82!important
}

.border-secondary {
    border: 1px solid #6b6f82!important
}

.border-top-secondary {
    border-top: 1px solid #6b6f82
}

.border-bottom-secondary {
    border-bottom: 1px solid #6b6f82
}

.border-left-secondary {
    border-left: 1px solid #6b6f82
}

.border-right-secondary {
    border-right: 1px solid #6b6f82
}

.bullet.bullet-secondary {
    background-color: #6b6f82
}

.bg-secondary.bg-darken-1,.btn-secondary.btn-darken-1 {
    background-color: #5f6374!important
}

.secondary.darken-1 {
    color: #5f6374!important
}

.bg-secondary.bg-darken-1.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(95,99,116,.6)
}

.btn-secondary.btn-darken-1 {
    border-color: #545766!important
}

.btn-secondary.btn-darken-1:hover {
    border-color: #545766!important;
    background-color: #484b58!important
}

.btn-secondary.btn-darken-1:active,.btn-secondary.btn-darken-1:focus {
    border-color: #484b58!important;
    background-color: #3d3f4a!important
}

.btn-outline-secondary.btn-outline-darken-1 {
    border-color: #5f6374!important;
    color: #5f6374!important
}

.btn-outline-secondary.btn-outline-darken-1:hover {
    background-color: #5f6374!important
}

.border-secondary.border-darken-1 {
    border: 1px solid #5f6374!important
}

.border-top-secondary.border-top-darken-1 {
    border-top: 1px solid #5f6374!important
}

.border-bottom-secondary.border-bottom-darken-1 {
    border-bottom: 1px solid #5f6374!important
}

.border-left-secondary.border-left-darken-1 {
    border-left: 1px solid #5f6374!important
}

.border-right-secondary.border-right-darken-1 {
    border-right: 1px solid #5f6374!important
}

.overlay-secondary.overlay-darken-1 {
    background: #5f6374;
    background: rgba(95,99,116,.8)
}

.bg-secondary.bg-darken-2,.btn-secondary.btn-darken-2 {
    background-color: #545766!important
}

.secondary.darken-2 {
    color: #545766!important
}

.bg-secondary.bg-darken-2.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(84,87,102,.6)
}

.btn-secondary.btn-darken-2 {
    border-color: #545766!important
}

.btn-secondary.btn-darken-2:hover {
    border-color: #545766!important;
    background-color: #484b58!important
}

.btn-secondary.btn-darken-2:active,.btn-secondary.btn-darken-2:focus {
    border-color: #484b58!important;
    background-color: #3d3f4a!important
}

.btn-outline-secondary.btn-outline-darken-2 {
    border-color: #545766!important;
    color: #545766!important
}

.btn-outline-secondary.btn-outline-darken-2:hover {
    background-color: #545766!important
}

.border-secondary.border-darken-2 {
    border: 1px solid #545766!important
}

.border-top-secondary.border-top-darken-2 {
    border-top: 1px solid #545766!important
}

.border-bottom-secondary.border-bottom-darken-2 {
    border-bottom: 1px solid #545766!important
}

.border-left-secondary.border-left-darken-2 {
    border-left: 1px solid #545766!important
}

.border-right-secondary.border-right-darken-2 {
    border-right: 1px solid #545766!important
}

.overlay-secondary.overlay-darken-2 {
    background: #545766;
    background: rgba(84,87,102,.8)
}

.secondary.darken-3 {
    color: #484b58!important
}

.bg-secondary.bg-darken-3 {
    background-color: #484b58!important
}

.bg-secondary.bg-darken-3.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(72,75,88,.6)
}

.btn-secondary.btn-darken-3,.btn-secondary.btn-darken-3:hover {
    border-color: #545766!important;
    background-color: #484b58!important
}

.btn-secondary.btn-darken-3:active,.btn-secondary.btn-darken-3:focus {
    border-color: #484b58!important;
    background-color: #3d3f4a!important
}

.btn-outline-secondary.btn-outline-darken-3 {
    border-color: #484b58!important;
    color: #484b58!important
}

.btn-outline-secondary.btn-outline-darken-3:hover {
    background-color: #484b58!important
}

.border-secondary.border-darken-3 {
    border: 1px solid #484b58!important
}

.border-top-secondary.border-top-darken-3 {
    border-top: 1px solid #484b58!important
}

.border-bottom-secondary.border-bottom-darken-3 {
    border-bottom: 1px solid #484b58!important
}

.border-left-secondary.border-left-darken-3 {
    border-left: 1px solid #484b58!important
}

.border-right-secondary.border-right-darken-3 {
    border-right: 1px solid #484b58!important
}

.overlay-secondary.overlay-darken-3 {
    background: #484b58;
    background: rgba(72,75,88,.8)
}

.bg-secondary.bg-darken-4,.btn-secondary.btn-darken-4 {
    background-color: #3d3f4a!important
}

.secondary.darken-4 {
    color: #3d3f4a!important
}

.bg-secondary.bg-darken-4.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(61,63,74,.6)
}

.btn-secondary.btn-darken-4 {
    border-color: #545766!important
}

.btn-secondary.btn-darken-4:hover {
    border-color: #545766!important;
    background-color: #484b58!important
}

.btn-secondary.btn-darken-4:active,.btn-secondary.btn-darken-4:focus {
    border-color: #484b58!important;
    background-color: #3d3f4a!important
}

.btn-outline-secondary.btn-outline-darken-4 {
    border-color: #3d3f4a!important;
    color: #3d3f4a!important
}

.btn-outline-secondary.btn-outline-darken-4:hover {
    background-color: #3d3f4a!important
}

input:focus~.bg-secondary {
    box-shadow: 0 0 0 .075rem #fff,0 0 0 .21rem #3d3f4a!important
}

.border-secondary.border-darken-4 {
    border: 1px solid #3d3f4a!important
}

.border-top-secondary.border-top-darken-4 {
    border-top: 1px solid #3d3f4a!important
}

.border-bottom-secondary.border-bottom-darken-4 {
    border-bottom: 1px solid #3d3f4a!important
}

.border-left-secondary.border-left-darken-4 {
    border-left: 1px solid #3d3f4a!important
}

.border-right-secondary.border-right-darken-4 {
    border-right: 1px solid #3d3f4a!important
}

.overlay-secondary.overlay-darken-4 {
    background: #3d3f4a;
    background: rgba(61,63,74,.8)
}

.bg-success.bg-lighten-5,.btn-success.btn-lighten-5 {
    background-color: #8ee9c9!important
}

.success.lighten-5 {
    color: #8ee9c9!important
}

.bg-success.bg-lighten-5.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(142,233,201,.6)
}

.btn-success.btn-lighten-5 {
    border-color: #20a576!important
}

.btn-success.btn-lighten-5:hover {
    border-color: #20a576!important;
    background-color: #1c9066!important
}

.btn-success.btn-lighten-5:active,.btn-success.btn-lighten-5:focus {
    border-color: #1c9066!important;
    background-color: #187a57!important
}

.btn-outline-success.btn-outline-lighten-5 {
    border-color: #8ee9c9!important;
    color: #8ee9c9!important
}

.btn-outline-success.btn-outline-lighten-5:hover {
    background-color: #8ee9c9!important
}

.border-success.border-lighten-5 {
    border: 1px solid #8ee9c9!important
}

.border-top-success.border-top-lighten-5 {
    border-top: 1px solid #8ee9c9!important
}

.border-bottom-success.border-bottom-lighten-5 {
    border-bottom: 1px solid #8ee9c9!important
}

.border-left-success.border-left-lighten-5 {
    border-left: 1px solid #8ee9c9!important
}

.border-right-success.border-right-lighten-5 {
    border-right: 1px solid #8ee9c9!important
}

.overlay-success.overlay-lighten-5 {
    background: #8ee9c9;
    background: rgba(142,233,201,.8)
}

.bg-success.bg-lighten-4,.btn-success.btn-lighten-4 {
    background-color: #79e5be!important
}

.success.lighten-4 {
    color: #79e5be!important
}

.bg-success.bg-lighten-4.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(121,229,190,.6)
}

.btn-success.btn-lighten-4 {
    border-color: #20a576!important
}

.btn-success.btn-lighten-4:hover {
    border-color: #20a576!important;
    background-color: #1c9066!important
}

.btn-success.btn-lighten-4:active,.btn-success.btn-lighten-4:focus {
    border-color: #1c9066!important;
    background-color: #187a57!important
}

.btn-outline-success.btn-outline-lighten-4 {
    border-color: #79e5be!important;
    color: #79e5be!important
}

.btn-outline-success.btn-outline-lighten-4:hover {
    background-color: #79e5be!important
}

.border-success.border-lighten-4 {
    border: 1px solid #79e5be!important
}

.border-top-success.border-top-lighten-4 {
    border-top: 1px solid #79e5be!important
}

.border-bottom-success.border-bottom-lighten-4 {
    border-bottom: 1px solid #79e5be!important
}

.border-left-success.border-left-lighten-4 {
    border-left: 1px solid #79e5be!important
}

.border-right-success.border-right-lighten-4 {
    border-right: 1px solid #79e5be!important
}

.overlay-success.overlay-lighten-4 {
    background: #79e5be;
    background: rgba(121,229,190,.8)
}

.bg-success.bg-lighten-3,.btn-success.btn-lighten-3 {
    background-color: #63e1b4!important
}

.success.lighten-3 {
    color: #63e1b4!important
}

.bg-success.bg-lighten-3.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(99,225,180,.6)
}

.btn-success.btn-lighten-3 {
    border-color: #20a576!important
}

.btn-success.btn-lighten-3:hover {
    border-color: #20a576!important;
    background-color: #1c9066!important
}

.btn-success.btn-lighten-3:active,.btn-success.btn-lighten-3:focus {
    border-color: #1c9066!important;
    background-color: #187a57!important
}

.btn-outline-success.btn-outline-lighten-3 {
    border-color: #63e1b4!important;
    color: #63e1b4!important
}

.btn-outline-success.btn-outline-lighten-3:hover {
    background-color: #63e1b4!important
}

.border-success.border-lighten-3 {
    border: 1px solid #63e1b4!important
}

.border-top-success.border-top-lighten-3 {
    border-top: 1px solid #63e1b4!important
}

.border-bottom-success.border-bottom-lighten-3 {
    border-bottom: 1px solid #63e1b4!important
}

.border-left-success.border-left-lighten-3 {
    border-left: 1px solid #63e1b4!important
}

.border-right-success.border-right-lighten-3 {
    border-right: 1px solid #63e1b4!important
}

.overlay-success.overlay-lighten-3 {
    background: #63e1b4;
    background: rgba(99,225,180,.8)
}

.bg-success.bg-lighten-2,.btn-success.btn-lighten-2 {
    background-color: #4eddaa!important
}

.success.lighten-2 {
    color: #4eddaa!important
}

.bg-success.bg-lighten-2.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(78,221,170,.6)
}

.btn-success.btn-lighten-2 {
    border-color: #20a576!important
}

.btn-success.btn-lighten-2:hover {
    border-color: #20a576!important;
    background-color: #1c9066!important
}

.btn-success.btn-lighten-2:active,.btn-success.btn-lighten-2:focus {
    border-color: #1c9066!important;
    background-color: #187a57!important
}

.btn-outline-success.btn-outline-lighten-2 {
    border-color: #4eddaa!important;
    color: #4eddaa!important
}

.btn-outline-success.btn-outline-lighten-2:hover {
    background-color: #4eddaa!important
}

.border-success.border-lighten-2 {
    border: 1px solid #4eddaa!important
}

.border-top-success.border-top-lighten-2 {
    border-top: 1px solid #4eddaa!important
}

.border-bottom-success.border-bottom-lighten-2 {
    border-bottom: 1px solid #4eddaa!important
}

.border-left-success.border-left-lighten-2 {
    border-left: 1px solid #4eddaa!important
}

.border-right-success.border-right-lighten-2 {
    border-right: 1px solid #4eddaa!important
}

.overlay-success.overlay-lighten-2 {
    background: #4eddaa;
    background: rgba(78,221,170,.8)
}

.bg-success.bg-lighten-1,.btn-success.btn-lighten-1 {
    background-color: #39d9a0!important
}

.success.lighten-1 {
    color: #39d9a0!important
}

.bg-success.bg-lighten-1.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(57,217,160,.6)
}

.bg-success.bg-glow,.btn-outline-success.btn-glow,.btn-success.btn-glow {
    box-shadow: 0 1px 20px 1px rgba(40,208,148,.6)
}

.btn-success.btn-lighten-1 {
    border-color: #20a576!important
}

.btn-success.btn-lighten-1:hover {
    border-color: #20a576!important;
    background-color: #1c9066!important
}

.btn-success.btn-lighten-1:active,.btn-success.btn-lighten-1:focus {
    border-color: #1c9066!important;
    background-color: #187a57!important
}

.btn-outline-success.btn-outline-lighten-1 {
    border-color: #39d9a0!important;
    color: #39d9a0!important
}

.btn-outline-success.btn-outline-lighten-1:hover {
    background-color: #39d9a0!important
}

.border-success.border-lighten-1 {
    border: 1px solid #39d9a0!important
}

.border-top-success.border-top-lighten-1 {
    border-top: 1px solid #39d9a0!important
}

.border-bottom-success.border-bottom-lighten-1 {
    border-bottom: 1px solid #39d9a0!important
}

.border-left-success.border-left-lighten-1 {
    border-left: 1px solid #39d9a0!important
}

.border-right-success.border-right-lighten-1 {
    border-right: 1px solid #39d9a0!important
}

.alert-success,.btn-success {
    border-color: #28d094!important
}

.overlay-success.overlay-lighten-1 {
    background: #39d9a0;
    background: rgba(57,217,160,.8)
}

.success {
    color: #28d094!important
}

.bg-success {
    background-color: #28d094!important
}

.bg-success .card-footer,.bg-success .card-header {
    background-color: transparent
}

.selectboxit.selectboxit-btn.bg-success {
    background-color: #28d094!important
}

.alert-success {
    background-color: #5fe0b2!important;
    color: #0b3a29!important
}

.alert-success .alert-link {
    color: #051811!important
}

.overlay-success {
    background: #28d094;
    background: rgba(40,208,148,.8)
}

.btn-success,.color-info.success {
    background-color: #28d094!important
}

.btn-success {
    color: #FFF
}

.btn-success:hover {
    border-color: #39d9a0!important;
    background-color: #39d9a0!important;
    color: #FFF!important
}

.btn-success:active,.btn-success:focus {
    border-color: #1c9066!important;
    background-color: #1c9066!important;
    color: #FFF!important
}

.btn-outline-success {
    border-color: #28d094;
    background-color: transparent;
    color: #28d094
}

.btn-outline-success:hover {
    background-color: #28d094;
    color: #FFF!important
}

input[type=checkbox].bg-success+.custom-control-label:before,input[type=radio].bg-success+.custom-control-label:before {
    background-color: #28d094!important
}

.border-success {
    border: 1px solid #28d094!important
}

.border-top-success {
    border-top: 1px solid #28d094
}

.border-bottom-success {
    border-bottom: 1px solid #28d094
}

.border-left-success {
    border-left: 1px solid #28d094
}

.border-right-success {
    border-right: 1px solid #28d094
}

.bullet.bullet-success {
    background-color: #28d094
}

.bg-success.bg-darken-1,.btn-success.btn-darken-1 {
    background-color: #24bb85!important
}

.success.darken-1 {
    color: #24bb85!important
}

.bg-success.bg-darken-1.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(36,187,133,.6)
}

.btn-success.btn-darken-1 {
    border-color: #20a576!important
}

.btn-success.btn-darken-1:hover {
    border-color: #20a576!important;
    background-color: #1c9066!important
}

.btn-success.btn-darken-1:active,.btn-success.btn-darken-1:focus {
    border-color: #1c9066!important;
    background-color: #187a57!important
}

.btn-outline-success.btn-outline-darken-1 {
    border-color: #24bb85!important;
    color: #24bb85!important
}

.btn-outline-success.btn-outline-darken-1:hover {
    background-color: #24bb85!important
}

.border-success.border-darken-1 {
    border: 1px solid #24bb85!important
}

.border-top-success.border-top-darken-1 {
    border-top: 1px solid #24bb85!important
}

.border-bottom-success.border-bottom-darken-1 {
    border-bottom: 1px solid #24bb85!important
}

.border-left-success.border-left-darken-1 {
    border-left: 1px solid #24bb85!important
}

.border-right-success.border-right-darken-1 {
    border-right: 1px solid #24bb85!important
}

.overlay-success.overlay-darken-1 {
    background: #24bb85;
    background: rgba(36,187,133,.8)
}

.bg-success.bg-darken-2,.btn-success.btn-darken-2 {
    background-color: #20a576!important
}

.success.darken-2 {
    color: #20a576!important
}

.bg-success.bg-darken-2.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(32,165,118,.6)
}

.btn-success.btn-darken-2 {
    border-color: #20a576!important
}

.btn-success.btn-darken-2:hover {
    border-color: #20a576!important;
    background-color: #1c9066!important
}

.btn-success.btn-darken-2:active,.btn-success.btn-darken-2:focus {
    border-color: #1c9066!important;
    background-color: #187a57!important
}

.btn-outline-success.btn-outline-darken-2 {
    border-color: #20a576!important;
    color: #20a576!important
}

.btn-outline-success.btn-outline-darken-2:hover {
    background-color: #20a576!important
}

.border-success.border-darken-2 {
    border: 1px solid #20a576!important
}

.border-top-success.border-top-darken-2 {
    border-top: 1px solid #20a576!important
}

.border-bottom-success.border-bottom-darken-2 {
    border-bottom: 1px solid #20a576!important
}

.border-left-success.border-left-darken-2 {
    border-left: 1px solid #20a576!important
}

.border-right-success.border-right-darken-2 {
    border-right: 1px solid #20a576!important
}

.overlay-success.overlay-darken-2 {
    background: #20a576;
    background: rgba(32,165,118,.8)
}

.success.darken-3 {
    color: #1c9066!important
}

.bg-success.bg-darken-3 {
    background-color: #1c9066!important
}

.bg-success.bg-darken-3.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(28,144,102,.6)
}

.btn-success.btn-darken-3,.btn-success.btn-darken-3:hover {
    border-color: #20a576!important;
    background-color: #1c9066!important
}

.btn-success.btn-darken-3:active,.btn-success.btn-darken-3:focus {
    border-color: #1c9066!important;
    background-color: #187a57!important
}

.btn-outline-success.btn-outline-darken-3 {
    border-color: #1c9066!important;
    color: #1c9066!important
}

.btn-outline-success.btn-outline-darken-3:hover {
    background-color: #1c9066!important
}

.border-success.border-darken-3 {
    border: 1px solid #1c9066!important
}

.border-top-success.border-top-darken-3 {
    border-top: 1px solid #1c9066!important
}

.border-bottom-success.border-bottom-darken-3 {
    border-bottom: 1px solid #1c9066!important
}

.border-left-success.border-left-darken-3 {
    border-left: 1px solid #1c9066!important
}

.border-right-success.border-right-darken-3 {
    border-right: 1px solid #1c9066!important
}

.overlay-success.overlay-darken-3 {
    background: #1c9066;
    background: rgba(28,144,102,.8)
}

.bg-success.bg-darken-4,.btn-success.btn-darken-4 {
    background-color: #187a57!important
}

.success.darken-4 {
    color: #187a57!important
}

.bg-success.bg-darken-4.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(24,122,87,.6)
}

.btn-success.btn-darken-4 {
    border-color: #20a576!important
}

.btn-success.btn-darken-4:hover {
    border-color: #20a576!important;
    background-color: #1c9066!important
}

.btn-success.btn-darken-4:active,.btn-success.btn-darken-4:focus {
    border-color: #1c9066!important;
    background-color: #187a57!important
}

.btn-outline-success.btn-outline-darken-4 {
    border-color: #187a57!important;
    color: #187a57!important
}

.btn-outline-success.btn-outline-darken-4:hover {
    background-color: #187a57!important
}

.border-success.border-darken-4 {
    border: 1px solid #187a57!important
}

.border-top-success.border-top-darken-4 {
    border-top: 1px solid #187a57!important
}

.border-bottom-success.border-bottom-darken-4 {
    border-bottom: 1px solid #187a57!important
}

.border-left-success.border-left-darken-4 {
    border-left: 1px solid #187a57!important
}

.border-right-success.border-right-darken-4 {
    border-right: 1px solid #187a57!important
}

.overlay-success.overlay-darken-4 {
    background: #187a57;
    background: rgba(24,122,87,.8)
}

.bg-success.bg-accent-1,.btn-success.btn-accent-1 {
    background-color: #e1fff0!important
}

.success.accent-1 {
    color: #e1fff0!important
}

.bg-success.bg-accent-1.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(225,255,240,.6)
}

.btn-success.btn-accent-1 {
    border-color: #20a576!important
}

.btn-success.btn-accent-1:hover {
    border-color: #20a576!important;
    background-color: #1c9066!important
}

.btn-success.btn-accent-1:active,.btn-success.btn-accent-1:focus {
    border-color: #1c9066!important;
    background-color: #187a57!important
}

.btn-outline-success.btn-outline-accent-1 {
    border-color: #e1fff0!important;
    color: #e1fff0!important
}

.btn-outline-success.btn-outline-accent-1:hover {
    background-color: #e1fff0!important
}

.border-success.border-accent-1 {
    border: 1px solid #e1fff0!important
}

.border-top-success.border-top-accent-1 {
    border-top: 1px solid #e1fff0!important
}

.border-bottom-success.border-bottom-accent-1 {
    border-bottom: 1px solid #e1fff0!important
}

.border-left-success.border-left-accent-1 {
    border-left: 1px solid #e1fff0!important
}

.border-right-success.border-right-accent-1 {
    border-right: 1px solid #e1fff0!important
}

.overlay-success.overlay-accent-1 {
    background: #e1fff0;
    background: rgba(225,255,240,.8)
}

.bg-success.bg-accent-2,.btn-success.btn-accent-2 {
    background-color: #aeffd5!important
}

.success.accent-2 {
    color: #aeffd5!important
}

.bg-success.bg-accent-2.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(174,255,213,.6)
}

.btn-success.btn-accent-2 {
    border-color: #20a576!important
}

.btn-success.btn-accent-2:hover {
    border-color: #20a576!important;
    background-color: #1c9066!important
}

.btn-success.btn-accent-2:active,.btn-success.btn-accent-2:focus {
    border-color: #1c9066!important;
    background-color: #187a57!important
}

.btn-outline-success.btn-outline-accent-2 {
    border-color: #aeffd5!important;
    color: #aeffd5!important
}

.btn-outline-success.btn-outline-accent-2:hover {
    background-color: #aeffd5!important
}

.border-success.border-accent-2 {
    border: 1px solid #aeffd5!important
}

.border-top-success.border-top-accent-2 {
    border-top: 1px solid #aeffd5!important
}

.border-bottom-success.border-bottom-accent-2 {
    border-bottom: 1px solid #aeffd5!important
}

.border-left-success.border-left-accent-2 {
    border-left: 1px solid #aeffd5!important
}

.border-right-success.border-right-accent-2 {
    border-right: 1px solid #aeffd5!important
}

.overlay-success.overlay-accent-2 {
    background: #aeffd5;
    background: rgba(174,255,213,.8)
}

.bg-success.bg-accent-3,.btn-success.btn-accent-3 {
    background-color: #7bffbb!important
}

.success.accent-3 {
    color: #7bffbb!important
}

.bg-success.bg-accent-3.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(123,255,187,.6)
}

.btn-success.btn-accent-3 {
    border-color: #20a576!important
}

.btn-success.btn-accent-3:hover {
    border-color: #20a576!important;
    background-color: #1c9066!important
}

.btn-success.btn-accent-3:active,.btn-success.btn-accent-3:focus {
    border-color: #1c9066!important;
    background-color: #187a57!important
}

.btn-outline-success.btn-outline-accent-3 {
    border-color: #7bffbb!important;
    color: #7bffbb!important
}

.btn-outline-success.btn-outline-accent-3:hover {
    background-color: #7bffbb!important
}

.border-success.border-accent-3 {
    border: 1px solid #7bffbb!important
}

.border-top-success.border-top-accent-3 {
    border-top: 1px solid #7bffbb!important
}

.border-bottom-success.border-bottom-accent-3 {
    border-bottom: 1px solid #7bffbb!important
}

.border-left-success.border-left-accent-3 {
    border-left: 1px solid #7bffbb!important
}

.border-right-success.border-right-accent-3 {
    border-right: 1px solid #7bffbb!important
}

.overlay-success.overlay-accent-3 {
    background: #7bffbb;
    background: rgba(123,255,187,.8)
}

.bg-success.bg-accent-4,.btn-success.btn-accent-4 {
    background-color: #62ffae!important
}

.success.accent-4 {
    color: #62ffae!important
}

.bg-success.bg-accent-4.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(98,255,174,.6)
}

.btn-success.btn-accent-4 {
    border-color: #20a576!important
}

.btn-success.btn-accent-4:hover {
    border-color: #20a576!important;
    background-color: #1c9066!important
}

.btn-success.btn-accent-4:active,.btn-success.btn-accent-4:focus {
    border-color: #1c9066!important;
    background-color: #187a57!important
}

.btn-outline-success.btn-outline-accent-4 {
    border-color: #62ffae!important;
    color: #62ffae!important
}

.btn-outline-success.btn-outline-accent-4:hover {
    background-color: #62ffae!important
}

input:focus~.bg-success {
    box-shadow: 0 0 0 .075rem #fff,0 0 0 .21rem #62ffae!important
}

.border-success.border-accent-4 {
    border: 1px solid #62ffae!important
}

.border-top-success.border-top-accent-4 {
    border-top: 1px solid #62ffae!important
}

.border-bottom-success.border-bottom-accent-4 {
    border-bottom: 1px solid #62ffae!important
}

.border-left-success.border-left-accent-4 {
    border-left: 1px solid #62ffae!important
}

.border-right-success.border-right-accent-4 {
    border-right: 1px solid #62ffae!important
}

.btn-info.btn-lighten-5,.btn-info.btn-lighten-5:hover {
    border-color: #0c84d1!important
}

.overlay-success.overlay-accent-4 {
    background: #62ffae;
    background: rgba(98,255,174,.8)
}

.bg-info.bg-lighten-5,.btn-info.btn-lighten-5 {
    background-color: #97d2f9!important
}

.info.lighten-5 {
    color: #97d2f9!important
}

.bg-info.bg-lighten-5.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(151,210,249,.6)
}

.btn-info.btn-lighten-5:hover {
    background-color: #0b75b9!important
}

.btn-info.btn-lighten-5:active,.btn-info.btn-lighten-5:focus {
    border-color: #0b75b9!important;
    background-color: #0965a1!important
}

.btn-outline-info.btn-outline-lighten-5 {
    border-color: #97d2f9!important;
    color: #97d2f9!important
}

.btn-outline-info.btn-outline-lighten-5:hover {
    background-color: #97d2f9!important
}

.border-info.border-lighten-5 {
    border: 1px solid #97d2f9!important
}

.border-top-info.border-top-lighten-5 {
    border-top: 1px solid #97d2f9!important
}

.border-bottom-info.border-bottom-lighten-5 {
    border-bottom: 1px solid #97d2f9!important
}

.border-left-info.border-left-lighten-5 {
    border-left: 1px solid #97d2f9!important
}

.border-right-info.border-right-lighten-5 {
    border-right: 1px solid #97d2f9!important
}

.btn-info.btn-lighten-4,.btn-info.btn-lighten-4:hover {
    border-color: #0c84d1!important
}

.overlay-info.overlay-lighten-5 {
    background: #97d2f9;
    background: rgba(151,210,249,.8)
}

.bg-info.bg-lighten-4,.btn-info.btn-lighten-4 {
    background-color: #7ec8f8!important
}

.info.lighten-4 {
    color: #7ec8f8!important
}

.bg-info.bg-lighten-4.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(126,200,248,.6)
}

.btn-info.btn-lighten-4:hover {
    background-color: #0b75b9!important
}

.btn-info.btn-lighten-4:active,.btn-info.btn-lighten-4:focus {
    border-color: #0b75b9!important;
    background-color: #0965a1!important
}

.btn-outline-info.btn-outline-lighten-4 {
    border-color: #7ec8f8!important;
    color: #7ec8f8!important
}

.btn-outline-info.btn-outline-lighten-4:hover {
    background-color: #7ec8f8!important
}

.border-info.border-lighten-4 {
    border: 1px solid #7ec8f8!important
}

.border-top-info.border-top-lighten-4 {
    border-top: 1px solid #7ec8f8!important
}

.border-bottom-info.border-bottom-lighten-4 {
    border-bottom: 1px solid #7ec8f8!important
}

.border-left-info.border-left-lighten-4 {
    border-left: 1px solid #7ec8f8!important
}

.border-right-info.border-right-lighten-4 {
    border-right: 1px solid #7ec8f8!important
}

.btn-info.btn-lighten-3,.btn-info.btn-lighten-3:hover {
    border-color: #0c84d1!important
}

.overlay-info.overlay-lighten-4 {
    background: #7ec8f8;
    background: rgba(126,200,248,.8)
}

.bg-info.bg-lighten-3,.btn-info.btn-lighten-3 {
    background-color: #66bef6!important
}

.info.lighten-3 {
    color: #66bef6!important
}

.bg-info.bg-lighten-3.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(102,190,246,.6)
}

.btn-info.btn-lighten-3:hover {
    background-color: #0b75b9!important
}

.btn-info.btn-lighten-3:active,.btn-info.btn-lighten-3:focus {
    border-color: #0b75b9!important;
    background-color: #0965a1!important
}

.btn-outline-info.btn-outline-lighten-3 {
    border-color: #66bef6!important;
    color: #66bef6!important
}

.btn-outline-info.btn-outline-lighten-3:hover {
    background-color: #66bef6!important
}

.border-info.border-lighten-3 {
    border: 1px solid #66bef6!important
}

.border-top-info.border-top-lighten-3 {
    border-top: 1px solid #66bef6!important
}

.border-bottom-info.border-bottom-lighten-3 {
    border-bottom: 1px solid #66bef6!important
}

.border-left-info.border-left-lighten-3 {
    border-left: 1px solid #66bef6!important
}

.border-right-info.border-right-lighten-3 {
    border-right: 1px solid #66bef6!important
}

.btn-info.btn-lighten-2,.btn-info.btn-lighten-2:hover {
    border-color: #0c84d1!important
}

.overlay-info.overlay-lighten-3 {
    background: #66bef6;
    background: rgba(102,190,246,.8)
}

.bg-info.bg-lighten-2,.btn-info.btn-lighten-2 {
    background-color: #4eb4f5!important
}

.info.lighten-2 {
    color: #4eb4f5!important
}

.bg-info.bg-lighten-2.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(78,180,245,.6)
}

.btn-info.btn-lighten-2:hover {
    background-color: #0b75b9!important
}

.btn-info.btn-lighten-2:active,.btn-info.btn-lighten-2:focus {
    border-color: #0b75b9!important;
    background-color: #0965a1!important
}

.btn-outline-info.btn-outline-lighten-2 {
    border-color: #4eb4f5!important;
    color: #4eb4f5!important
}

.btn-outline-info.btn-outline-lighten-2:hover {
    background-color: #4eb4f5!important
}

.border-info.border-lighten-2 {
    border: 1px solid #4eb4f5!important
}

.border-top-info.border-top-lighten-2 {
    border-top: 1px solid #4eb4f5!important
}

.border-bottom-info.border-bottom-lighten-2 {
    border-bottom: 1px solid #4eb4f5!important
}

.border-left-info.border-left-lighten-2 {
    border-left: 1px solid #4eb4f5!important
}

.border-right-info.border-right-lighten-2 {
    border-right: 1px solid #4eb4f5!important
}

.btn-info.btn-lighten-1,.btn-info.btn-lighten-1:hover {
    border-color: #0c84d1!important
}

.overlay-info.overlay-lighten-2 {
    background: #4eb4f5;
    background: rgba(78,180,245,.8)
}

.bg-info.bg-lighten-1,.btn-info.btn-lighten-1 {
    background-color: #36a9f3!important
}

.info.lighten-1 {
    color: #36a9f3!important
}

.bg-info.bg-lighten-1.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(54,169,243,.6)
}

.bg-info.bg-glow,.btn-info.btn-glow,.btn-outline-info.btn-glow {
    box-shadow: 0 1px 20px 1px rgba(30,159,242,.6)
}

.btn-info.btn-lighten-1:hover {
    background-color: #0b75b9!important
}

.btn-info.btn-lighten-1:active,.btn-info.btn-lighten-1:focus {
    border-color: #0b75b9!important;
    background-color: #0965a1!important
}

.btn-outline-info.btn-outline-lighten-1 {
    border-color: #36a9f3!important;
    color: #36a9f3!important
}

.btn-outline-info.btn-outline-lighten-1:hover {
    background-color: #36a9f3!important
}

.border-info.border-lighten-1 {
    border: 1px solid #36a9f3!important
}

.border-top-info.border-top-lighten-1 {
    border-top: 1px solid #36a9f3!important
}

.border-bottom-info.border-bottom-lighten-1 {
    border-bottom: 1px solid #36a9f3!important
}

.border-left-info.border-left-lighten-1 {
    border-left: 1px solid #36a9f3!important
}

.border-right-info.border-right-lighten-1 {
    border-right: 1px solid #36a9f3!important
}

.alert-info,.btn-info {
    border-color: #1e9ff2!important
}

.overlay-info.overlay-lighten-1 {
    background: #36a9f3;
    background: rgba(54,169,243,.8)
}

.info {
    color: #1e9ff2!important
}

.bg-info {
    background-color: #1e9ff2!important
}

.bg-info .card-footer,.bg-info .card-header {
    background-color: transparent
}

.selectboxit.selectboxit-btn.bg-info {
    background-color: #1e9ff2!important
}

.alert-info {
    background-color: #62bcf6!important;
    color: #053858!important
}

.alert-info .alert-link {
    color: #031f32!important
}

.overlay-info {
    background: #1e9ff2;
    background: rgba(30,159,242,.8)
}

.btn-info,.color-info.info {
    background-color: #1e9ff2!important
}

.btn-info {
    color: #FFF
}

.btn-info:hover {
    border-color: #36a9f3!important;
    background-color: #36a9f3!important;
    color: #FFF!important
}

.btn-info:active,.btn-info:focus {
    border-color: #0b75b9!important;
    background-color: #0b75b9!important;
    color: #FFF!important
}

.btn-outline-info {
    border-color: #1e9ff2;
    background-color: transparent;
    color: #1e9ff2
}

.btn-outline-info:hover {
    background-color: #1e9ff2;
    color: #FFF!important
}

input[type=checkbox].bg-info+.custom-control-label:before,input[type=radio].bg-info+.custom-control-label:before {
    background-color: #1e9ff2!important
}

.border-info {
    border: 1px solid #1e9ff2!important
}

.border-top-info {
    border-top: 1px solid #1e9ff2
}

.border-bottom-info {
    border-bottom: 1px solid #1e9ff2
}

.border-left-info {
    border-left: 1px solid #1e9ff2
}

.border-right-info {
    border-right: 1px solid #1e9ff2
}

.btn-info.btn-darken-1,.btn-info.btn-darken-1:hover {
    border-color: #0c84d1!important
}

.bullet.bullet-info {
    background-color: #1e9ff2
}

.bg-info.bg-darken-1,.btn-info.btn-darken-1 {
    background-color: #0d93e9!important
}

.info.darken-1 {
    color: #0d93e9!important
}

.bg-info.bg-darken-1.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(13,147,233,.6)
}

.btn-info.btn-darken-1:hover {
    background-color: #0b75b9!important
}

.btn-info.btn-darken-1:active,.btn-info.btn-darken-1:focus {
    border-color: #0b75b9!important;
    background-color: #0965a1!important
}

.btn-outline-info.btn-outline-darken-1 {
    border-color: #0d93e9!important;
    color: #0d93e9!important
}

.btn-outline-info.btn-outline-darken-1:hover {
    background-color: #0d93e9!important
}

.border-info.border-darken-1 {
    border: 1px solid #0d93e9!important
}

.border-top-info.border-top-darken-1 {
    border-top: 1px solid #0d93e9!important
}

.border-bottom-info.border-bottom-darken-1 {
    border-bottom: 1px solid #0d93e9!important
}

.border-left-info.border-left-darken-1 {
    border-left: 1px solid #0d93e9!important
}

.border-right-info.border-right-darken-1 {
    border-right: 1px solid #0d93e9!important
}

.btn-info.btn-darken-2,.btn-info.btn-darken-2:hover {
    border-color: #0c84d1!important
}

.overlay-info.overlay-darken-1 {
    background: #0d93e9;
    background: rgba(13,147,233,.8)
}

.bg-info.bg-darken-2,.btn-info.btn-darken-2 {
    background-color: #0c84d1!important
}

.info.darken-2 {
    color: #0c84d1!important
}

.bg-info.bg-darken-2.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(12,132,209,.6)
}

.btn-info.btn-darken-2:hover {
    background-color: #0b75b9!important
}

.btn-info.btn-darken-2:active,.btn-info.btn-darken-2:focus {
    border-color: #0b75b9!important;
    background-color: #0965a1!important
}

.btn-outline-info.btn-outline-darken-2 {
    border-color: #0c84d1!important;
    color: #0c84d1!important
}

.btn-outline-info.btn-outline-darken-2:hover {
    background-color: #0c84d1!important
}

.border-info.border-darken-2 {
    border: 1px solid #0c84d1!important
}

.border-top-info.border-top-darken-2 {
    border-top: 1px solid #0c84d1!important
}

.border-bottom-info.border-bottom-darken-2 {
    border-bottom: 1px solid #0c84d1!important
}

.border-left-info.border-left-darken-2 {
    border-left: 1px solid #0c84d1!important
}

.border-right-info.border-right-darken-2 {
    border-right: 1px solid #0c84d1!important
}

.overlay-info.overlay-darken-2 {
    background: #0c84d1;
    background: rgba(12,132,209,.8)
}

.info.darken-3 {
    color: #0b75b9!important
}

.bg-info.bg-darken-3 {
    background-color: #0b75b9!important
}

.bg-info.bg-darken-3.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(11,117,185,.6)
}

.btn-info.btn-darken-3,.btn-info.btn-darken-3:hover {
    border-color: #0c84d1!important;
    background-color: #0b75b9!important
}

.btn-info.btn-darken-3:active,.btn-info.btn-darken-3:focus {
    border-color: #0b75b9!important;
    background-color: #0965a1!important
}

.btn-outline-info.btn-outline-darken-3 {
    border-color: #0b75b9!important;
    color: #0b75b9!important
}

.btn-outline-info.btn-outline-darken-3:hover {
    background-color: #0b75b9!important
}

.border-info.border-darken-3 {
    border: 1px solid #0b75b9!important
}

.border-top-info.border-top-darken-3 {
    border-top: 1px solid #0b75b9!important
}

.border-bottom-info.border-bottom-darken-3 {
    border-bottom: 1px solid #0b75b9!important
}

.border-left-info.border-left-darken-3 {
    border-left: 1px solid #0b75b9!important
}

.border-right-info.border-right-darken-3 {
    border-right: 1px solid #0b75b9!important
}

.btn-info.btn-darken-4,.btn-info.btn-darken-4:hover {
    border-color: #0c84d1!important
}

.overlay-info.overlay-darken-3 {
    background: #0b75b9;
    background: rgba(11,117,185,.8)
}

.bg-info.bg-darken-4,.btn-info.btn-darken-4 {
    background-color: #0965a1!important
}

.info.darken-4 {
    color: #0965a1!important
}

.bg-info.bg-darken-4.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(9,101,161,.6)
}

.btn-info.btn-darken-4:hover {
    background-color: #0b75b9!important
}

.btn-info.btn-darken-4:active,.btn-info.btn-darken-4:focus {
    border-color: #0b75b9!important;
    background-color: #0965a1!important
}

.btn-outline-info.btn-outline-darken-4 {
    border-color: #0965a1!important;
    color: #0965a1!important
}

.btn-outline-info.btn-outline-darken-4:hover {
    background-color: #0965a1!important
}

.border-info.border-darken-4 {
    border: 1px solid #0965a1!important
}

.border-top-info.border-top-darken-4 {
    border-top: 1px solid #0965a1!important
}

.border-bottom-info.border-bottom-darken-4 {
    border-bottom: 1px solid #0965a1!important
}

.border-left-info.border-left-darken-4 {
    border-left: 1px solid #0965a1!important
}

.border-right-info.border-right-darken-4 {
    border-right: 1px solid #0965a1!important
}

.btn-info.btn-accent-1,.btn-info.btn-accent-1:hover {
    border-color: #0c84d1!important
}

.overlay-info.overlay-darken-4 {
    background: #0965a1;
    background: rgba(9,101,161,.8)
}

.bg-info.bg-accent-1,.btn-info.btn-accent-1 {
    background-color: #eff5ff!important
}

.info.accent-1 {
    color: #eff5ff!important
}

.bg-info.bg-accent-1.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(239,245,255,.6)
}

.btn-info.btn-accent-1:hover {
    background-color: #0b75b9!important
}

.btn-info.btn-accent-1:active,.btn-info.btn-accent-1:focus {
    border-color: #0b75b9!important;
    background-color: #0965a1!important
}

.btn-outline-info.btn-outline-accent-1 {
    border-color: #eff5ff!important;
    color: #eff5ff!important
}

.btn-outline-info.btn-outline-accent-1:hover {
    background-color: #eff5ff!important
}

.border-info.border-accent-1 {
    border: 1px solid #eff5ff!important
}

.border-top-info.border-top-accent-1 {
    border-top: 1px solid #eff5ff!important
}

.border-bottom-info.border-bottom-accent-1 {
    border-bottom: 1px solid #eff5ff!important
}

.border-left-info.border-left-accent-1 {
    border-left: 1px solid #eff5ff!important
}

.border-right-info.border-right-accent-1 {
    border-right: 1px solid #eff5ff!important
}

.btn-info.btn-accent-2,.btn-info.btn-accent-2:hover {
    border-color: #0c84d1!important
}

.overlay-info.overlay-accent-1 {
    background: #eff5ff;
    background: rgba(239,245,255,.8)
}

.bg-info.bg-accent-2,.btn-info.btn-accent-2 {
    background-color: #dfecff!important
}

.info.accent-2 {
    color: #dfecff!important
}

.bg-info.bg-accent-2.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(223,236,255,.6)
}

.btn-info.btn-accent-2:hover {
    background-color: #0b75b9!important
}

.btn-info.btn-accent-2:active,.btn-info.btn-accent-2:focus {
    border-color: #0b75b9!important;
    background-color: #0965a1!important
}

.btn-outline-info.btn-outline-accent-2 {
    border-color: #dfecff!important;
    color: #dfecff!important
}

.btn-outline-info.btn-outline-accent-2:hover {
    background-color: #dfecff!important
}

.border-info.border-accent-2 {
    border: 1px solid #dfecff!important
}

.border-top-info.border-top-accent-2 {
    border-top: 1px solid #dfecff!important
}

.border-bottom-info.border-bottom-accent-2 {
    border-bottom: 1px solid #dfecff!important
}

.border-left-info.border-left-accent-2 {
    border-left: 1px solid #dfecff!important
}

.border-right-info.border-right-accent-2 {
    border-right: 1px solid #dfecff!important
}

.btn-info.btn-accent-3,.btn-info.btn-accent-3:hover {
    border-color: #0c84d1!important
}

.overlay-info.overlay-accent-2 {
    background: #dfecff;
    background: rgba(223,236,255,.8)
}

.bg-info.bg-accent-3,.btn-info.btn-accent-3 {
    background-color: #accfff!important
}

.info.accent-3 {
    color: #accfff!important
}

.bg-info.bg-accent-3.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(172,207,255,.6)
}

.btn-info.btn-accent-3:hover {
    background-color: #0b75b9!important
}

.btn-info.btn-accent-3:active,.btn-info.btn-accent-3:focus {
    border-color: #0b75b9!important;
    background-color: #0965a1!important
}

.btn-outline-info.btn-outline-accent-3 {
    border-color: #accfff!important;
    color: #accfff!important
}

.btn-outline-info.btn-outline-accent-3:hover {
    background-color: #accfff!important
}

.border-info.border-accent-3 {
    border: 1px solid #accfff!important
}

.border-top-info.border-top-accent-3 {
    border-top: 1px solid #accfff!important
}

.border-bottom-info.border-bottom-accent-3 {
    border-bottom: 1px solid #accfff!important
}

.border-left-info.border-left-accent-3 {
    border-left: 1px solid #accfff!important
}

.border-right-info.border-right-accent-3 {
    border-right: 1px solid #accfff!important
}

.btn-info.btn-accent-4,.btn-info.btn-accent-4:hover {
    border-color: #0c84d1!important
}

.overlay-info.overlay-accent-3 {
    background: #accfff;
    background: rgba(172,207,255,.8)
}

.bg-info.bg-accent-4,.btn-info.btn-accent-4 {
    background-color: #92c0ff!important
}

.info.accent-4 {
    color: #92c0ff!important
}

.bg-info.bg-accent-4.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(146,192,255,.6)
}

.btn-info.btn-accent-4:hover {
    background-color: #0b75b9!important
}

.btn-info.btn-accent-4:active,.btn-info.btn-accent-4:focus {
    border-color: #0b75b9!important;
    background-color: #0965a1!important
}

.btn-outline-info.btn-outline-accent-4 {
    border-color: #92c0ff!important;
    color: #92c0ff!important
}

.btn-outline-info.btn-outline-accent-4:hover {
    background-color: #92c0ff!important
}

input:focus~.bg-info {
    box-shadow: 0 0 0 .075rem #fff,0 0 0 .21rem #92c0ff!important
}

.border-info.border-accent-4 {
    border: 1px solid #92c0ff!important
}

.border-top-info.border-top-accent-4 {
    border-top: 1px solid #92c0ff!important
}

.border-bottom-info.border-bottom-accent-4 {
    border-bottom: 1px solid #92c0ff!important
}

.border-left-info.border-left-accent-4 {
    border-left: 1px solid #92c0ff!important
}

.border-right-info.border-right-accent-4 {
    border-right: 1px solid #92c0ff!important
}

.overlay-info.overlay-accent-4 {
    background: #92c0ff;
    background: rgba(146,192,255,.8)
}

.bg-warning.bg-lighten-5,.btn-warning.btn-lighten-5 {
    background-color: #ffdec9!important
}

.warning.lighten-5 {
    color: #ffdec9!important
}

.bg-warning.bg-lighten-5.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(255,222,201,.6)
}

.btn-warning.btn-lighten-5 {
    border-color: #ff7216!important
}

.btn-warning.btn-lighten-5:hover {
    border-color: #ff7216!important;
    background-color: #fc6300!important
}

.btn-warning.btn-lighten-5:active,.btn-warning.btn-lighten-5:focus {
    border-color: #fc6300!important;
    background-color: #e25900!important
}

.btn-outline-warning.btn-outline-lighten-5 {
    border-color: #ffdec9!important;
    color: #ffdec9!important
}

.btn-outline-warning.btn-outline-lighten-5:hover {
    background-color: #ffdec9!important
}

.border-warning.border-lighten-5 {
    border: 1px solid #ffdec9!important
}

.border-top-warning.border-top-lighten-5 {
    border-top: 1px solid #ffdec9!important
}

.border-bottom-warning.border-bottom-lighten-5 {
    border-bottom: 1px solid #ffdec9!important
}

.border-left-warning.border-left-lighten-5 {
    border-left: 1px solid #ffdec9!important
}

.border-right-warning.border-right-lighten-5 {
    border-right: 1px solid #ffdec9!important
}

.overlay-warning.overlay-lighten-5 {
    background: #ffdec9;
    background: rgba(255,222,201,.8)
}

.bg-warning.bg-lighten-4,.btn-warning.btn-lighten-4 {
    background-color: #ffcfaf!important
}

.warning.lighten-4 {
    color: #ffcfaf!important
}

.bg-warning.bg-lighten-4.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(255,207,175,.6)
}

.btn-warning.btn-lighten-4 {
    border-color: #ff7216!important
}

.btn-warning.btn-lighten-4:hover {
    border-color: #ff7216!important;
    background-color: #fc6300!important
}

.btn-warning.btn-lighten-4:active,.btn-warning.btn-lighten-4:focus {
    border-color: #fc6300!important;
    background-color: #e25900!important
}

.btn-outline-warning.btn-outline-lighten-4 {
    border-color: #ffcfaf!important;
    color: #ffcfaf!important
}

.btn-outline-warning.btn-outline-lighten-4:hover {
    background-color: #ffcfaf!important
}

.border-warning.border-lighten-4 {
    border: 1px solid #ffcfaf!important
}

.border-top-warning.border-top-lighten-4 {
    border-top: 1px solid #ffcfaf!important
}

.border-bottom-warning.border-bottom-lighten-4 {
    border-bottom: 1px solid #ffcfaf!important
}

.border-left-warning.border-left-lighten-4 {
    border-left: 1px solid #ffcfaf!important
}

.border-right-warning.border-right-lighten-4 {
    border-right: 1px solid #ffcfaf!important
}

.overlay-warning.overlay-lighten-4 {
    background: #ffcfaf;
    background: rgba(255,207,175,.8)
}

.bg-warning.bg-lighten-3,.btn-warning.btn-lighten-3 {
    background-color: #ffbf96!important
}

.warning.lighten-3 {
    color: #ffbf96!important
}

.bg-warning.bg-lighten-3.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(255,191,150,.6)
}

.btn-warning.btn-lighten-3 {
    border-color: #ff7216!important
}

.btn-warning.btn-lighten-3:hover {
    border-color: #ff7216!important;
    background-color: #fc6300!important
}

.btn-warning.btn-lighten-3:active,.btn-warning.btn-lighten-3:focus {
    border-color: #fc6300!important;
    background-color: #e25900!important
}

.btn-outline-warning.btn-outline-lighten-3 {
    border-color: #ffbf96!important;
    color: #ffbf96!important
}

.btn-outline-warning.btn-outline-lighten-3:hover {
    background-color: #ffbf96!important
}

.border-warning.border-lighten-3 {
    border: 1px solid #ffbf96!important
}

.border-top-warning.border-top-lighten-3 {
    border-top: 1px solid #ffbf96!important
}

.border-bottom-warning.border-bottom-lighten-3 {
    border-bottom: 1px solid #ffbf96!important
}

.border-left-warning.border-left-lighten-3 {
    border-left: 1px solid #ffbf96!important
}

.border-right-warning.border-right-lighten-3 {
    border-right: 1px solid #ffbf96!important
}

.overlay-warning.overlay-lighten-3 {
    background: #ffbf96;
    background: rgba(255,191,150,.8)
}

.bg-warning.bg-lighten-2,.btn-warning.btn-lighten-2 {
    background-color: #ffb07c!important
}

.warning.lighten-2 {
    color: #ffb07c!important
}

.bg-warning.bg-lighten-2.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(255,176,124,.6)
}

.btn-warning.btn-lighten-2 {
    border-color: #ff7216!important
}

.btn-warning.btn-lighten-2:hover {
    border-color: #ff7216!important;
    background-color: #fc6300!important
}

.btn-warning.btn-lighten-2:active,.btn-warning.btn-lighten-2:focus {
    border-color: #fc6300!important;
    background-color: #e25900!important
}

.btn-outline-warning.btn-outline-lighten-2 {
    border-color: #ffb07c!important;
    color: #ffb07c!important
}

.btn-outline-warning.btn-outline-lighten-2:hover {
    background-color: #ffb07c!important
}

.border-warning.border-lighten-2 {
    border: 1px solid #ffb07c!important
}

.border-top-warning.border-top-lighten-2 {
    border-top: 1px solid #ffb07c!important
}

.border-bottom-warning.border-bottom-lighten-2 {
    border-bottom: 1px solid #ffb07c!important
}

.border-left-warning.border-left-lighten-2 {
    border-left: 1px solid #ffb07c!important
}

.border-right-warning.border-right-lighten-2 {
    border-right: 1px solid #ffb07c!important
}

.overlay-warning.overlay-lighten-2 {
    background: #ffb07c;
    background: rgba(255,176,124,.8)
}

.bg-warning.bg-lighten-1,.btn-warning.btn-lighten-1 {
    background-color: #ffa063!important
}

.warning.lighten-1 {
    color: #ffa063!important
}

.bg-warning.bg-lighten-1.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(255,160,99,.6)
}

.bg-warning.bg-glow,.btn-outline-warning.btn-glow,.btn-warning.btn-glow {
    box-shadow: 0 1px 20px 1px rgba(255,145,73,.6)
}

.btn-warning.btn-lighten-1 {
    border-color: #ff7216!important
}

.btn-warning.btn-lighten-1:hover {
    border-color: #ff7216!important;
    background-color: #fc6300!important
}

.btn-warning.btn-lighten-1:active,.btn-warning.btn-lighten-1:focus {
    border-color: #fc6300!important;
    background-color: #e25900!important
}

.btn-outline-warning.btn-outline-lighten-1 {
    border-color: #ffa063!important;
    color: #ffa063!important
}

.btn-outline-warning.btn-outline-lighten-1:hover {
    background-color: #ffa063!important
}

.border-warning.border-lighten-1 {
    border: 1px solid #ffa063!important
}

.border-top-warning.border-top-lighten-1 {
    border-top: 1px solid #ffa063!important
}

.border-bottom-warning.border-bottom-lighten-1 {
    border-bottom: 1px solid #ffa063!important
}

.border-left-warning.border-left-lighten-1 {
    border-left: 1px solid #ffa063!important
}

.border-right-warning.border-right-lighten-1 {
    border-right: 1px solid #ffa063!important
}

.alert-warning,.btn-warning {
    border-color: #ff9149!important
}

.overlay-warning.overlay-lighten-1 {
    background: #ffa063;
    background: rgba(255,160,99,.8)
}

.warning {
    color: #ff9149!important
}

.bg-warning {
    background-color: #ff9149!important
}

.bg-warning .card-footer,.bg-warning .card-header {
    background-color: transparent
}

.selectboxit.selectboxit-btn.bg-warning {
    background-color: #ff9149!important
}

.alert-warning {
    background-color: #ffbc90!important;
    color: #963b00!important
}

.alert-warning .alert-link {
    color: #6d2b00!important
}

.overlay-warning {
    background: #ff9149;
    background: rgba(255,145,73,.8)
}

.btn-warning,.color-info.warning {
    background-color: #ff9149!important
}

.btn-warning {
    color: #FFF
}

.btn-warning:hover {
    border-color: #ffa063!important;
    background-color: #ffa063!important;
    color: #FFF!important
}

.btn-warning:active,.btn-warning:focus {
    border-color: #fc6300!important;
    background-color: #fc6300!important;
    color: #FFF!important
}

.btn-outline-warning {
    border-color: #ff9149;
    background-color: transparent;
    color: #ff9149
}

.btn-outline-warning:hover {
    background-color: #ff9149;
    color: #FFF!important
}

input[type=checkbox].bg-warning+.custom-control-label:before,input[type=radio].bg-warning+.custom-control-label:before {
    background-color: #ff9149!important
}

.border-warning {
    border: 1px solid #ff9149!important
}

.border-top-warning {
    border-top: 1px solid #ff9149
}

.border-bottom-warning {
    border-bottom: 1px solid #ff9149
}

.border-left-warning {
    border-left: 1px solid #ff9149
}

.border-right-warning {
    border-right: 1px solid #ff9149
}

.bullet.bullet-warning {
    background-color: #ff9149
}

.bg-warning.bg-darken-1,.btn-warning.btn-darken-1 {
    background-color: #ff8230!important
}

.warning.darken-1 {
    color: #ff8230!important
}

.bg-warning.bg-darken-1.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(255,130,48,.6)
}

.btn-warning.btn-darken-1 {
    border-color: #ff7216!important
}

.btn-warning.btn-darken-1:hover {
    border-color: #ff7216!important;
    background-color: #fc6300!important
}

.btn-warning.btn-darken-1:active,.btn-warning.btn-darken-1:focus {
    border-color: #fc6300!important;
    background-color: #e25900!important
}

.btn-outline-warning.btn-outline-darken-1 {
    border-color: #ff8230!important;
    color: #ff8230!important
}

.btn-outline-warning.btn-outline-darken-1:hover {
    background-color: #ff8230!important
}

.border-warning.border-darken-1 {
    border: 1px solid #ff8230!important
}

.border-top-warning.border-top-darken-1 {
    border-top: 1px solid #ff8230!important
}

.border-bottom-warning.border-bottom-darken-1 {
    border-bottom: 1px solid #ff8230!important
}

.border-left-warning.border-left-darken-1 {
    border-left: 1px solid #ff8230!important
}

.border-right-warning.border-right-darken-1 {
    border-right: 1px solid #ff8230!important
}

.overlay-warning.overlay-darken-1 {
    background: #ff8230;
    background: rgba(255,130,48,.8)
}

.bg-warning.bg-darken-2,.btn-warning.btn-darken-2 {
    background-color: #ff7216!important
}

.warning.darken-2 {
    color: #ff7216!important
}

.bg-warning.bg-darken-2.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(255,114,22,.6)
}

.btn-warning.btn-darken-2 {
    border-color: #ff7216!important
}

.btn-warning.btn-darken-2:hover {
    border-color: #ff7216!important;
    background-color: #fc6300!important
}

.btn-warning.btn-darken-2:active,.btn-warning.btn-darken-2:focus {
    border-color: #fc6300!important;
    background-color: #e25900!important
}

.btn-outline-warning.btn-outline-darken-2 {
    border-color: #ff7216!important;
    color: #ff7216!important
}

.btn-outline-warning.btn-outline-darken-2:hover {
    background-color: #ff7216!important
}

.border-warning.border-darken-2 {
    border: 1px solid #ff7216!important
}

.border-top-warning.border-top-darken-2 {
    border-top: 1px solid #ff7216!important
}

.border-bottom-warning.border-bottom-darken-2 {
    border-bottom: 1px solid #ff7216!important
}

.border-left-warning.border-left-darken-2 {
    border-left: 1px solid #ff7216!important
}

.border-right-warning.border-right-darken-2 {
    border-right: 1px solid #ff7216!important
}

.overlay-warning.overlay-darken-2 {
    background: #ff7216;
    background: rgba(255,114,22,.8)
}

.warning.darken-3 {
    color: #fc6300!important
}

.bg-warning.bg-darken-3 {
    background-color: #fc6300!important
}

.bg-warning.bg-darken-3.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(252,99,0,.6)
}

.btn-warning.btn-darken-3,.btn-warning.btn-darken-3:hover {
    border-color: #ff7216!important;
    background-color: #fc6300!important
}

.btn-warning.btn-darken-3:active,.btn-warning.btn-darken-3:focus {
    border-color: #fc6300!important;
    background-color: #e25900!important
}

.btn-outline-warning.btn-outline-darken-3 {
    border-color: #fc6300!important;
    color: #fc6300!important
}

.btn-outline-warning.btn-outline-darken-3:hover {
    background-color: #fc6300!important
}

.border-warning.border-darken-3 {
    border: 1px solid #fc6300!important
}

.border-top-warning.border-top-darken-3 {
    border-top: 1px solid #fc6300!important
}

.border-bottom-warning.border-bottom-darken-3 {
    border-bottom: 1px solid #fc6300!important
}

.border-left-warning.border-left-darken-3 {
    border-left: 1px solid #fc6300!important
}

.border-right-warning.border-right-darken-3 {
    border-right: 1px solid #fc6300!important
}

.overlay-warning.overlay-darken-3 {
    background: #fc6300;
    background: rgba(252,99,0,.8)
}

.bg-warning.bg-darken-4,.btn-warning.btn-darken-4 {
    background-color: #e25900!important
}

.warning.darken-4 {
    color: #e25900!important
}

.bg-warning.bg-darken-4.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(226,89,0,.6)
}

.btn-warning.btn-darken-4 {
    border-color: #ff7216!important
}

.btn-warning.btn-darken-4:hover {
    border-color: #ff7216!important;
    background-color: #fc6300!important
}

.btn-warning.btn-darken-4:active,.btn-warning.btn-darken-4:focus {
    border-color: #fc6300!important;
    background-color: #e25900!important
}

.btn-outline-warning.btn-outline-darken-4 {
    border-color: #e25900!important;
    color: #e25900!important
}

.btn-outline-warning.btn-outline-darken-4:hover {
    background-color: #e25900!important
}

.border-warning.border-darken-4 {
    border: 1px solid #e25900!important
}

.border-top-warning.border-top-darken-4 {
    border-top: 1px solid #e25900!important
}

.border-bottom-warning.border-bottom-darken-4 {
    border-bottom: 1px solid #e25900!important
}

.border-left-warning.border-left-darken-4 {
    border-left: 1px solid #e25900!important
}

.border-right-warning.border-right-darken-4 {
    border-right: 1px solid #e25900!important
}

.overlay-warning.overlay-darken-4 {
    background: #e25900;
    background: rgba(226,89,0,.8)
}

.bg-warning.bg-accent-1,.btn-warning.btn-accent-1 {
    background-color: #fff5ef!important
}

.warning.accent-1 {
    color: #fff5ef!important
}

.bg-warning.bg-accent-1.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(255,245,239,.6)
}

.btn-warning.btn-accent-1 {
    border-color: #ff7216!important
}

.btn-warning.btn-accent-1:hover {
    border-color: #ff7216!important;
    background-color: #fc6300!important
}

.btn-warning.btn-accent-1:active,.btn-warning.btn-accent-1:focus {
    border-color: #fc6300!important;
    background-color: #e25900!important
}

.btn-outline-warning.btn-outline-accent-1 {
    border-color: #fff5ef!important;
    color: #fff5ef!important
}

.btn-outline-warning.btn-outline-accent-1:hover {
    background-color: #fff5ef!important
}

.border-warning.border-accent-1 {
    border: 1px solid #fff5ef!important
}

.border-top-warning.border-top-accent-1 {
    border-top: 1px solid #fff5ef!important
}

.border-bottom-warning.border-bottom-accent-1 {
    border-bottom: 1px solid #fff5ef!important
}

.border-left-warning.border-left-accent-1 {
    border-left: 1px solid #fff5ef!important
}

.border-right-warning.border-right-accent-1 {
    border-right: 1px solid #fff5ef!important
}

.overlay-warning.overlay-accent-1 {
    background: #fff5ef;
    background: rgba(255,245,239,.8)
}

.bg-warning.bg-accent-2,.btn-warning.btn-accent-2 {
    background-color: #ffe5d8!important
}

.warning.accent-2 {
    color: #ffe5d8!important
}

.bg-warning.bg-accent-2.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(255,229,216,.6)
}

.btn-warning.btn-accent-2 {
    border-color: #ff7216!important
}

.btn-warning.btn-accent-2:hover {
    border-color: #ff7216!important;
    background-color: #fc6300!important
}

.btn-warning.btn-accent-2:active,.btn-warning.btn-accent-2:focus {
    border-color: #fc6300!important;
    background-color: #e25900!important
}

.btn-outline-warning.btn-outline-accent-2 {
    border-color: #ffe5d8!important;
    color: #ffe5d8!important
}

.btn-outline-warning.btn-outline-accent-2:hover {
    background-color: #ffe5d8!important
}

.border-warning.border-accent-2 {
    border: 1px solid #ffe5d8!important
}

.border-top-warning.border-top-accent-2 {
    border-top: 1px solid #ffe5d8!important
}

.border-bottom-warning.border-bottom-accent-2 {
    border-bottom: 1px solid #ffe5d8!important
}

.border-left-warning.border-left-accent-2 {
    border-left: 1px solid #ffe5d8!important
}

.border-right-warning.border-right-accent-2 {
    border-right: 1px solid #ffe5d8!important
}

.overlay-warning.overlay-accent-2 {
    background: #ffe5d8;
    background: rgba(255,229,216,.8)
}

.bg-warning.bg-accent-3,.btn-warning.btn-accent-3 {
    background-color: #ffddd0!important
}

.warning.accent-3 {
    color: #ffddd0!important
}

.bg-warning.bg-accent-3.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(255,221,208,.6)
}

.btn-warning.btn-accent-3 {
    border-color: #ff7216!important
}

.btn-warning.btn-accent-3:hover {
    border-color: #ff7216!important;
    background-color: #fc6300!important
}

.btn-warning.btn-accent-3:active,.btn-warning.btn-accent-3:focus {
    border-color: #fc6300!important;
    background-color: #e25900!important
}

.btn-outline-warning.btn-outline-accent-3 {
    border-color: #ffddd0!important;
    color: #ffddd0!important
}

.btn-outline-warning.btn-outline-accent-3:hover {
    background-color: #ffddd0!important
}

.border-warning.border-accent-3 {
    border: 1px solid #ffddd0!important
}

.border-top-warning.border-top-accent-3 {
    border-top: 1px solid #ffddd0!important
}

.border-bottom-warning.border-bottom-accent-3 {
    border-bottom: 1px solid #ffddd0!important
}

.border-left-warning.border-left-accent-3 {
    border-left: 1px solid #ffddd0!important
}

.border-right-warning.border-right-accent-3 {
    border-right: 1px solid #ffddd0!important
}

.overlay-warning.overlay-accent-3 {
    background: #ffddd0;
    background: rgba(255,221,208,.8)
}

.bg-warning.bg-accent-4,.btn-warning.btn-accent-4 {
    background-color: #ffcab7!important
}

.warning.accent-4 {
    color: #ffcab7!important
}

.bg-warning.bg-accent-4.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(255,202,183,.6)
}

.btn-warning.btn-accent-4 {
    border-color: #ff7216!important
}

.btn-warning.btn-accent-4:hover {
    border-color: #ff7216!important;
    background-color: #fc6300!important
}

.btn-warning.btn-accent-4:active,.btn-warning.btn-accent-4:focus {
    border-color: #fc6300!important;
    background-color: #e25900!important
}

.btn-outline-warning.btn-outline-accent-4 {
    border-color: #ffcab7!important;
    color: #ffcab7!important
}

.btn-outline-warning.btn-outline-accent-4:hover {
    background-color: #ffcab7!important
}

input:focus~.bg-warning {
    box-shadow: 0 0 0 .075rem #fff,0 0 0 .21rem #ffcab7!important
}

.border-warning.border-accent-4 {
    border: 1px solid #ffcab7!important
}

.border-top-warning.border-top-accent-4 {
    border-top: 1px solid #ffcab7!important
}

.border-bottom-warning.border-bottom-accent-4 {
    border-bottom: 1px solid #ffcab7!important
}

.border-left-warning.border-left-accent-4 {
    border-left: 1px solid #ffcab7!important
}

.border-right-warning.border-right-accent-4 {
    border-right: 1px solid #ffcab7!important
}

.overlay-warning.overlay-accent-4 {
    background: #ffcab7;
    background: rgba(255,202,183,.8)
}

.bg-danger.bg-lighten-5,.btn-danger.btn-lighten-5 {
    background-color: #ffc9d0!important
}

.danger.lighten-5 {
    color: #ffc9d0!important
}

.bg-danger.bg-lighten-5.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(255,201,208,.6)
}

.btn-danger.btn-lighten-5 {
    border-color: #ff1635!important
}

.btn-danger.btn-lighten-5:hover {
    border-color: #ff1635!important;
    background-color: #fc0021!important
}

.btn-danger.btn-lighten-5:active,.btn-danger.btn-lighten-5:focus {
    border-color: #fc0021!important;
    background-color: #e2001e!important
}

.btn-outline-danger.btn-outline-lighten-5 {
    border-color: #ffc9d0!important;
    color: #ffc9d0!important
}

.btn-outline-danger.btn-outline-lighten-5:hover {
    background-color: #ffc9d0!important
}

.border-danger.border-lighten-5 {
    border: 1px solid #ffc9d0!important
}

.border-top-danger.border-top-lighten-5 {
    border-top: 1px solid #ffc9d0!important
}

.border-bottom-danger.border-bottom-lighten-5 {
    border-bottom: 1px solid #ffc9d0!important
}

.border-left-danger.border-left-lighten-5 {
    border-left: 1px solid #ffc9d0!important
}

.border-right-danger.border-right-lighten-5 {
    border-right: 1px solid #ffc9d0!important
}

.overlay-danger.overlay-lighten-5 {
    background: #ffc9d0;
    background: rgba(255,201,208,.8)
}

.bg-danger.bg-lighten-4,.btn-danger.btn-lighten-4 {
    background-color: #ffafba!important
}

.danger.lighten-4 {
    color: #ffafba!important
}

.bg-danger.bg-lighten-4.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(255,175,186,.6)
}

.btn-danger.btn-lighten-4 {
    border-color: #ff1635!important
}

.btn-danger.btn-lighten-4:hover {
    border-color: #ff1635!important;
    background-color: #fc0021!important
}

.btn-danger.btn-lighten-4:active,.btn-danger.btn-lighten-4:focus {
    border-color: #fc0021!important;
    background-color: #e2001e!important
}

.btn-outline-danger.btn-outline-lighten-4 {
    border-color: #ffafba!important;
    color: #ffafba!important
}

.btn-outline-danger.btn-outline-lighten-4:hover {
    background-color: #ffafba!important
}

.border-danger.border-lighten-4 {
    border: 1px solid #ffafba!important
}

.border-top-danger.border-top-lighten-4 {
    border-top: 1px solid #ffafba!important
}

.border-bottom-danger.border-bottom-lighten-4 {
    border-bottom: 1px solid #ffafba!important
}

.border-left-danger.border-left-lighten-4 {
    border-left: 1px solid #ffafba!important
}

.border-right-danger.border-right-lighten-4 {
    border-right: 1px solid #ffafba!important
}

.overlay-danger.overlay-lighten-4 {
    background: #ffafba;
    background: rgba(255,175,186,.8)
}

.bg-danger.bg-lighten-3,.btn-danger.btn-lighten-3 {
    background-color: #ff96a3!important
}

.danger.lighten-3 {
    color: #ff96a3!important
}

.bg-danger.bg-lighten-3.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(255,150,163,.6)
}

.btn-danger.btn-lighten-3 {
    border-color: #ff1635!important
}

.btn-danger.btn-lighten-3:hover {
    border-color: #ff1635!important;
    background-color: #fc0021!important
}

.btn-danger.btn-lighten-3:active,.btn-danger.btn-lighten-3:focus {
    border-color: #fc0021!important;
    background-color: #e2001e!important
}

.btn-outline-danger.btn-outline-lighten-3 {
    border-color: #ff96a3!important;
    color: #ff96a3!important
}

.btn-outline-danger.btn-outline-lighten-3:hover {
    background-color: #ff96a3!important
}

.border-danger.border-lighten-3 {
    border: 1px solid #ff96a3!important
}

.border-top-danger.border-top-lighten-3 {
    border-top: 1px solid #ff96a3!important
}

.border-bottom-danger.border-bottom-lighten-3 {
    border-bottom: 1px solid #ff96a3!important
}

.border-left-danger.border-left-lighten-3 {
    border-left: 1px solid #ff96a3!important
}

.border-right-danger.border-right-lighten-3 {
    border-right: 1px solid #ff96a3!important
}

.overlay-danger.overlay-lighten-3 {
    background: #ff96a3;
    background: rgba(255,150,163,.8)
}

.bg-danger.bg-lighten-2,.btn-danger.btn-lighten-2 {
    background-color: #ff7c8d!important
}

.danger.lighten-2 {
    color: #ff7c8d!important
}

.bg-danger.bg-lighten-2.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(255,124,141,.6)
}

.btn-danger.btn-lighten-2 {
    border-color: #ff1635!important
}

.btn-danger.btn-lighten-2:hover {
    border-color: #ff1635!important;
    background-color: #fc0021!important
}

.btn-danger.btn-lighten-2:active,.btn-danger.btn-lighten-2:focus {
    border-color: #fc0021!important;
    background-color: #e2001e!important
}

.btn-outline-danger.btn-outline-lighten-2 {
    border-color: #ff7c8d!important;
    color: #ff7c8d!important
}

.btn-outline-danger.btn-outline-lighten-2:hover {
    background-color: #ff7c8d!important
}

.border-danger.border-lighten-2 {
    border: 1px solid #ff7c8d!important
}

.border-top-danger.border-top-lighten-2 {
    border-top: 1px solid #ff7c8d!important
}

.border-bottom-danger.border-bottom-lighten-2 {
    border-bottom: 1px solid #ff7c8d!important
}

.border-left-danger.border-left-lighten-2 {
    border-left: 1px solid #ff7c8d!important
}

.border-right-danger.border-right-lighten-2 {
    border-right: 1px solid #ff7c8d!important
}

.overlay-danger.overlay-lighten-2 {
    background: #ff7c8d;
    background: rgba(255,124,141,.8)
}

.bg-danger.bg-lighten-1,.btn-danger.btn-lighten-1 {
    background-color: #ff6377!important
}

.danger.lighten-1 {
    color: #ff6377!important
}

.bg-danger.bg-lighten-1.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(255,99,119,.6)
}

.bg-danger.bg-glow,.btn-danger.btn-glow,.btn-outline-danger.btn-glow {
    box-shadow: 0 1px 20px 1px rgba(255,73,97,.6)
}

.btn-danger.btn-lighten-1 {
    border-color: #ff1635!important
}

.btn-danger.btn-lighten-1:hover {
    border-color: #ff1635!important;
    background-color: #fc0021!important
}

.btn-danger.btn-lighten-1:active,.btn-danger.btn-lighten-1:focus {
    border-color: #fc0021!important;
    background-color: #e2001e!important
}

.btn-outline-danger.btn-outline-lighten-1 {
    border-color: #ff6377!important;
    color: #ff6377!important
}

.btn-outline-danger.btn-outline-lighten-1:hover {
    background-color: #ff6377!important
}

.border-danger.border-lighten-1 {
    border: 1px solid #ff6377!important
}

.border-top-danger.border-top-lighten-1 {
    border-top: 1px solid #ff6377!important
}

.border-bottom-danger.border-bottom-lighten-1 {
    border-bottom: 1px solid #ff6377!important
}

.border-left-danger.border-left-lighten-1 {
    border-left: 1px solid #ff6377!important
}

.border-right-danger.border-right-lighten-1 {
    border-right: 1px solid #ff6377!important
}

.alert-danger,.btn-danger {
    border-color: #ff4961!important
}

.overlay-danger.overlay-lighten-1 {
    background: #ff6377;
    background: rgba(255,99,119,.8)
}

.danger {
    color: #ff4961!important
}

.bg-danger {
    background-color: #ff4961!important
}

.bg-danger .card-footer,.bg-danger .card-header {
    background-color: transparent
}

.selectboxit.selectboxit-btn.bg-danger {
    background-color: #ff4961!important
}

.alert-danger {
    background-color: #ff909f!important;
    color: #960014!important
}

.alert-danger .alert-link {
    color: #6d000e!important
}

.overlay-danger {
    background: #ff4961;
    background: rgba(255,73,97,.8)
}

.btn-danger,.color-info.danger {
    background-color: #ff4961!important
}

.btn-danger {
    color: #FFF
}

.btn-danger:hover {
    border-color: #ff6377!important;
    background-color: #ff6377!important;
    color: #FFF!important
}

.btn-danger:active,.btn-danger:focus {
    border-color: #fc0021!important;
    background-color: #fc0021!important;
    color: #FFF!important
}

.btn-outline-danger {
    border-color: #ff4961;
    background-color: transparent;
    color: #ff4961
}

.btn-outline-danger:hover {
    background-color: #ff4961;
    color: #FFF!important
}

input[type=checkbox].bg-danger+.custom-control-label:before,input[type=radio].bg-danger+.custom-control-label:before {
    background-color: #ff4961!important
}

.border-danger {
    border: 1px solid #ff4961!important
}

.border-top-danger {
    border-top: 1px solid #ff4961
}

.border-bottom-danger {
    border-bottom: 1px solid #ff4961
}

.border-left-danger {
    border-left: 1px solid #ff4961
}

.border-right-danger {
    border-right: 1px solid #ff4961
}

.btn-danger.btn-darken-1,.btn-danger.btn-darken-1:hover {
    border-color: #ff1635!important
}

.bullet.bullet-danger {
    background-color: #ff4961
}

.bg-danger.bg-darken-1,.btn-danger.btn-darken-1 {
    background-color: #ff304b!important
}

.danger.darken-1 {
    color: #ff304b!important
}

.bg-danger.bg-darken-1.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(255,48,75,.6)
}

.btn-danger.btn-darken-1:hover {
    background-color: #fc0021!important
}

.btn-danger.btn-darken-1:active,.btn-danger.btn-darken-1:focus {
    border-color: #fc0021!important;
    background-color: #e2001e!important
}

.btn-outline-danger.btn-outline-darken-1 {
    border-color: #ff304b!important;
    color: #ff304b!important
}

.btn-outline-danger.btn-outline-darken-1:hover {
    background-color: #ff304b!important
}

.border-danger.border-darken-1 {
    border: 1px solid #ff304b!important
}

.border-top-danger.border-top-darken-1 {
    border-top: 1px solid #ff304b!important
}

.border-bottom-danger.border-bottom-darken-1 {
    border-bottom: 1px solid #ff304b!important
}

.border-left-danger.border-left-darken-1 {
    border-left: 1px solid #ff304b!important
}

.border-right-danger.border-right-darken-1 {
    border-right: 1px solid #ff304b!important
}

.btn-danger.btn-darken-2,.btn-danger.btn-darken-2:hover {
    border-color: #ff1635!important
}

.overlay-danger.overlay-darken-1 {
    background: #ff304b;
    background: rgba(255,48,75,.8)
}

.bg-danger.bg-darken-2,.btn-danger.btn-darken-2 {
    background-color: #ff1635!important
}

.danger.darken-2 {
    color: #ff1635!important
}

.bg-danger.bg-darken-2.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(255,22,53,.6)
}

.btn-danger.btn-darken-2:hover {
    background-color: #fc0021!important
}

.btn-danger.btn-darken-2:active,.btn-danger.btn-darken-2:focus {
    border-color: #fc0021!important;
    background-color: #e2001e!important
}

.btn-outline-danger.btn-outline-darken-2 {
    border-color: #ff1635!important;
    color: #ff1635!important
}

.btn-outline-danger.btn-outline-darken-2:hover {
    background-color: #ff1635!important
}

.border-danger.border-darken-2 {
    border: 1px solid #ff1635!important
}

.border-top-danger.border-top-darken-2 {
    border-top: 1px solid #ff1635!important
}

.border-bottom-danger.border-bottom-darken-2 {
    border-bottom: 1px solid #ff1635!important
}

.border-left-danger.border-left-darken-2 {
    border-left: 1px solid #ff1635!important
}

.border-right-danger.border-right-darken-2 {
    border-right: 1px solid #ff1635!important
}

.overlay-danger.overlay-darken-2 {
    background: #ff1635;
    background: rgba(255,22,53,.8)
}

.danger.darken-3 {
    color: #fc0021!important
}

.bg-danger.bg-darken-3 {
    background-color: #fc0021!important
}

.bg-danger.bg-darken-3.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(252,0,33,.6)
}

.btn-danger.btn-darken-3,.btn-danger.btn-darken-3:hover {
    border-color: #ff1635!important;
    background-color: #fc0021!important
}

.btn-danger.btn-darken-3:active,.btn-danger.btn-darken-3:focus {
    border-color: #fc0021!important;
    background-color: #e2001e!important
}

.btn-outline-danger.btn-outline-darken-3 {
    border-color: #fc0021!important;
    color: #fc0021!important
}

.btn-outline-danger.btn-outline-darken-3:hover {
    background-color: #fc0021!important
}

.border-danger.border-darken-3 {
    border: 1px solid #fc0021!important
}

.border-top-danger.border-top-darken-3 {
    border-top: 1px solid #fc0021!important
}

.border-bottom-danger.border-bottom-darken-3 {
    border-bottom: 1px solid #fc0021!important
}

.border-left-danger.border-left-darken-3 {
    border-left: 1px solid #fc0021!important
}

.border-right-danger.border-right-darken-3 {
    border-right: 1px solid #fc0021!important
}

.btn-danger.btn-darken-4,.btn-danger.btn-darken-4:hover {
    border-color: #ff1635!important
}

.overlay-danger.overlay-darken-3 {
    background: #fc0021;
    background: rgba(252,0,33,.8)
}

.bg-danger.bg-darken-4,.btn-danger.btn-darken-4 {
    background-color: #e2001e!important
}

.danger.darken-4 {
    color: #e2001e!important
}

.bg-danger.bg-darken-4.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(226,0,30,.6)
}

.btn-danger.btn-darken-4:hover {
    background-color: #fc0021!important
}

.btn-danger.btn-darken-4:active,.btn-danger.btn-darken-4:focus {
    border-color: #fc0021!important;
    background-color: #e2001e!important
}

.btn-outline-danger.btn-outline-darken-4 {
    border-color: #e2001e!important;
    color: #e2001e!important
}

.btn-outline-danger.btn-outline-darken-4:hover {
    background-color: #e2001e!important
}

.border-danger.border-darken-4 {
    border: 1px solid #e2001e!important
}

.border-top-danger.border-top-darken-4 {
    border-top: 1px solid #e2001e!important
}

.border-bottom-danger.border-bottom-darken-4 {
    border-bottom: 1px solid #e2001e!important
}

.border-left-danger.border-left-darken-4 {
    border-left: 1px solid #e2001e!important
}

.border-right-danger.border-right-darken-4 {
    border-right: 1px solid #e2001e!important
}

.btn-danger.btn-accent-1,.btn-danger.btn-accent-1:hover {
    border-color: #ff1635!important
}

.overlay-danger.overlay-darken-4 {
    background: #e2001e;
    background: rgba(226,0,30,.8)
}

.bg-danger.bg-accent-1,.btn-danger.btn-accent-1 {
    background-color: #ffeef1!important
}

.danger.accent-1 {
    color: #ffeef1!important
}

.bg-danger.bg-accent-1.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(255,238,241,.6)
}

.btn-danger.btn-accent-1:hover {
    background-color: #fc0021!important
}

.btn-danger.btn-accent-1:active,.btn-danger.btn-accent-1:focus {
    border-color: #fc0021!important;
    background-color: #e2001e!important
}

.btn-outline-danger.btn-outline-accent-1 {
    border-color: #ffeef1!important;
    color: #ffeef1!important
}

.btn-outline-danger.btn-outline-accent-1:hover {
    background-color: #ffeef1!important
}

.border-danger.border-accent-1 {
    border: 1px solid #ffeef1!important
}

.border-top-danger.border-top-accent-1 {
    border-top: 1px solid #ffeef1!important
}

.border-bottom-danger.border-bottom-accent-1 {
    border-bottom: 1px solid #ffeef1!important
}

.border-left-danger.border-left-accent-1 {
    border-left: 1px solid #ffeef1!important
}

.border-right-danger.border-right-accent-1 {
    border-right: 1px solid #ffeef1!important
}

.btn-danger.btn-accent-2,.btn-danger.btn-accent-2:hover {
    border-color: #ff1635!important
}

.overlay-danger.overlay-accent-1 {
    background: #ffeef1;
    background: rgba(255,238,241,.8)
}

.bg-danger.bg-accent-2,.btn-danger.btn-accent-2 {
    background-color: #ffd6db!important
}

.danger.accent-2 {
    color: #ffd6db!important
}

.bg-danger.bg-accent-2.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(255,214,219,.6)
}

.btn-danger.btn-accent-2:hover {
    background-color: #fc0021!important
}

.btn-danger.btn-accent-2:active,.btn-danger.btn-accent-2:focus {
    border-color: #fc0021!important;
    background-color: #e2001e!important
}

.btn-outline-danger.btn-outline-accent-2 {
    border-color: #ffd6db!important;
    color: #ffd6db!important
}

.btn-outline-danger.btn-outline-accent-2:hover {
    background-color: #ffd6db!important
}

.border-danger.border-accent-2 {
    border: 1px solid #ffd6db!important
}

.border-top-danger.border-top-accent-2 {
    border-top: 1px solid #ffd6db!important
}

.border-bottom-danger.border-bottom-accent-2 {
    border-bottom: 1px solid #ffd6db!important
}

.border-left-danger.border-left-accent-2 {
    border-left: 1px solid #ffd6db!important
}

.border-right-danger.border-right-accent-2 {
    border-right: 1px solid #ffd6db!important
}

.btn-danger.btn-accent-3,.btn-danger.btn-accent-3:hover {
    border-color: #ff1635!important
}

.overlay-danger.overlay-accent-2 {
    background: #ffd6db;
    background: rgba(255,214,219,.8)
}

.bg-danger.bg-accent-3,.btn-danger.btn-accent-3 {
    background-color: #ffd0d3!important
}

.danger.accent-3 {
    color: #ffd0d3!important
}

.bg-danger.bg-accent-3.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(255,208,211,.6)
}

.btn-danger.btn-accent-3:hover {
    background-color: #fc0021!important
}

.btn-danger.btn-accent-3:active,.btn-danger.btn-accent-3:focus {
    border-color: #fc0021!important;
    background-color: #e2001e!important
}

.btn-outline-danger.btn-outline-accent-3 {
    border-color: #ffd0d3!important;
    color: #ffd0d3!important
}

.btn-outline-danger.btn-outline-accent-3:hover {
    background-color: #ffd0d3!important
}

.border-danger.border-accent-3 {
    border: 1px solid #ffd0d3!important
}

.border-top-danger.border-top-accent-3 {
    border-top: 1px solid #ffd0d3!important
}

.border-bottom-danger.border-bottom-accent-3 {
    border-bottom: 1px solid #ffd0d3!important
}

.border-left-danger.border-left-accent-3 {
    border-left: 1px solid #ffd0d3!important
}

.border-right-danger.border-right-accent-3 {
    border-right: 1px solid #ffd0d3!important
}

.btn-danger.btn-accent-4,.btn-danger.btn-accent-4:hover {
    border-color: #ff1635!important
}

.overlay-danger.overlay-accent-3 {
    background: #ffd0d3;
    background: rgba(255,208,211,.8)
}

.bg-danger.bg-accent-4,.btn-danger.btn-accent-4 {
    background-color: #ffb7bc!important
}

.danger.accent-4 {
    color: #ffb7bc!important
}

.bg-danger.bg-accent-4.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(255,183,188,.6)
}

.btn-danger.btn-accent-4:hover {
    background-color: #fc0021!important
}

.btn-danger.btn-accent-4:active,.btn-danger.btn-accent-4:focus {
    border-color: #fc0021!important;
    background-color: #e2001e!important
}

.btn-outline-danger.btn-outline-accent-4 {
    border-color: #ffb7bc!important;
    color: #ffb7bc!important
}

.btn-outline-danger.btn-outline-accent-4:hover {
    background-color: #ffb7bc!important
}

input:focus~.bg-danger {
    box-shadow: 0 0 0 .075rem #fff,0 0 0 .21rem #ffb7bc!important
}

.bg-light.bg-glow,.btn-light.btn-glow,.btn-outline-light.btn-glow {
    box-shadow: 0 1px 20px 1px rgba(224,224,224,.6)
}

.border-danger.border-accent-4 {
    border: 1px solid #ffb7bc!important
}

.border-top-danger.border-top-accent-4 {
    border-top: 1px solid #ffb7bc!important
}

.border-bottom-danger.border-bottom-accent-4 {
    border-bottom: 1px solid #ffb7bc!important
}

.border-left-danger.border-left-accent-4 {
    border-left: 1px solid #ffb7bc!important
}

.border-right-danger.border-right-accent-4 {
    border-right: 1px solid #ffb7bc!important
}

.alert-light,.btn-light {
    border-color: #e0e0e0!important
}

.overlay-danger.overlay-accent-4 {
    background: #ffb7bc;
    background: rgba(255,183,188,.8)
}

.light {
    color: #e0e0e0!important
}

.bg-light {
    background-color: #e0e0e0!important
}

.bg-light .card-footer,.bg-light .card-header {
    background-color: transparent
}

.selectboxit.selectboxit-btn.bg-light {
    background-color: #e0e0e0!important
}

.alert-light {
    background-color: #fff!important;
    color: #878787!important
}

.alert-light .alert-link {
    color: #727272!important
}

.overlay-light {
    background: #e0e0e0;
    background: rgba(224,224,224,.8)
}

.btn-light,.color-info.light {
    background-color: #e0e0e0!important
}

.btn-light {
    color: #FFF
}

.btn-light:active,.btn-light:focus,.btn-light:hover {
    color: #FFF!important
}

.btn-light:hover {
    border-color: #bdbdbd!important;
    background-color: #bdbdbd!important
}

.btn-outline-light {
    border-color: #e0e0e0;
    background-color: transparent;
    color: #e0e0e0
}

.btn-outline-light:hover {
    background-color: #e0e0e0;
    color: #FFF!important
}

input[type=checkbox].bg-light+.custom-control-label:before,input[type=radio].bg-light+.custom-control-label:before {
    background-color: #e0e0e0!important
}

.border-light {
    border: 1px solid #e0e0e0!important
}

.border-top-light {
    border-top: 1px solid #e0e0e0
}

.border-bottom-light {
    border-bottom: 1px solid #e0e0e0
}

.border-left-light {
    border-left: 1px solid #e0e0e0
}

.border-right-light {
    border-right: 1px solid #e0e0e0
}

.bullet.bullet-light {
    background-color: #e0e0e0
}

.bg-light.bg-lighten-1,.btn-light.btn-lighten-1,.btn-outline-light.btn-outline-lighten-1:hover {
    background-color: #bdbdbd!important
}

.light.lighten-1 {
    color: #bdbdbd!important
}

.bg-light.bg-lighten-1.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(189,189,189,.6)
}

.btn-outline-light.btn-outline-lighten-1 {
    border-color: #bdbdbd!important;
    color: #bdbdbd!important
}

.border-light.border-lighten-1 {
    border: 1px solid #bdbdbd!important
}

.border-top-light.border-top-lighten-1 {
    border-top: 1px solid #bdbdbd!important
}

.border-bottom-light.border-bottom-lighten-1 {
    border-bottom: 1px solid #bdbdbd!important
}

.border-left-light.border-left-lighten-1 {
    border-left: 1px solid #bdbdbd!important
}

.border-right-light.border-right-lighten-1 {
    border-right: 1px solid #bdbdbd!important
}

.overlay-light.overlay-lighten-1 {
    background: #bdbdbd;
    background: rgba(189,189,189,.8)
}

.bg-light.bg-darken-1,.btn-light.btn-darken-1,.btn-outline-light.btn-outline-darken-1:hover {
    background-color: #9e9e9e!important
}

.light.darken-1 {
    color: #9e9e9e!important
}

.bg-light.bg-darken-1.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(158,158,158,.6)
}

.btn-outline-light.btn-outline-darken-1 {
    border-color: #9e9e9e!important;
    color: #9e9e9e!important
}

input:focus~.bg-light {
    box-shadow: 0 0 0 .075rem #fff,0 0 0 .21rem #9e9e9e!important
}

.bg-dark.bg-glow,.btn-dark.btn-glow,.btn-outline-dark.btn-glow {
    box-shadow: 0 1px 20px 1px rgba(66,66,66,.6)
}

.border-light.border-darken-1 {
    border: 1px solid #9e9e9e!important
}

.border-top-light.border-top-darken-1 {
    border-top: 1px solid #9e9e9e!important
}

.border-bottom-light.border-bottom-darken-1 {
    border-bottom: 1px solid #9e9e9e!important
}

.border-left-light.border-left-darken-1 {
    border-left: 1px solid #9e9e9e!important
}

.border-right-light.border-right-darken-1 {
    border-right: 1px solid #9e9e9e!important
}

.alert-dark,.btn-dark {
    border-color: #424242!important
}

.overlay-light.overlay-darken-1 {
    background: #9e9e9e;
    background: rgba(158,158,158,.8)
}

.dark {
    color: #424242!important
}

.alert-dark,.alert-dark .alert-link {
    color: #000!important
}

.bg-dark {
    background-color: #424242!important
}

.bg-dark .card-footer,.bg-dark .card-header {
    background-color: transparent
}

.selectboxit.selectboxit-btn.bg-dark {
    background-color: #424242!important
}

.alert-dark {
    background-color: #666!important
}

.overlay-dark {
    background: #424242;
    background: rgba(66,66,66,.8)
}

.btn-dark,.color-info.dark {
    background-color: #424242!important
}

.btn-dark {
    color: #FFF
}

.btn-dark:active,.btn-dark:focus,.btn-dark:hover {
    color: #FFF!important
}

.btn-dark:hover {
    border-color: #616161!important;
    background-color: #616161!important
}

.btn-outline-dark {
    border-color: #424242;
    background-color: transparent;
    color: #424242
}

.btn-outline-dark:hover {
    background-color: #424242;
    color: #FFF!important
}

input[type=checkbox].bg-dark+.custom-control-label:before,input[type=radio].bg-dark+.custom-control-label:before {
    background-color: #424242!important
}

.border-dark {
    border: 1px solid #424242!important
}

.border-top-dark {
    border-top: 1px solid #424242
}

.border-bottom-dark {
    border-bottom: 1px solid #424242
}

.border-left-dark {
    border-left: 1px solid #424242
}

.border-right-dark {
    border-right: 1px solid #424242
}

.bullet.bullet-dark {
    background-color: #424242
}

.bg-dark.bg-lighten-1,.btn-dark.btn-lighten-1,.btn-outline-dark.btn-outline-lighten-1:hover {
    background-color: #616161!important
}

.dark.lighten-1 {
    color: #616161!important
}

.bg-dark.bg-lighten-1.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(97,97,97,.6)
}

.btn-outline-dark.btn-outline-lighten-1 {
    border-color: #616161!important;
    color: #616161!important
}

.border-dark.border-lighten-1 {
    border: 1px solid #616161!important
}

.border-top-dark.border-top-lighten-1 {
    border-top: 1px solid #616161!important
}

.border-bottom-dark.border-bottom-lighten-1 {
    border-bottom: 1px solid #616161!important
}

.border-left-dark.border-left-lighten-1 {
    border-left: 1px solid #616161!important
}

.border-right-dark.border-right-lighten-1 {
    border-right: 1px solid #616161!important
}

.overlay-dark.overlay-lighten-1 {
    background: #616161;
    background: rgba(97,97,97,.8)
}

.bg-dark.bg-darken-1,.btn-dark.btn-darken-1,.btn-outline-dark.btn-outline-darken-1:hover {
    background-color: #212121!important
}

.dark.darken-1 {
    color: #212121!important
}

.bg-dark.bg-darken-1.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(33,33,33,.6)
}

.btn-outline-dark.btn-outline-darken-1 {
    border-color: #212121!important;
    color: #212121!important
}

input:focus~.bg-dark {
    box-shadow: 0 0 0 .075rem #fff,0 0 0 .21rem #212121!important
}

.border-dark.border-darken-1 {
    border: 1px solid #212121!important
}

.border-top-dark.border-top-darken-1 {
    border-top: 1px solid #212121!important
}

.border-bottom-dark.border-bottom-darken-1 {
    border-bottom: 1px solid #212121!important
}

.border-left-dark.border-left-darken-1 {
    border-left: 1px solid #212121!important
}

.border-right-dark.border-right-darken-1 {
    border-right: 1px solid #212121!important
}

.btn-red.btn-lighten-5,.btn-red.btn-lighten-5:hover {
    border-color: #d32f2f!important
}

.overlay-dark.overlay-darken-1 {
    background: #212121;
    background: rgba(33,33,33,.8)
}

.bg-red.bg-lighten-5,.btn-red.btn-lighten-5 {
    background-color: #ffebee!important
}

.red.lighten-5 {
    color: #ffebee!important
}

.bg-red.bg-lighten-5.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(255,235,238,.6)
}

.btn-red.btn-lighten-5:hover {
    background-color: #c62828!important
}

.btn-red.btn-lighten-5:active,.btn-red.btn-lighten-5:focus {
    border-color: #c62828!important;
    background-color: #b71c1c!important
}

.btn-outline-red.btn-outline-lighten-5 {
    border-color: #ffebee!important;
    color: #ffebee!important
}

.btn-outline-red.btn-outline-lighten-5:hover {
    background-color: #ffebee!important
}

.border-red.border-lighten-5 {
    border: 1px solid #ffebee!important
}

.border-top-red.border-top-lighten-5 {
    border-top: 1px solid #ffebee!important
}

.border-bottom-red.border-bottom-lighten-5 {
    border-bottom: 1px solid #ffebee!important
}

.border-left-red.border-left-lighten-5 {
    border-left: 1px solid #ffebee!important
}

.border-right-red.border-right-lighten-5 {
    border-right: 1px solid #ffebee!important
}

.btn-red.btn-lighten-4,.btn-red.btn-lighten-4:hover {
    border-color: #d32f2f!important
}

.overlay-red.overlay-lighten-5 {
    background: #ffebee;
    background: rgba(255,235,238,.8)
}

.bg-red.bg-lighten-4,.btn-red.btn-lighten-4 {
    background-color: #ffcdd2!important
}

.red.lighten-4 {
    color: #ffcdd2!important
}

.bg-red.bg-lighten-4.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(255,205,210,.6)
}

.btn-red.btn-lighten-4:hover {
    background-color: #c62828!important
}

.btn-red.btn-lighten-4:active,.btn-red.btn-lighten-4:focus {
    border-color: #c62828!important;
    background-color: #b71c1c!important
}

.btn-outline-red.btn-outline-lighten-4 {
    border-color: #ffcdd2!important;
    color: #ffcdd2!important
}

.btn-outline-red.btn-outline-lighten-4:hover {
    background-color: #ffcdd2!important
}

.border-red.border-lighten-4 {
    border: 1px solid #ffcdd2!important
}

.border-top-red.border-top-lighten-4 {
    border-top: 1px solid #ffcdd2!important
}

.border-bottom-red.border-bottom-lighten-4 {
    border-bottom: 1px solid #ffcdd2!important
}

.border-left-red.border-left-lighten-4 {
    border-left: 1px solid #ffcdd2!important
}

.border-right-red.border-right-lighten-4 {
    border-right: 1px solid #ffcdd2!important
}

.btn-red.btn-lighten-3,.btn-red.btn-lighten-3:hover {
    border-color: #d32f2f!important
}

.overlay-red.overlay-lighten-4 {
    background: #ffcdd2;
    background: rgba(255,205,210,.8)
}

.bg-red.bg-lighten-3,.btn-red.btn-lighten-3 {
    background-color: #ef9a9a!important
}

.red.lighten-3 {
    color: #ef9a9a!important
}

.bg-red.bg-lighten-3.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(239,154,154,.6)
}

.btn-red.btn-lighten-3:hover {
    background-color: #c62828!important
}

.btn-red.btn-lighten-3:active,.btn-red.btn-lighten-3:focus {
    border-color: #c62828!important;
    background-color: #b71c1c!important
}

.btn-outline-red.btn-outline-lighten-3 {
    border-color: #ef9a9a!important;
    color: #ef9a9a!important
}

.btn-outline-red.btn-outline-lighten-3:hover {
    background-color: #ef9a9a!important
}

.border-red.border-lighten-3 {
    border: 1px solid #ef9a9a!important
}

.border-top-red.border-top-lighten-3 {
    border-top: 1px solid #ef9a9a!important
}

.border-bottom-red.border-bottom-lighten-3 {
    border-bottom: 1px solid #ef9a9a!important
}

.border-left-red.border-left-lighten-3 {
    border-left: 1px solid #ef9a9a!important
}

.border-right-red.border-right-lighten-3 {
    border-right: 1px solid #ef9a9a!important
}

.btn-red.btn-lighten-2,.btn-red.btn-lighten-2:hover {
    border-color: #d32f2f!important
}

.overlay-red.overlay-lighten-3 {
    background: #ef9a9a;
    background: rgba(239,154,154,.8)
}

.bg-red.bg-lighten-2,.btn-red.btn-lighten-2 {
    background-color: #e57373!important
}

.red.lighten-2 {
    color: #e57373!important
}

.bg-red.bg-lighten-2.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(229,115,115,.6)
}

.btn-red.btn-lighten-2:hover {
    background-color: #c62828!important
}

.btn-red.btn-lighten-2:active,.btn-red.btn-lighten-2:focus {
    border-color: #c62828!important;
    background-color: #b71c1c!important
}

.btn-outline-red.btn-outline-lighten-2 {
    border-color: #e57373!important;
    color: #e57373!important
}

.btn-outline-red.btn-outline-lighten-2:hover {
    background-color: #e57373!important
}

.border-red.border-lighten-2 {
    border: 1px solid #e57373!important
}

.border-top-red.border-top-lighten-2 {
    border-top: 1px solid #e57373!important
}

.border-bottom-red.border-bottom-lighten-2 {
    border-bottom: 1px solid #e57373!important
}

.border-left-red.border-left-lighten-2 {
    border-left: 1px solid #e57373!important
}

.border-right-red.border-right-lighten-2 {
    border-right: 1px solid #e57373!important
}

.btn-red.btn-lighten-1,.btn-red.btn-lighten-1:hover {
    border-color: #d32f2f!important
}

.overlay-red.overlay-lighten-2 {
    background: #e57373;
    background: rgba(229,115,115,.8)
}

.bg-red.bg-lighten-1,.btn-red.btn-lighten-1 {
    background-color: #ef5350!important
}

.red.lighten-1 {
    color: #ef5350!important
}

.bg-red.bg-lighten-1.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(239,83,80,.6)
}

.bg-red.bg-glow,.btn-outline-red.btn-glow,.btn-red.btn-glow {
    box-shadow: 0 1px 20px 1px rgba(244,67,54,.6)
}

.btn-red.btn-lighten-1:hover {
    background-color: #c62828!important
}

.btn-red.btn-lighten-1:active,.btn-red.btn-lighten-1:focus {
    border-color: #c62828!important;
    background-color: #b71c1c!important
}

.btn-outline-red.btn-outline-lighten-1 {
    border-color: #ef5350!important;
    color: #ef5350!important
}

.btn-outline-red.btn-outline-lighten-1:hover {
    background-color: #ef5350!important
}

.border-red.border-lighten-1 {
    border: 1px solid #ef5350!important
}

.border-top-red.border-top-lighten-1 {
    border-top: 1px solid #ef5350!important
}

.border-bottom-red.border-bottom-lighten-1 {
    border-bottom: 1px solid #ef5350!important
}

.border-left-red.border-left-lighten-1 {
    border-left: 1px solid #ef5350!important
}

.border-right-red.border-right-lighten-1 {
    border-right: 1px solid #ef5350!important
}

.alert-red,.btn-red {
    border-color: #f44336!important
}

.overlay-red.overlay-lighten-1 {
    background: #ef5350;
    background: rgba(239,83,80,.8)
}

.red {
    color: #f44336!important
}

.bg-red {
    background-color: #f44336!important
}

.bg-red .card-footer,.bg-red .card-header {
    background-color: transparent
}

.selectboxit.selectboxit-btn.bg-red {
    background-color: #f44336!important
}

.alert-red {
    background-color: #f8827a!important;
    color: #710e06!important
}

.alert-red .alert-link {
    color: #4b0904!important
}

.overlay-red {
    background: #f44336;
    background: rgba(244,67,54,.8)
}

.btn-red,.color-info.red {
    background-color: #f44336!important
}

.btn-red {
    color: #FFF
}

.btn-red:hover {
    border-color: #ef5350!important;
    background-color: #ef5350!important;
    color: #FFF!important
}

.btn-red:active,.btn-red:focus {
    border-color: #c62828!important;
    background-color: #c62828!important;
    color: #FFF!important
}

.btn-outline-red {
    border-color: #f44336;
    background-color: transparent;
    color: #f44336
}

.btn-outline-red:hover {
    background-color: #f44336;
    color: #FFF!important
}

input[type=checkbox].bg-red+.custom-control-label:before,input[type=radio].bg-red+.custom-control-label:before {
    background-color: #f44336!important
}

.border-red {
    border: 1px solid #f44336!important
}

.border-top-red {
    border-top: 1px solid #f44336
}

.border-bottom-red {
    border-bottom: 1px solid #f44336
}

.border-left-red {
    border-left: 1px solid #f44336
}

.border-right-red {
    border-right: 1px solid #f44336
}

.btn-red.btn-darken-1,.btn-red.btn-darken-1:hover {
    border-color: #d32f2f!important
}

.bullet.bullet-red {
    background-color: #f44336
}

.bg-red.bg-darken-1,.btn-red.btn-darken-1 {
    background-color: #e53935!important
}

.red.darken-1 {
    color: #e53935!important
}

.bg-red.bg-darken-1.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(229,57,53,.6)
}

.btn-red.btn-darken-1:hover {
    background-color: #c62828!important
}

.btn-red.btn-darken-1:active,.btn-red.btn-darken-1:focus {
    border-color: #c62828!important;
    background-color: #b71c1c!important
}

.btn-outline-red.btn-outline-darken-1 {
    border-color: #e53935!important;
    color: #e53935!important
}

.btn-outline-red.btn-outline-darken-1:hover {
    background-color: #e53935!important
}

.border-red.border-darken-1 {
    border: 1px solid #e53935!important
}

.border-top-red.border-top-darken-1 {
    border-top: 1px solid #e53935!important
}

.border-bottom-red.border-bottom-darken-1 {
    border-bottom: 1px solid #e53935!important
}

.border-left-red.border-left-darken-1 {
    border-left: 1px solid #e53935!important
}

.border-right-red.border-right-darken-1 {
    border-right: 1px solid #e53935!important
}

.btn-red.btn-darken-2,.btn-red.btn-darken-2:hover {
    border-color: #d32f2f!important
}

.overlay-red.overlay-darken-1 {
    background: #e53935;
    background: rgba(229,57,53,.8)
}

.bg-red.bg-darken-2,.btn-red.btn-darken-2 {
    background-color: #d32f2f!important
}

.red.darken-2 {
    color: #d32f2f!important
}

.bg-red.bg-darken-2.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(211,47,47,.6)
}

.btn-red.btn-darken-2:hover {
    background-color: #c62828!important
}

.btn-red.btn-darken-2:active,.btn-red.btn-darken-2:focus {
    border-color: #c62828!important;
    background-color: #b71c1c!important
}

.btn-outline-red.btn-outline-darken-2 {
    border-color: #d32f2f!important;
    color: #d32f2f!important
}

.btn-outline-red.btn-outline-darken-2:hover {
    background-color: #d32f2f!important
}

.border-red.border-darken-2 {
    border: 1px solid #d32f2f!important
}

.border-top-red.border-top-darken-2 {
    border-top: 1px solid #d32f2f!important
}

.border-bottom-red.border-bottom-darken-2 {
    border-bottom: 1px solid #d32f2f!important
}

.border-left-red.border-left-darken-2 {
    border-left: 1px solid #d32f2f!important
}

.border-right-red.border-right-darken-2 {
    border-right: 1px solid #d32f2f!important
}

.overlay-red.overlay-darken-2 {
    background: #d32f2f;
    background: rgba(211,47,47,.8)
}

.red.darken-3 {
    color: #c62828!important
}

.bg-red.bg-darken-3 {
    background-color: #c62828!important
}

.bg-red.bg-darken-3.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(198,40,40,.6)
}

.btn-red.btn-darken-3,.btn-red.btn-darken-3:hover {
    border-color: #d32f2f!important;
    background-color: #c62828!important
}

.btn-red.btn-darken-3:active,.btn-red.btn-darken-3:focus {
    border-color: #c62828!important;
    background-color: #b71c1c!important
}

.btn-outline-red.btn-outline-darken-3 {
    border-color: #c62828!important;
    color: #c62828!important
}

.btn-outline-red.btn-outline-darken-3:hover {
    background-color: #c62828!important
}

.border-red.border-darken-3 {
    border: 1px solid #c62828!important
}

.border-top-red.border-top-darken-3 {
    border-top: 1px solid #c62828!important
}

.border-bottom-red.border-bottom-darken-3 {
    border-bottom: 1px solid #c62828!important
}

.border-left-red.border-left-darken-3 {
    border-left: 1px solid #c62828!important
}

.border-right-red.border-right-darken-3 {
    border-right: 1px solid #c62828!important
}

.btn-red.btn-darken-4,.btn-red.btn-darken-4:hover {
    border-color: #d32f2f!important
}

.overlay-red.overlay-darken-3 {
    background: #c62828;
    background: rgba(198,40,40,.8)
}

.bg-red.bg-darken-4,.btn-red.btn-darken-4 {
    background-color: #b71c1c!important
}

.red.darken-4 {
    color: #b71c1c!important
}

.bg-red.bg-darken-4.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(183,28,28,.6)
}

.btn-red.btn-darken-4:hover {
    background-color: #c62828!important
}

.btn-red.btn-darken-4:active,.btn-red.btn-darken-4:focus {
    border-color: #c62828!important;
    background-color: #b71c1c!important
}

.btn-outline-red.btn-outline-darken-4 {
    border-color: #b71c1c!important;
    color: #b71c1c!important
}

.btn-outline-red.btn-outline-darken-4:hover {
    background-color: #b71c1c!important
}

.border-red.border-darken-4 {
    border: 1px solid #b71c1c!important
}

.border-top-red.border-top-darken-4 {
    border-top: 1px solid #b71c1c!important
}

.border-bottom-red.border-bottom-darken-4 {
    border-bottom: 1px solid #b71c1c!important
}

.border-left-red.border-left-darken-4 {
    border-left: 1px solid #b71c1c!important
}

.border-right-red.border-right-darken-4 {
    border-right: 1px solid #b71c1c!important
}

.btn-red.btn-accent-1,.btn-red.btn-accent-1:hover {
    border-color: #d32f2f!important
}

.overlay-red.overlay-darken-4 {
    background: #b71c1c;
    background: rgba(183,28,28,.8)
}

.bg-red.bg-accent-1,.btn-red.btn-accent-1 {
    background-color: #ff8a80!important
}

.red.accent-1 {
    color: #ff8a80!important
}

.bg-red.bg-accent-1.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(255,138,128,.6)
}

.btn-red.btn-accent-1:hover {
    background-color: #c62828!important
}

.btn-red.btn-accent-1:active,.btn-red.btn-accent-1:focus {
    border-color: #c62828!important;
    background-color: #b71c1c!important
}

.btn-outline-red.btn-outline-accent-1 {
    border-color: #ff8a80!important;
    color: #ff8a80!important
}

.btn-outline-red.btn-outline-accent-1:hover {
    background-color: #ff8a80!important
}

.border-red.border-accent-1 {
    border: 1px solid #ff8a80!important
}

.border-top-red.border-top-accent-1 {
    border-top: 1px solid #ff8a80!important
}

.border-bottom-red.border-bottom-accent-1 {
    border-bottom: 1px solid #ff8a80!important
}

.border-left-red.border-left-accent-1 {
    border-left: 1px solid #ff8a80!important
}

.border-right-red.border-right-accent-1 {
    border-right: 1px solid #ff8a80!important
}

.btn-red.btn-accent-2,.btn-red.btn-accent-2:hover {
    border-color: #d32f2f!important
}

.overlay-red.overlay-accent-1 {
    background: #ff8a80;
    background: rgba(255,138,128,.8)
}

.bg-red.bg-accent-2,.btn-red.btn-accent-2 {
    background-color: #ff5252!important
}

.red.accent-2 {
    color: #ff5252!important
}

.bg-red.bg-accent-2.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(255,82,82,.6)
}

.btn-red.btn-accent-2:hover {
    background-color: #c62828!important
}

.btn-red.btn-accent-2:active,.btn-red.btn-accent-2:focus {
    border-color: #c62828!important;
    background-color: #b71c1c!important
}

.btn-outline-red.btn-outline-accent-2 {
    border-color: #ff5252!important;
    color: #ff5252!important
}

.btn-outline-red.btn-outline-accent-2:hover {
    background-color: #ff5252!important
}

.border-red.border-accent-2 {
    border: 1px solid #ff5252!important
}

.border-top-red.border-top-accent-2 {
    border-top: 1px solid #ff5252!important
}

.border-bottom-red.border-bottom-accent-2 {
    border-bottom: 1px solid #ff5252!important
}

.border-left-red.border-left-accent-2 {
    border-left: 1px solid #ff5252!important
}

.border-right-red.border-right-accent-2 {
    border-right: 1px solid #ff5252!important
}

.btn-red.btn-accent-3,.btn-red.btn-accent-3:hover {
    border-color: #d32f2f!important
}

.overlay-red.overlay-accent-2 {
    background: #ff5252;
    background: rgba(255,82,82,.8)
}

.bg-red.bg-accent-3,.btn-red.btn-accent-3 {
    background-color: #ff1744!important
}

.red.accent-3 {
    color: #ff1744!important
}

.bg-red.bg-accent-3.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(255,23,68,.6)
}

.btn-red.btn-accent-3:hover {
    background-color: #c62828!important
}

.btn-red.btn-accent-3:active,.btn-red.btn-accent-3:focus {
    border-color: #c62828!important;
    background-color: #b71c1c!important
}

.btn-outline-red.btn-outline-accent-3 {
    border-color: #ff1744!important;
    color: #ff1744!important
}

.btn-outline-red.btn-outline-accent-3:hover {
    background-color: #ff1744!important
}

.border-red.border-accent-3 {
    border: 1px solid #ff1744!important
}

.border-top-red.border-top-accent-3 {
    border-top: 1px solid #ff1744!important
}

.border-bottom-red.border-bottom-accent-3 {
    border-bottom: 1px solid #ff1744!important
}

.border-left-red.border-left-accent-3 {
    border-left: 1px solid #ff1744!important
}

.border-right-red.border-right-accent-3 {
    border-right: 1px solid #ff1744!important
}

.btn-red.btn-accent-4,.btn-red.btn-accent-4:hover {
    border-color: #d32f2f!important
}

.overlay-red.overlay-accent-3 {
    background: #ff1744;
    background: rgba(255,23,68,.8)
}

.bg-red.bg-accent-4,.btn-red.btn-accent-4 {
    background-color: #d50000!important
}

.red.accent-4 {
    color: #d50000!important
}

.bg-red.bg-accent-4.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(213,0,0,.6)
}

.btn-red.btn-accent-4:hover {
    background-color: #c62828!important
}

.btn-red.btn-accent-4:active,.btn-red.btn-accent-4:focus {
    border-color: #c62828!important;
    background-color: #b71c1c!important
}

.btn-outline-red.btn-outline-accent-4 {
    border-color: #d50000!important;
    color: #d50000!important
}

.btn-outline-red.btn-outline-accent-4:hover {
    background-color: #d50000!important
}

input:focus~.bg-red {
    box-shadow: 0 0 0 .075rem #fff,0 0 0 .21rem #d50000!important
}

.border-red.border-accent-4 {
    border: 1px solid #d50000!important
}

.border-top-red.border-top-accent-4 {
    border-top: 1px solid #d50000!important
}

.border-bottom-red.border-bottom-accent-4 {
    border-bottom: 1px solid #d50000!important
}

.border-left-red.border-left-accent-4 {
    border-left: 1px solid #d50000!important
}

.border-right-red.border-right-accent-4 {
    border-right: 1px solid #d50000!important
}

.btn-pink.btn-lighten-5,.btn-pink.btn-lighten-5:hover {
    border-color: #c2185b!important
}

.overlay-red.overlay-accent-4 {
    background: #d50000;
    background: rgba(213,0,0,.8)
}

.bg-pink.bg-lighten-5,.btn-pink.btn-lighten-5 {
    background-color: #fce4ec!important
}

.pink.lighten-5 {
    color: #fce4ec!important
}

.bg-pink.bg-lighten-5.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(252,228,236,.6)
}

.btn-pink.btn-lighten-5:hover {
    background-color: #ad1457!important
}

.btn-pink.btn-lighten-5:active,.btn-pink.btn-lighten-5:focus {
    border-color: #ad1457!important;
    background-color: #880e4f!important
}

.btn-outline-pink.btn-outline-lighten-5 {
    border-color: #fce4ec!important;
    color: #fce4ec!important
}

.btn-outline-pink.btn-outline-lighten-5:hover {
    background-color: #fce4ec!important
}

.border-pink.border-lighten-5 {
    border: 1px solid #fce4ec!important
}

.border-top-pink.border-top-lighten-5 {
    border-top: 1px solid #fce4ec!important
}

.border-bottom-pink.border-bottom-lighten-5 {
    border-bottom: 1px solid #fce4ec!important
}

.border-left-pink.border-left-lighten-5 {
    border-left: 1px solid #fce4ec!important
}

.border-right-pink.border-right-lighten-5 {
    border-right: 1px solid #fce4ec!important
}

.btn-pink.btn-lighten-4,.btn-pink.btn-lighten-4:hover {
    border-color: #c2185b!important
}

.overlay-pink.overlay-lighten-5 {
    background: #fce4ec;
    background: rgba(252,228,236,.8)
}

.bg-pink.bg-lighten-4,.btn-pink.btn-lighten-4 {
    background-color: #f8bbd0!important
}

.pink.lighten-4 {
    color: #f8bbd0!important
}

.bg-pink.bg-lighten-4.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(248,187,208,.6)
}

.btn-pink.btn-lighten-4:hover {
    background-color: #ad1457!important
}

.btn-pink.btn-lighten-4:active,.btn-pink.btn-lighten-4:focus {
    border-color: #ad1457!important;
    background-color: #880e4f!important
}

.btn-outline-pink.btn-outline-lighten-4 {
    border-color: #f8bbd0!important;
    color: #f8bbd0!important
}

.btn-outline-pink.btn-outline-lighten-4:hover {
    background-color: #f8bbd0!important
}

.border-pink.border-lighten-4 {
    border: 1px solid #f8bbd0!important
}

.border-top-pink.border-top-lighten-4 {
    border-top: 1px solid #f8bbd0!important
}

.border-bottom-pink.border-bottom-lighten-4 {
    border-bottom: 1px solid #f8bbd0!important
}

.border-left-pink.border-left-lighten-4 {
    border-left: 1px solid #f8bbd0!important
}

.border-right-pink.border-right-lighten-4 {
    border-right: 1px solid #f8bbd0!important
}

.btn-pink.btn-lighten-3,.btn-pink.btn-lighten-3:hover {
    border-color: #c2185b!important
}

.overlay-pink.overlay-lighten-4 {
    background: #f8bbd0;
    background: rgba(248,187,208,.8)
}

.bg-pink.bg-lighten-3,.btn-pink.btn-lighten-3 {
    background-color: #f48fb1!important
}

.pink.lighten-3 {
    color: #f48fb1!important
}

.bg-pink.bg-lighten-3.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(244,143,177,.6)
}

.btn-pink.btn-lighten-3:hover {
    background-color: #ad1457!important
}

.btn-pink.btn-lighten-3:active,.btn-pink.btn-lighten-3:focus {
    border-color: #ad1457!important;
    background-color: #880e4f!important
}

.btn-outline-pink.btn-outline-lighten-3 {
    border-color: #f48fb1!important;
    color: #f48fb1!important
}

.btn-outline-pink.btn-outline-lighten-3:hover {
    background-color: #f48fb1!important
}

.border-pink.border-lighten-3 {
    border: 1px solid #f48fb1!important
}

.border-top-pink.border-top-lighten-3 {
    border-top: 1px solid #f48fb1!important
}

.border-bottom-pink.border-bottom-lighten-3 {
    border-bottom: 1px solid #f48fb1!important
}

.border-left-pink.border-left-lighten-3 {
    border-left: 1px solid #f48fb1!important
}

.border-right-pink.border-right-lighten-3 {
    border-right: 1px solid #f48fb1!important
}

.btn-pink.btn-lighten-2,.btn-pink.btn-lighten-2:hover {
    border-color: #c2185b!important
}

.overlay-pink.overlay-lighten-3 {
    background: #f48fb1;
    background: rgba(244,143,177,.8)
}

.bg-pink.bg-lighten-2,.btn-pink.btn-lighten-2 {
    background-color: #f06292!important
}

.pink.lighten-2 {
    color: #f06292!important
}

.bg-pink.bg-lighten-2.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(240,98,146,.6)
}

.btn-pink.btn-lighten-2:hover {
    background-color: #ad1457!important
}

.btn-pink.btn-lighten-2:active,.btn-pink.btn-lighten-2:focus {
    border-color: #ad1457!important;
    background-color: #880e4f!important
}

.btn-outline-pink.btn-outline-lighten-2 {
    border-color: #f06292!important;
    color: #f06292!important
}

.btn-outline-pink.btn-outline-lighten-2:hover {
    background-color: #f06292!important
}

.border-pink.border-lighten-2 {
    border: 1px solid #f06292!important
}

.border-top-pink.border-top-lighten-2 {
    border-top: 1px solid #f06292!important
}

.border-bottom-pink.border-bottom-lighten-2 {
    border-bottom: 1px solid #f06292!important
}

.border-left-pink.border-left-lighten-2 {
    border-left: 1px solid #f06292!important
}

.border-right-pink.border-right-lighten-2 {
    border-right: 1px solid #f06292!important
}

.btn-pink.btn-lighten-1,.btn-pink.btn-lighten-1:hover {
    border-color: #c2185b!important
}

.overlay-pink.overlay-lighten-2 {
    background: #f06292;
    background: rgba(240,98,146,.8)
}

.bg-pink.bg-lighten-1,.btn-pink.btn-lighten-1 {
    background-color: #ec407a!important
}

.pink.lighten-1 {
    color: #ec407a!important
}

.bg-pink.bg-lighten-1.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(236,64,122,.6)
}

.bg-pink.bg-glow,.btn-outline-pink.btn-glow,.btn-pink.btn-glow {
    box-shadow: 0 1px 20px 1px rgba(233,30,99,.6)
}

.btn-pink.btn-lighten-1:hover {
    background-color: #ad1457!important
}

.btn-pink.btn-lighten-1:active,.btn-pink.btn-lighten-1:focus {
    border-color: #ad1457!important;
    background-color: #880e4f!important
}

.btn-outline-pink.btn-outline-lighten-1 {
    border-color: #ec407a!important;
    color: #ec407a!important
}

.btn-outline-pink.btn-outline-lighten-1:hover {
    background-color: #ec407a!important
}

.border-pink.border-lighten-1 {
    border: 1px solid #ec407a!important
}

.border-top-pink.border-top-lighten-1 {
    border-top: 1px solid #ec407a!important
}

.border-bottom-pink.border-bottom-lighten-1 {
    border-bottom: 1px solid #ec407a!important
}

.border-left-pink.border-left-lighten-1 {
    border-left: 1px solid #ec407a!important
}

.border-right-pink.border-right-lighten-1 {
    border-right: 1px solid #ec407a!important
}

.alert-pink,.btn-pink {
    border-color: #e91e63!important
}

.overlay-pink.overlay-lighten-1 {
    background: #ec407a;
    background: rgba(236,64,122,.8)
}

.pink {
    color: #e91e63!important
}

.bg-pink {
    background-color: #e91e63!important
}

.bg-pink .card-footer,.bg-pink .card-header {
    background-color: transparent
}

.selectboxit.selectboxit-btn.bg-pink {
    background-color: #e91e63!important
}

.alert-pink {
    background-color: #ef5f90!important;
    color: #4d081f!important
}

.alert-pink .alert-link {
    color: #280410!important
}

.overlay-pink {
    background: #e91e63;
    background: rgba(233,30,99,.8)
}

.btn-pink,.color-info.pink {
    background-color: #e91e63!important
}

.btn-pink {
    color: #FFF
}

.btn-pink:hover {
    border-color: #ec407a!important;
    background-color: #ec407a!important;
    color: #FFF!important
}

.btn-pink:active,.btn-pink:focus {
    border-color: #ad1457!important;
    background-color: #ad1457!important;
    color: #FFF!important
}

.btn-outline-pink {
    border-color: #e91e63;
    background-color: transparent;
    color: #e91e63
}

.btn-outline-pink:hover {
    background-color: #e91e63;
    color: #FFF!important
}

input[type=checkbox].bg-pink+.custom-control-label:before,input[type=radio].bg-pink+.custom-control-label:before {
    background-color: #e91e63!important
}

.border-pink {
    border: 1px solid #e91e63!important
}

.border-top-pink {
    border-top: 1px solid #e91e63
}

.border-bottom-pink {
    border-bottom: 1px solid #e91e63
}

.border-left-pink {
    border-left: 1px solid #e91e63
}

.border-right-pink {
    border-right: 1px solid #e91e63
}

.btn-pink.btn-darken-1,.btn-pink.btn-darken-1:hover {
    border-color: #c2185b!important
}

.bullet.bullet-pink {
    background-color: #e91e63
}

.bg-pink.bg-darken-1,.btn-pink.btn-darken-1 {
    background-color: #d81b60!important
}

.pink.darken-1 {
    color: #d81b60!important
}

.bg-pink.bg-darken-1.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(216,27,96,.6)
}

.btn-pink.btn-darken-1:hover {
    background-color: #ad1457!important
}

.btn-pink.btn-darken-1:active,.btn-pink.btn-darken-1:focus {
    border-color: #ad1457!important;
    background-color: #880e4f!important
}

.btn-outline-pink.btn-outline-darken-1 {
    border-color: #d81b60!important;
    color: #d81b60!important
}

.btn-outline-pink.btn-outline-darken-1:hover {
    background-color: #d81b60!important
}

.border-pink.border-darken-1 {
    border: 1px solid #d81b60!important
}

.border-top-pink.border-top-darken-1 {
    border-top: 1px solid #d81b60!important
}

.border-bottom-pink.border-bottom-darken-1 {
    border-bottom: 1px solid #d81b60!important
}

.border-left-pink.border-left-darken-1 {
    border-left: 1px solid #d81b60!important
}

.border-right-pink.border-right-darken-1 {
    border-right: 1px solid #d81b60!important
}

.btn-pink.btn-darken-2,.btn-pink.btn-darken-2:hover {
    border-color: #c2185b!important
}

.overlay-pink.overlay-darken-1 {
    background: #d81b60;
    background: rgba(216,27,96,.8)
}

.bg-pink.bg-darken-2,.btn-pink.btn-darken-2 {
    background-color: #c2185b!important
}

.pink.darken-2 {
    color: #c2185b!important
}

.bg-pink.bg-darken-2.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(194,24,91,.6)
}

.btn-pink.btn-darken-2:hover {
    background-color: #ad1457!important
}

.btn-pink.btn-darken-2:active,.btn-pink.btn-darken-2:focus {
    border-color: #ad1457!important;
    background-color: #880e4f!important
}

.btn-outline-pink.btn-outline-darken-2 {
    border-color: #c2185b!important;
    color: #c2185b!important
}

.btn-outline-pink.btn-outline-darken-2:hover {
    background-color: #c2185b!important
}

.border-pink.border-darken-2 {
    border: 1px solid #c2185b!important
}

.border-top-pink.border-top-darken-2 {
    border-top: 1px solid #c2185b!important
}

.border-bottom-pink.border-bottom-darken-2 {
    border-bottom: 1px solid #c2185b!important
}

.border-left-pink.border-left-darken-2 {
    border-left: 1px solid #c2185b!important
}

.border-right-pink.border-right-darken-2 {
    border-right: 1px solid #c2185b!important
}

.overlay-pink.overlay-darken-2 {
    background: #c2185b;
    background: rgba(194,24,91,.8)
}

.pink.darken-3 {
    color: #ad1457!important
}

.bg-pink.bg-darken-3 {
    background-color: #ad1457!important
}

.bg-pink.bg-darken-3.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(173,20,87,.6)
}

.btn-pink.btn-darken-3,.btn-pink.btn-darken-3:hover {
    border-color: #c2185b!important;
    background-color: #ad1457!important
}

.btn-pink.btn-darken-3:active,.btn-pink.btn-darken-3:focus {
    border-color: #ad1457!important;
    background-color: #880e4f!important
}

.btn-outline-pink.btn-outline-darken-3 {
    border-color: #ad1457!important;
    color: #ad1457!important
}

.btn-outline-pink.btn-outline-darken-3:hover {
    background-color: #ad1457!important
}

.border-pink.border-darken-3 {
    border: 1px solid #ad1457!important
}

.border-top-pink.border-top-darken-3 {
    border-top: 1px solid #ad1457!important
}

.border-bottom-pink.border-bottom-darken-3 {
    border-bottom: 1px solid #ad1457!important
}

.border-left-pink.border-left-darken-3 {
    border-left: 1px solid #ad1457!important
}

.border-right-pink.border-right-darken-3 {
    border-right: 1px solid #ad1457!important
}

.btn-pink.btn-darken-4,.btn-pink.btn-darken-4:hover {
    border-color: #c2185b!important
}

.overlay-pink.overlay-darken-3 {
    background: #ad1457;
    background: rgba(173,20,87,.8)
}

.bg-pink.bg-darken-4,.btn-pink.btn-darken-4 {
    background-color: #880e4f!important
}

.pink.darken-4 {
    color: #880e4f!important
}

.bg-pink.bg-darken-4.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(136,14,79,.6)
}

.btn-pink.btn-darken-4:hover {
    background-color: #ad1457!important
}

.btn-pink.btn-darken-4:active,.btn-pink.btn-darken-4:focus {
    border-color: #ad1457!important;
    background-color: #880e4f!important
}

.btn-outline-pink.btn-outline-darken-4 {
    border-color: #880e4f!important;
    color: #880e4f!important
}

.btn-outline-pink.btn-outline-darken-4:hover {
    background-color: #880e4f!important
}

.border-pink.border-darken-4 {
    border: 1px solid #880e4f!important
}

.border-top-pink.border-top-darken-4 {
    border-top: 1px solid #880e4f!important
}

.border-bottom-pink.border-bottom-darken-4 {
    border-bottom: 1px solid #880e4f!important
}

.border-left-pink.border-left-darken-4 {
    border-left: 1px solid #880e4f!important
}

.border-right-pink.border-right-darken-4 {
    border-right: 1px solid #880e4f!important
}

.btn-pink.btn-accent-1,.btn-pink.btn-accent-1:hover {
    border-color: #c2185b!important
}

.overlay-pink.overlay-darken-4 {
    background: #880e4f;
    background: rgba(136,14,79,.8)
}

.bg-pink.bg-accent-1,.btn-pink.btn-accent-1 {
    background-color: #ff80ab!important
}

.pink.accent-1 {
    color: #ff80ab!important
}

.bg-pink.bg-accent-1.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(255,128,171,.6)
}

.btn-pink.btn-accent-1:hover {
    background-color: #ad1457!important
}

.btn-pink.btn-accent-1:active,.btn-pink.btn-accent-1:focus {
    border-color: #ad1457!important;
    background-color: #880e4f!important
}

.btn-outline-pink.btn-outline-accent-1 {
    border-color: #ff80ab!important;
    color: #ff80ab!important
}

.btn-outline-pink.btn-outline-accent-1:hover {
    background-color: #ff80ab!important
}

.border-pink.border-accent-1 {
    border: 1px solid #ff80ab!important
}

.border-top-pink.border-top-accent-1 {
    border-top: 1px solid #ff80ab!important
}

.border-bottom-pink.border-bottom-accent-1 {
    border-bottom: 1px solid #ff80ab!important
}

.border-left-pink.border-left-accent-1 {
    border-left: 1px solid #ff80ab!important
}

.border-right-pink.border-right-accent-1 {
    border-right: 1px solid #ff80ab!important
}

.btn-pink.btn-accent-2,.btn-pink.btn-accent-2:hover {
    border-color: #c2185b!important
}

.overlay-pink.overlay-accent-1 {
    background: #ff80ab;
    background: rgba(255,128,171,.8)
}

.bg-pink.bg-accent-2,.btn-pink.btn-accent-2 {
    background-color: #ff4081!important
}

.pink.accent-2 {
    color: #ff4081!important
}

.bg-pink.bg-accent-2.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(255,64,129,.6)
}

.btn-pink.btn-accent-2:hover {
    background-color: #ad1457!important
}

.btn-pink.btn-accent-2:active,.btn-pink.btn-accent-2:focus {
    border-color: #ad1457!important;
    background-color: #880e4f!important
}

.btn-outline-pink.btn-outline-accent-2 {
    border-color: #ff4081!important;
    color: #ff4081!important
}

.btn-outline-pink.btn-outline-accent-2:hover {
    background-color: #ff4081!important
}

.border-pink.border-accent-2 {
    border: 1px solid #ff4081!important
}

.border-top-pink.border-top-accent-2 {
    border-top: 1px solid #ff4081!important
}

.border-bottom-pink.border-bottom-accent-2 {
    border-bottom: 1px solid #ff4081!important
}

.border-left-pink.border-left-accent-2 {
    border-left: 1px solid #ff4081!important
}

.border-right-pink.border-right-accent-2 {
    border-right: 1px solid #ff4081!important
}

.btn-pink.btn-accent-3,.btn-pink.btn-accent-3:hover {
    border-color: #c2185b!important
}

.overlay-pink.overlay-accent-2 {
    background: #ff4081;
    background: rgba(255,64,129,.8)
}

.bg-pink.bg-accent-3,.btn-pink.btn-accent-3 {
    background-color: #f50057!important
}

.pink.accent-3 {
    color: #f50057!important
}

.bg-pink.bg-accent-3.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(245,0,87,.6)
}

.btn-pink.btn-accent-3:hover {
    background-color: #ad1457!important
}

.btn-pink.btn-accent-3:active,.btn-pink.btn-accent-3:focus {
    border-color: #ad1457!important;
    background-color: #880e4f!important
}

.btn-outline-pink.btn-outline-accent-3 {
    border-color: #f50057!important;
    color: #f50057!important
}

.btn-outline-pink.btn-outline-accent-3:hover {
    background-color: #f50057!important
}

.border-pink.border-accent-3 {
    border: 1px solid #f50057!important
}

.border-top-pink.border-top-accent-3 {
    border-top: 1px solid #f50057!important
}

.border-bottom-pink.border-bottom-accent-3 {
    border-bottom: 1px solid #f50057!important
}

.border-left-pink.border-left-accent-3 {
    border-left: 1px solid #f50057!important
}

.border-right-pink.border-right-accent-3 {
    border-right: 1px solid #f50057!important
}

.btn-pink.btn-accent-4,.btn-pink.btn-accent-4:hover {
    border-color: #c2185b!important
}

.overlay-pink.overlay-accent-3 {
    background: #f50057;
    background: rgba(245,0,87,.8)
}

.bg-pink.bg-accent-4,.btn-pink.btn-accent-4 {
    background-color: #c51162!important
}

.pink.accent-4 {
    color: #c51162!important
}

.bg-pink.bg-accent-4.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(197,17,98,.6)
}

.btn-pink.btn-accent-4:hover {
    background-color: #ad1457!important
}

.btn-pink.btn-accent-4:active,.btn-pink.btn-accent-4:focus {
    border-color: #ad1457!important;
    background-color: #880e4f!important
}

.btn-outline-pink.btn-outline-accent-4 {
    border-color: #c51162!important;
    color: #c51162!important
}

.btn-outline-pink.btn-outline-accent-4:hover {
    background-color: #c51162!important
}

input:focus~.bg-pink {
    box-shadow: 0 0 0 .075rem #fff,0 0 0 .21rem #c51162!important
}

.border-pink.border-accent-4 {
    border: 1px solid #c51162!important
}

.border-top-pink.border-top-accent-4 {
    border-top: 1px solid #c51162!important
}

.border-bottom-pink.border-bottom-accent-4 {
    border-bottom: 1px solid #c51162!important
}

.border-left-pink.border-left-accent-4 {
    border-left: 1px solid #c51162!important
}

.border-right-pink.border-right-accent-4 {
    border-right: 1px solid #c51162!important
}

.overlay-pink.overlay-accent-4 {
    background: #c51162;
    background: rgba(197,17,98,.8)
}

.bg-purple.bg-lighten-5,.btn-purple.btn-lighten-5 {
    background-color: #f3e5f5!important
}

.purple.lighten-5 {
    color: #f3e5f5!important
}

.bg-purple.bg-lighten-5.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(243,229,245,.6)
}

.btn-purple.btn-lighten-5 {
    border-color: #7b1fa2!important
}

.btn-purple.btn-lighten-5:hover {
    border-color: #7b1fa2!important;
    background-color: #6a1b9a!important
}

.btn-purple.btn-lighten-5:active,.btn-purple.btn-lighten-5:focus {
    border-color: #6a1b9a!important;
    background-color: #4a148c!important
}

.btn-outline-purple.btn-outline-lighten-5 {
    border-color: #f3e5f5!important;
    color: #f3e5f5!important
}

.btn-outline-purple.btn-outline-lighten-5:hover {
    background-color: #f3e5f5!important
}

.border-purple.border-lighten-5 {
    border: 1px solid #f3e5f5!important
}

.border-top-purple.border-top-lighten-5 {
    border-top: 1px solid #f3e5f5!important
}

.border-bottom-purple.border-bottom-lighten-5 {
    border-bottom: 1px solid #f3e5f5!important
}

.border-left-purple.border-left-lighten-5 {
    border-left: 1px solid #f3e5f5!important
}

.border-right-purple.border-right-lighten-5 {
    border-right: 1px solid #f3e5f5!important
}

.overlay-purple.overlay-lighten-5 {
    background: #f3e5f5;
    background: rgba(243,229,245,.8)
}

.bg-purple.bg-lighten-4,.btn-purple.btn-lighten-4 {
    background-color: #e1bee7!important
}

.purple.lighten-4 {
    color: #e1bee7!important
}

.bg-purple.bg-lighten-4.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(225,190,231,.6)
}

.btn-purple.btn-lighten-4 {
    border-color: #7b1fa2!important
}

.btn-purple.btn-lighten-4:hover {
    border-color: #7b1fa2!important;
    background-color: #6a1b9a!important
}

.btn-purple.btn-lighten-4:active,.btn-purple.btn-lighten-4:focus {
    border-color: #6a1b9a!important;
    background-color: #4a148c!important
}

.btn-outline-purple.btn-outline-lighten-4 {
    border-color: #e1bee7!important;
    color: #e1bee7!important
}

.btn-outline-purple.btn-outline-lighten-4:hover {
    background-color: #e1bee7!important
}

.border-purple.border-lighten-4 {
    border: 1px solid #e1bee7!important
}

.border-top-purple.border-top-lighten-4 {
    border-top: 1px solid #e1bee7!important
}

.border-bottom-purple.border-bottom-lighten-4 {
    border-bottom: 1px solid #e1bee7!important
}

.border-left-purple.border-left-lighten-4 {
    border-left: 1px solid #e1bee7!important
}

.border-right-purple.border-right-lighten-4 {
    border-right: 1px solid #e1bee7!important
}

.overlay-purple.overlay-lighten-4 {
    background: #e1bee7;
    background: rgba(225,190,231,.8)
}

.bg-purple.bg-lighten-3,.btn-purple.btn-lighten-3 {
    background-color: #ce93d8!important
}

.purple.lighten-3 {
    color: #ce93d8!important
}

.bg-purple.bg-lighten-3.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(206,147,216,.6)
}

.btn-purple.btn-lighten-3 {
    border-color: #7b1fa2!important
}

.btn-purple.btn-lighten-3:hover {
    border-color: #7b1fa2!important;
    background-color: #6a1b9a!important
}

.btn-purple.btn-lighten-3:active,.btn-purple.btn-lighten-3:focus {
    border-color: #6a1b9a!important;
    background-color: #4a148c!important
}

.btn-outline-purple.btn-outline-lighten-3 {
    border-color: #ce93d8!important;
    color: #ce93d8!important
}

.btn-outline-purple.btn-outline-lighten-3:hover {
    background-color: #ce93d8!important
}

.border-purple.border-lighten-3 {
    border: 1px solid #ce93d8!important
}

.border-top-purple.border-top-lighten-3 {
    border-top: 1px solid #ce93d8!important
}

.border-bottom-purple.border-bottom-lighten-3 {
    border-bottom: 1px solid #ce93d8!important
}

.border-left-purple.border-left-lighten-3 {
    border-left: 1px solid #ce93d8!important
}

.border-right-purple.border-right-lighten-3 {
    border-right: 1px solid #ce93d8!important
}

.overlay-purple.overlay-lighten-3 {
    background: #ce93d8;
    background: rgba(206,147,216,.8)
}

.bg-purple.bg-lighten-2,.btn-purple.btn-lighten-2 {
    background-color: #ba68c8!important
}

.purple.lighten-2 {
    color: #ba68c8!important
}

.bg-purple.bg-lighten-2.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(186,104,200,.6)
}

.btn-purple.btn-lighten-2 {
    border-color: #7b1fa2!important
}

.btn-purple.btn-lighten-2:hover {
    border-color: #7b1fa2!important;
    background-color: #6a1b9a!important
}

.btn-purple.btn-lighten-2:active,.btn-purple.btn-lighten-2:focus {
    border-color: #6a1b9a!important;
    background-color: #4a148c!important
}

.btn-outline-purple.btn-outline-lighten-2 {
    border-color: #ba68c8!important;
    color: #ba68c8!important
}

.btn-outline-purple.btn-outline-lighten-2:hover {
    background-color: #ba68c8!important
}

.border-purple.border-lighten-2 {
    border: 1px solid #ba68c8!important
}

.border-top-purple.border-top-lighten-2 {
    border-top: 1px solid #ba68c8!important
}

.border-bottom-purple.border-bottom-lighten-2 {
    border-bottom: 1px solid #ba68c8!important
}

.border-left-purple.border-left-lighten-2 {
    border-left: 1px solid #ba68c8!important
}

.border-right-purple.border-right-lighten-2 {
    border-right: 1px solid #ba68c8!important
}

.overlay-purple.overlay-lighten-2 {
    background: #ba68c8;
    background: rgba(186,104,200,.8)
}

.bg-purple.bg-lighten-1,.btn-purple.btn-lighten-1 {
    background-color: #ab47bc!important
}

.purple.lighten-1 {
    color: #ab47bc!important
}

.bg-purple.bg-lighten-1.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(171,71,188,.6)
}

.bg-purple.bg-glow,.btn-outline-purple.btn-glow,.btn-purple.btn-glow {
    box-shadow: 0 1px 20px 1px rgba(156,39,176,.6)
}

.btn-purple.btn-lighten-1 {
    border-color: #7b1fa2!important
}

.btn-purple.btn-lighten-1:hover {
    border-color: #7b1fa2!important;
    background-color: #6a1b9a!important
}

.btn-purple.btn-lighten-1:active,.btn-purple.btn-lighten-1:focus {
    border-color: #6a1b9a!important;
    background-color: #4a148c!important
}

.btn-outline-purple.btn-outline-lighten-1 {
    border-color: #ab47bc!important;
    color: #ab47bc!important
}

.btn-outline-purple.btn-outline-lighten-1:hover {
    background-color: #ab47bc!important
}

.border-purple.border-lighten-1 {
    border: 1px solid #ab47bc!important
}

.border-top-purple.border-top-lighten-1 {
    border-top: 1px solid #ab47bc!important
}

.border-bottom-purple.border-bottom-lighten-1 {
    border-bottom: 1px solid #ab47bc!important
}

.border-left-purple.border-left-lighten-1 {
    border-left: 1px solid #ab47bc!important
}

.border-right-purple.border-right-lighten-1 {
    border-right: 1px solid #ab47bc!important
}

.alert-purple,.btn-purple {
    border-color: #9c27b0!important
}

.overlay-purple.overlay-lighten-1 {
    background: #ab47bc;
    background: rgba(171,71,188,.8)
}

.purple {
    color: #9c27b0!important
}

.bg-purple {
    background-color: #9c27b0!important
}

.bg-purple .card-footer,.bg-purple .card-header {
    background-color: transparent
}

.selectboxit.selectboxit-btn.bg-purple {
    background-color: #9c27b0!important
}

.alert-purple {
    background-color: #c248d6!important;
    color: #1a071e!important
}

.alert-purple .alert-link {
    color: #000!important
}

.overlay-purple {
    background: #9c27b0;
    background: rgba(156,39,176,.8)
}

.btn-purple,.color-info.purple {
    background-color: #9c27b0!important
}

.btn-purple {
    color: #FFF
}

.btn-purple:hover {
    border-color: #ab47bc!important;
    background-color: #ab47bc!important;
    color: #FFF!important
}

.btn-purple:active,.btn-purple:focus {
    border-color: #6a1b9a!important;
    background-color: #6a1b9a!important;
    color: #FFF!important
}

.btn-outline-purple {
    border-color: #9c27b0;
    background-color: transparent;
    color: #9c27b0
}

.btn-outline-purple:hover {
    background-color: #9c27b0;
    color: #FFF!important
}

input[type=checkbox].bg-purple+.custom-control-label:before,input[type=radio].bg-purple+.custom-control-label:before {
    background-color: #9c27b0!important
}

.border-purple {
    border: 1px solid #9c27b0!important
}

.border-top-purple {
    border-top: 1px solid #9c27b0
}

.border-bottom-purple {
    border-bottom: 1px solid #9c27b0
}

.border-left-purple {
    border-left: 1px solid #9c27b0
}

.border-right-purple {
    border-right: 1px solid #9c27b0
}

.btn-purple.btn-darken-1,.btn-purple.btn-darken-1:hover {
    border-color: #7b1fa2!important
}

.bullet.bullet-purple {
    background-color: #9c27b0
}

.bg-purple.bg-darken-1,.btn-purple.btn-darken-1 {
    background-color: #8e24aa!important
}

.purple.darken-1 {
    color: #8e24aa!important
}

.bg-purple.bg-darken-1.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(142,36,170,.6)
}

.btn-purple.btn-darken-1:hover {
    background-color: #6a1b9a!important
}

.btn-purple.btn-darken-1:active,.btn-purple.btn-darken-1:focus {
    border-color: #6a1b9a!important;
    background-color: #4a148c!important
}

.btn-outline-purple.btn-outline-darken-1 {
    border-color: #8e24aa!important;
    color: #8e24aa!important
}

.btn-outline-purple.btn-outline-darken-1:hover {
    background-color: #8e24aa!important
}

.border-purple.border-darken-1 {
    border: 1px solid #8e24aa!important
}

.border-top-purple.border-top-darken-1 {
    border-top: 1px solid #8e24aa!important
}

.border-bottom-purple.border-bottom-darken-1 {
    border-bottom: 1px solid #8e24aa!important
}

.border-left-purple.border-left-darken-1 {
    border-left: 1px solid #8e24aa!important
}

.border-right-purple.border-right-darken-1 {
    border-right: 1px solid #8e24aa!important
}

.btn-purple.btn-darken-2,.btn-purple.btn-darken-2:hover {
    border-color: #7b1fa2!important
}

.overlay-purple.overlay-darken-1 {
    background: #8e24aa;
    background: rgba(142,36,170,.8)
}

.bg-purple.bg-darken-2,.btn-purple.btn-darken-2 {
    background-color: #7b1fa2!important
}

.purple.darken-2 {
    color: #7b1fa2!important
}

.bg-purple.bg-darken-2.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(123,31,162,.6)
}

.btn-purple.btn-darken-2:hover {
    background-color: #6a1b9a!important
}

.btn-purple.btn-darken-2:active,.btn-purple.btn-darken-2:focus {
    border-color: #6a1b9a!important;
    background-color: #4a148c!important
}

.btn-outline-purple.btn-outline-darken-2 {
    border-color: #7b1fa2!important;
    color: #7b1fa2!important
}

.btn-outline-purple.btn-outline-darken-2:hover {
    background-color: #7b1fa2!important
}

.border-purple.border-darken-2 {
    border: 1px solid #7b1fa2!important
}

.border-top-purple.border-top-darken-2 {
    border-top: 1px solid #7b1fa2!important
}

.border-bottom-purple.border-bottom-darken-2 {
    border-bottom: 1px solid #7b1fa2!important
}

.border-left-purple.border-left-darken-2 {
    border-left: 1px solid #7b1fa2!important
}

.border-right-purple.border-right-darken-2 {
    border-right: 1px solid #7b1fa2!important
}

.overlay-purple.overlay-darken-2 {
    background: #7b1fa2;
    background: rgba(123,31,162,.8)
}

.purple.darken-3 {
    color: #6a1b9a!important
}

.bg-purple.bg-darken-3 {
    background-color: #6a1b9a!important
}

.bg-purple.bg-darken-3.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(106,27,154,.6)
}

.btn-purple.btn-darken-3,.btn-purple.btn-darken-3:hover {
    border-color: #7b1fa2!important;
    background-color: #6a1b9a!important
}

.btn-purple.btn-darken-3:active,.btn-purple.btn-darken-3:focus {
    border-color: #6a1b9a!important;
    background-color: #4a148c!important
}

.btn-outline-purple.btn-outline-darken-3 {
    border-color: #6a1b9a!important;
    color: #6a1b9a!important
}

.btn-outline-purple.btn-outline-darken-3:hover {
    background-color: #6a1b9a!important
}

.border-purple.border-darken-3 {
    border: 1px solid #6a1b9a!important
}

.border-top-purple.border-top-darken-3 {
    border-top: 1px solid #6a1b9a!important
}

.border-bottom-purple.border-bottom-darken-3 {
    border-bottom: 1px solid #6a1b9a!important
}

.border-left-purple.border-left-darken-3 {
    border-left: 1px solid #6a1b9a!important
}

.border-right-purple.border-right-darken-3 {
    border-right: 1px solid #6a1b9a!important
}

.btn-purple.btn-darken-4,.btn-purple.btn-darken-4:hover {
    border-color: #7b1fa2!important
}

.overlay-purple.overlay-darken-3 {
    background: #6a1b9a;
    background: rgba(106,27,154,.8)
}

.bg-purple.bg-darken-4,.btn-purple.btn-darken-4 {
    background-color: #4a148c!important
}

.purple.darken-4 {
    color: #4a148c!important
}

.bg-purple.bg-darken-4.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(74,20,140,.6)
}

.btn-purple.btn-darken-4:hover {
    background-color: #6a1b9a!important
}

.btn-purple.btn-darken-4:active,.btn-purple.btn-darken-4:focus {
    border-color: #6a1b9a!important;
    background-color: #4a148c!important
}

.btn-outline-purple.btn-outline-darken-4 {
    border-color: #4a148c!important;
    color: #4a148c!important
}

.btn-outline-purple.btn-outline-darken-4:hover {
    background-color: #4a148c!important
}

.border-purple.border-darken-4 {
    border: 1px solid #4a148c!important
}

.border-top-purple.border-top-darken-4 {
    border-top: 1px solid #4a148c!important
}

.border-bottom-purple.border-bottom-darken-4 {
    border-bottom: 1px solid #4a148c!important
}

.border-left-purple.border-left-darken-4 {
    border-left: 1px solid #4a148c!important
}

.border-right-purple.border-right-darken-4 {
    border-right: 1px solid #4a148c!important
}

.btn-purple.btn-accent-1,.btn-purple.btn-accent-1:hover {
    border-color: #7b1fa2!important
}

.overlay-purple.overlay-darken-4 {
    background: #4a148c;
    background: rgba(74,20,140,.8)
}

.bg-purple.bg-accent-1,.btn-purple.btn-accent-1 {
    background-color: #ea80fc!important
}

.purple.accent-1 {
    color: #ea80fc!important
}

.bg-purple.bg-accent-1.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(234,128,252,.6)
}

.btn-purple.btn-accent-1:hover {
    background-color: #6a1b9a!important
}

.btn-purple.btn-accent-1:active,.btn-purple.btn-accent-1:focus {
    border-color: #6a1b9a!important;
    background-color: #4a148c!important
}

.btn-outline-purple.btn-outline-accent-1 {
    border-color: #ea80fc!important;
    color: #ea80fc!important
}

.btn-outline-purple.btn-outline-accent-1:hover {
    background-color: #ea80fc!important
}

.border-purple.border-accent-1 {
    border: 1px solid #ea80fc!important
}

.border-top-purple.border-top-accent-1 {
    border-top: 1px solid #ea80fc!important
}

.border-bottom-purple.border-bottom-accent-1 {
    border-bottom: 1px solid #ea80fc!important
}

.border-left-purple.border-left-accent-1 {
    border-left: 1px solid #ea80fc!important
}

.border-right-purple.border-right-accent-1 {
    border-right: 1px solid #ea80fc!important
}

.btn-purple.btn-accent-2,.btn-purple.btn-accent-2:hover {
    border-color: #7b1fa2!important
}

.overlay-purple.overlay-accent-1 {
    background: #ea80fc;
    background: rgba(234,128,252,.8)
}

.bg-purple.bg-accent-2,.btn-purple.btn-accent-2 {
    background-color: #e040fb!important
}

.purple.accent-2 {
    color: #e040fb!important
}

.bg-purple.bg-accent-2.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(224,64,251,.6)
}

.btn-purple.btn-accent-2:hover {
    background-color: #6a1b9a!important
}

.btn-purple.btn-accent-2:active,.btn-purple.btn-accent-2:focus {
    border-color: #6a1b9a!important;
    background-color: #4a148c!important
}

.btn-outline-purple.btn-outline-accent-2 {
    border-color: #e040fb!important;
    color: #e040fb!important
}

.btn-outline-purple.btn-outline-accent-2:hover {
    background-color: #e040fb!important
}

.border-purple.border-accent-2 {
    border: 1px solid #e040fb!important
}

.border-top-purple.border-top-accent-2 {
    border-top: 1px solid #e040fb!important
}

.border-bottom-purple.border-bottom-accent-2 {
    border-bottom: 1px solid #e040fb!important
}

.border-left-purple.border-left-accent-2 {
    border-left: 1px solid #e040fb!important
}

.border-right-purple.border-right-accent-2 {
    border-right: 1px solid #e040fb!important
}

.btn-purple.btn-accent-3,.btn-purple.btn-accent-3:hover {
    border-color: #7b1fa2!important
}

.overlay-purple.overlay-accent-2 {
    background: #e040fb;
    background: rgba(224,64,251,.8)
}

.bg-purple.bg-accent-3,.btn-purple.btn-accent-3 {
    background-color: #d500f9!important
}

.purple.accent-3 {
    color: #d500f9!important
}

.bg-purple.bg-accent-3.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(213,0,249,.6)
}

.btn-purple.btn-accent-3:hover {
    background-color: #6a1b9a!important
}

.btn-purple.btn-accent-3:active,.btn-purple.btn-accent-3:focus {
    border-color: #6a1b9a!important;
    background-color: #4a148c!important
}

.btn-outline-purple.btn-outline-accent-3 {
    border-color: #d500f9!important;
    color: #d500f9!important
}

.btn-outline-purple.btn-outline-accent-3:hover {
    background-color: #d500f9!important
}

.border-purple.border-accent-3 {
    border: 1px solid #d500f9!important
}

.border-top-purple.border-top-accent-3 {
    border-top: 1px solid #d500f9!important
}

.border-bottom-purple.border-bottom-accent-3 {
    border-bottom: 1px solid #d500f9!important
}

.border-left-purple.border-left-accent-3 {
    border-left: 1px solid #d500f9!important
}

.border-right-purple.border-right-accent-3 {
    border-right: 1px solid #d500f9!important
}

.btn-purple.btn-accent-4,.btn-purple.btn-accent-4:hover {
    border-color: #7b1fa2!important
}

.overlay-purple.overlay-accent-3 {
    background: #d500f9;
    background: rgba(213,0,249,.8)
}

.bg-purple.bg-accent-4,.btn-purple.btn-accent-4 {
    background-color: #d0f!important
}

.purple.accent-4 {
    color: #d0f!important
}

.bg-purple.bg-accent-4.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(221,0,255,.6)
}

.btn-purple.btn-accent-4:hover {
    background-color: #6a1b9a!important
}

.btn-purple.btn-accent-4:active,.btn-purple.btn-accent-4:focus {
    border-color: #6a1b9a!important;
    background-color: #4a148c!important
}

.btn-outline-purple.btn-outline-accent-4 {
    border-color: #d0f!important;
    color: #d0f!important
}

.btn-outline-purple.btn-outline-accent-4:hover {
    background-color: #d0f!important
}

input:focus~.bg-purple {
    box-shadow: 0 0 0 .075rem #fff,0 0 0 .21rem #d0f!important
}

.border-purple.border-accent-4 {
    border: 1px solid #d0f!important
}

.border-top-purple.border-top-accent-4 {
    border-top: 1px solid #d0f!important
}

.border-bottom-purple.border-bottom-accent-4 {
    border-bottom: 1px solid #d0f!important
}

.border-left-purple.border-left-accent-4 {
    border-left: 1px solid #d0f!important
}

.border-right-purple.border-right-accent-4 {
    border-right: 1px solid #d0f!important
}

.btn-blue.btn-lighten-5,.btn-blue.btn-lighten-5:hover {
    border-color: #1976d2!important
}

.overlay-purple.overlay-accent-4 {
    background: #d0f;
    background: rgba(221,0,255,.8)
}

.bg-blue.bg-lighten-5,.btn-blue.btn-lighten-5 {
    background-color: #e3f2fd!important
}

.blue.lighten-5 {
    color: #e3f2fd!important
}

.bg-blue.bg-lighten-5.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(227,242,253,.6)
}

.btn-blue.btn-lighten-5:hover {
    background-color: #1565c0!important
}

.btn-blue.btn-lighten-5:active,.btn-blue.btn-lighten-5:focus {
    border-color: #1565c0!important;
    background-color: #0d47a1!important
}

.btn-outline-blue.btn-outline-lighten-5 {
    border-color: #e3f2fd!important;
    color: #e3f2fd!important
}

.btn-outline-blue.btn-outline-lighten-5:hover {
    background-color: #e3f2fd!important
}

.border-blue.border-lighten-5 {
    border: 1px solid #e3f2fd!important
}

.border-top-blue.border-top-lighten-5 {
    border-top: 1px solid #e3f2fd!important
}

.border-bottom-blue.border-bottom-lighten-5 {
    border-bottom: 1px solid #e3f2fd!important
}

.border-left-blue.border-left-lighten-5 {
    border-left: 1px solid #e3f2fd!important
}

.border-right-blue.border-right-lighten-5 {
    border-right: 1px solid #e3f2fd!important
}

.btn-blue.btn-lighten-4,.btn-blue.btn-lighten-4:hover {
    border-color: #1976d2!important
}

.overlay-blue.overlay-lighten-5 {
    background: #e3f2fd;
    background: rgba(227,242,253,.8)
}

.bg-blue.bg-lighten-4,.btn-blue.btn-lighten-4 {
    background-color: #bbdefb!important
}

.blue.lighten-4 {
    color: #bbdefb!important
}

.bg-blue.bg-lighten-4.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(187,222,251,.6)
}

.btn-blue.btn-lighten-4:hover {
    background-color: #1565c0!important
}

.btn-blue.btn-lighten-4:active,.btn-blue.btn-lighten-4:focus {
    border-color: #1565c0!important;
    background-color: #0d47a1!important
}

.btn-outline-blue.btn-outline-lighten-4 {
    border-color: #bbdefb!important;
    color: #bbdefb!important
}

.btn-outline-blue.btn-outline-lighten-4:hover {
    background-color: #bbdefb!important
}

.border-blue.border-lighten-4 {
    border: 1px solid #bbdefb!important
}

.border-top-blue.border-top-lighten-4 {
    border-top: 1px solid #bbdefb!important
}

.border-bottom-blue.border-bottom-lighten-4 {
    border-bottom: 1px solid #bbdefb!important
}

.border-left-blue.border-left-lighten-4 {
    border-left: 1px solid #bbdefb!important
}

.border-right-blue.border-right-lighten-4 {
    border-right: 1px solid #bbdefb!important
}

.btn-blue.btn-lighten-3,.btn-blue.btn-lighten-3:hover {
    border-color: #1976d2!important
}

.overlay-blue.overlay-lighten-4 {
    background: #bbdefb;
    background: rgba(187,222,251,.8)
}

.bg-blue.bg-lighten-3,.btn-blue.btn-lighten-3 {
    background-color: #90caf9!important
}

.blue.lighten-3 {
    color: #90caf9!important
}

.bg-blue.bg-lighten-3.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(144,202,249,.6)
}

.btn-blue.btn-lighten-3:hover {
    background-color: #1565c0!important
}

.btn-blue.btn-lighten-3:active,.btn-blue.btn-lighten-3:focus {
    border-color: #1565c0!important;
    background-color: #0d47a1!important
}

.btn-outline-blue.btn-outline-lighten-3 {
    border-color: #90caf9!important;
    color: #90caf9!important
}

.btn-outline-blue.btn-outline-lighten-3:hover {
    background-color: #90caf9!important
}

.border-blue.border-lighten-3 {
    border: 1px solid #90caf9!important
}

.border-top-blue.border-top-lighten-3 {
    border-top: 1px solid #90caf9!important
}

.border-bottom-blue.border-bottom-lighten-3 {
    border-bottom: 1px solid #90caf9!important
}

.border-left-blue.border-left-lighten-3 {
    border-left: 1px solid #90caf9!important
}

.border-right-blue.border-right-lighten-3 {
    border-right: 1px solid #90caf9!important
}

.btn-blue.btn-lighten-2,.btn-blue.btn-lighten-2:hover {
    border-color: #1976d2!important
}

.overlay-blue.overlay-lighten-3 {
    background: #90caf9;
    background: rgba(144,202,249,.8)
}

.bg-blue.bg-lighten-2,.btn-blue.btn-lighten-2 {
    background-color: #64b5f6!important
}

.blue.lighten-2 {
    color: #64b5f6!important
}

.bg-blue.bg-lighten-2.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(100,181,246,.6)
}

.btn-blue.btn-lighten-2:hover {
    background-color: #1565c0!important
}

.btn-blue.btn-lighten-2:active,.btn-blue.btn-lighten-2:focus {
    border-color: #1565c0!important;
    background-color: #0d47a1!important
}

.btn-outline-blue.btn-outline-lighten-2 {
    border-color: #64b5f6!important;
    color: #64b5f6!important
}

.btn-outline-blue.btn-outline-lighten-2:hover {
    background-color: #64b5f6!important
}

.border-blue.border-lighten-2 {
    border: 1px solid #64b5f6!important
}

.border-top-blue.border-top-lighten-2 {
    border-top: 1px solid #64b5f6!important
}

.border-bottom-blue.border-bottom-lighten-2 {
    border-bottom: 1px solid #64b5f6!important
}

.border-left-blue.border-left-lighten-2 {
    border-left: 1px solid #64b5f6!important
}

.border-right-blue.border-right-lighten-2 {
    border-right: 1px solid #64b5f6!important
}

.btn-blue.btn-lighten-1,.btn-blue.btn-lighten-1:hover {
    border-color: #1976d2!important
}

.overlay-blue.overlay-lighten-2 {
    background: #64b5f6;
    background: rgba(100,181,246,.8)
}

.bg-blue.bg-lighten-1,.btn-blue.btn-lighten-1 {
    background-color: #42a5f5!important
}

.blue.lighten-1 {
    color: #42a5f5!important
}

.bg-blue.bg-lighten-1.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(66,165,245,.6)
}

.bg-blue.bg-glow,.btn-blue.btn-glow,.btn-outline-blue.btn-glow {
    box-shadow: 0 1px 20px 1px rgba(33,150,243,.6)
}

.btn-blue.btn-lighten-1:hover {
    background-color: #1565c0!important
}

.btn-blue.btn-lighten-1:active,.btn-blue.btn-lighten-1:focus {
    border-color: #1565c0!important;
    background-color: #0d47a1!important
}

.btn-outline-blue.btn-outline-lighten-1 {
    border-color: #42a5f5!important;
    color: #42a5f5!important
}

.btn-outline-blue.btn-outline-lighten-1:hover {
    background-color: #42a5f5!important
}

.border-blue.border-lighten-1 {
    border: 1px solid #42a5f5!important
}

.border-top-blue.border-top-lighten-1 {
    border-top: 1px solid #42a5f5!important
}

.border-bottom-blue.border-bottom-lighten-1 {
    border-bottom: 1px solid #42a5f5!important
}

.border-left-blue.border-left-lighten-1 {
    border-left: 1px solid #42a5f5!important
}

.border-right-blue.border-right-lighten-1 {
    border-right: 1px solid #42a5f5!important
}

.alert-blue,.btn-blue {
    border-color: #2196f3!important
}

.overlay-blue.overlay-lighten-1 {
    background: #42a5f5;
    background: rgba(66,165,245,.8)
}

.blue {
    color: #2196f3!important
}

.bg-blue {
    background-color: #2196f3!important
}

.bg-blue .card-footer,.bg-blue .card-header {
    background-color: transparent
}

.selectboxit.selectboxit-btn.bg-blue {
    background-color: #2196f3!important
}

.alert-blue {
    background-color: #65b6f7!important;
    color: #05365d!important
}

.alert-blue .alert-link {
    color: #031f36!important
}

.overlay-blue {
    background: #2196f3;
    background: rgba(33,150,243,.8)
}

.btn-blue,.color-info.blue {
    background-color: #2196f3!important
}

.btn-blue {
    color: #FFF
}

.btn-blue:hover {
    border-color: #42a5f5!important;
    background-color: #42a5f5!important;
    color: #FFF!important
}

.btn-blue:active,.btn-blue:focus {
    border-color: #1565c0!important;
    background-color: #1565c0!important;
    color: #FFF!important
}

.btn-outline-blue {
    border-color: #2196f3;
    background-color: transparent;
    color: #2196f3
}

.btn-outline-blue:hover {
    background-color: #2196f3;
    color: #FFF!important
}

input[type=checkbox].bg-blue+.custom-control-label:before,input[type=radio].bg-blue+.custom-control-label:before {
    background-color: #2196f3!important
}

.border-blue {
    border: 1px solid #2196f3!important
}

.border-top-blue {
    border-top: 1px solid #2196f3
}

.border-bottom-blue {
    border-bottom: 1px solid #2196f3
}

.border-left-blue {
    border-left: 1px solid #2196f3
}

.border-right-blue {
    border-right: 1px solid #2196f3
}

.btn-blue.btn-darken-1,.btn-blue.btn-darken-1:hover {
    border-color: #1976d2!important
}

.bullet.bullet-blue {
    background-color: #2196f3
}

.bg-blue.bg-darken-1,.btn-blue.btn-darken-1 {
    background-color: #1e88e5!important
}

.blue.darken-1 {
    color: #1e88e5!important
}

.bg-blue.bg-darken-1.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(30,136,229,.6)
}

.btn-blue.btn-darken-1:hover {
    background-color: #1565c0!important
}

.btn-blue.btn-darken-1:active,.btn-blue.btn-darken-1:focus {
    border-color: #1565c0!important;
    background-color: #0d47a1!important
}

.btn-outline-blue.btn-outline-darken-1 {
    border-color: #1e88e5!important;
    color: #1e88e5!important
}

.btn-outline-blue.btn-outline-darken-1:hover {
    background-color: #1e88e5!important
}

.border-blue.border-darken-1 {
    border: 1px solid #1e88e5!important
}

.border-top-blue.border-top-darken-1 {
    border-top: 1px solid #1e88e5!important
}

.border-bottom-blue.border-bottom-darken-1 {
    border-bottom: 1px solid #1e88e5!important
}

.border-left-blue.border-left-darken-1 {
    border-left: 1px solid #1e88e5!important
}

.border-right-blue.border-right-darken-1 {
    border-right: 1px solid #1e88e5!important
}

.btn-blue.btn-darken-2,.btn-blue.btn-darken-2:hover {
    border-color: #1976d2!important
}

.overlay-blue.overlay-darken-1 {
    background: #1e88e5;
    background: rgba(30,136,229,.8)
}

.bg-blue.bg-darken-2,.btn-blue.btn-darken-2 {
    background-color: #1976d2!important
}

.blue.darken-2 {
    color: #1976d2!important
}

.bg-blue.bg-darken-2.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(25,118,210,.6)
}

.btn-blue.btn-darken-2:hover {
    background-color: #1565c0!important
}

.btn-blue.btn-darken-2:active,.btn-blue.btn-darken-2:focus {
    border-color: #1565c0!important;
    background-color: #0d47a1!important
}

.btn-outline-blue.btn-outline-darken-2 {
    border-color: #1976d2!important;
    color: #1976d2!important
}

.btn-outline-blue.btn-outline-darken-2:hover {
    background-color: #1976d2!important
}

.border-blue.border-darken-2 {
    border: 1px solid #1976d2!important
}

.border-top-blue.border-top-darken-2 {
    border-top: 1px solid #1976d2!important
}

.border-bottom-blue.border-bottom-darken-2 {
    border-bottom: 1px solid #1976d2!important
}

.border-left-blue.border-left-darken-2 {
    border-left: 1px solid #1976d2!important
}

.border-right-blue.border-right-darken-2 {
    border-right: 1px solid #1976d2!important
}

.overlay-blue.overlay-darken-2 {
    background: #1976d2;
    background: rgba(25,118,210,.8)
}

.blue.darken-3 {
    color: #1565c0!important
}

.bg-blue.bg-darken-3 {
    background-color: #1565c0!important
}

.bg-blue.bg-darken-3.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(21,101,192,.6)
}

.btn-blue.btn-darken-3,.btn-blue.btn-darken-3:hover {
    border-color: #1976d2!important;
    background-color: #1565c0!important
}

.btn-blue.btn-darken-3:active,.btn-blue.btn-darken-3:focus {
    border-color: #1565c0!important;
    background-color: #0d47a1!important
}

.btn-outline-blue.btn-outline-darken-3 {
    border-color: #1565c0!important;
    color: #1565c0!important
}

.btn-outline-blue.btn-outline-darken-3:hover {
    background-color: #1565c0!important
}

.border-blue.border-darken-3 {
    border: 1px solid #1565c0!important
}

.border-top-blue.border-top-darken-3 {
    border-top: 1px solid #1565c0!important
}

.border-bottom-blue.border-bottom-darken-3 {
    border-bottom: 1px solid #1565c0!important
}

.border-left-blue.border-left-darken-3 {
    border-left: 1px solid #1565c0!important
}

.border-right-blue.border-right-darken-3 {
    border-right: 1px solid #1565c0!important
}

.btn-blue.btn-darken-4,.btn-blue.btn-darken-4:hover {
    border-color: #1976d2!important
}

.overlay-blue.overlay-darken-3 {
    background: #1565c0;
    background: rgba(21,101,192,.8)
}

.bg-blue.bg-darken-4,.btn-blue.btn-darken-4 {
    background-color: #0d47a1!important
}

.blue.darken-4 {
    color: #0d47a1!important
}

.bg-blue.bg-darken-4.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(13,71,161,.6)
}

.btn-blue.btn-darken-4:hover {
    background-color: #1565c0!important
}

.btn-blue.btn-darken-4:active,.btn-blue.btn-darken-4:focus {
    border-color: #1565c0!important;
    background-color: #0d47a1!important
}

.btn-outline-blue.btn-outline-darken-4 {
    border-color: #0d47a1!important;
    color: #0d47a1!important
}

.btn-outline-blue.btn-outline-darken-4:hover {
    background-color: #0d47a1!important
}

.border-blue.border-darken-4 {
    border: 1px solid #0d47a1!important
}

.border-top-blue.border-top-darken-4 {
    border-top: 1px solid #0d47a1!important
}

.border-bottom-blue.border-bottom-darken-4 {
    border-bottom: 1px solid #0d47a1!important
}

.border-left-blue.border-left-darken-4 {
    border-left: 1px solid #0d47a1!important
}

.border-right-blue.border-right-darken-4 {
    border-right: 1px solid #0d47a1!important
}

.btn-blue.btn-accent-1,.btn-blue.btn-accent-1:hover {
    border-color: #1976d2!important
}

.overlay-blue.overlay-darken-4 {
    background: #0d47a1;
    background: rgba(13,71,161,.8)
}

.bg-blue.bg-accent-1,.btn-blue.btn-accent-1 {
    background-color: #82b1ff!important
}

.blue.accent-1 {
    color: #82b1ff!important
}

.bg-blue.bg-accent-1.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(130,177,255,.6)
}

.btn-blue.btn-accent-1:hover {
    background-color: #1565c0!important
}

.btn-blue.btn-accent-1:active,.btn-blue.btn-accent-1:focus {
    border-color: #1565c0!important;
    background-color: #0d47a1!important
}

.btn-outline-blue.btn-outline-accent-1 {
    border-color: #82b1ff!important;
    color: #82b1ff!important
}

.btn-outline-blue.btn-outline-accent-1:hover {
    background-color: #82b1ff!important
}

.border-blue.border-accent-1 {
    border: 1px solid #82b1ff!important
}

.border-top-blue.border-top-accent-1 {
    border-top: 1px solid #82b1ff!important
}

.border-bottom-blue.border-bottom-accent-1 {
    border-bottom: 1px solid #82b1ff!important
}

.border-left-blue.border-left-accent-1 {
    border-left: 1px solid #82b1ff!important
}

.border-right-blue.border-right-accent-1 {
    border-right: 1px solid #82b1ff!important
}

.btn-blue.btn-accent-2,.btn-blue.btn-accent-2:hover {
    border-color: #1976d2!important
}

.overlay-blue.overlay-accent-1 {
    background: #82b1ff;
    background: rgba(130,177,255,.8)
}

.bg-blue.bg-accent-2,.btn-blue.btn-accent-2 {
    background-color: #448aff!important
}

.blue.accent-2 {
    color: #448aff!important
}

.bg-blue.bg-accent-2.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(68,138,255,.6)
}

.btn-blue.btn-accent-2:hover {
    background-color: #1565c0!important
}

.btn-blue.btn-accent-2:active,.btn-blue.btn-accent-2:focus {
    border-color: #1565c0!important;
    background-color: #0d47a1!important
}

.btn-outline-blue.btn-outline-accent-2 {
    border-color: #448aff!important;
    color: #448aff!important
}

.btn-outline-blue.btn-outline-accent-2:hover {
    background-color: #448aff!important
}

.border-blue.border-accent-2 {
    border: 1px solid #448aff!important
}

.border-top-blue.border-top-accent-2 {
    border-top: 1px solid #448aff!important
}

.border-bottom-blue.border-bottom-accent-2 {
    border-bottom: 1px solid #448aff!important
}

.border-left-blue.border-left-accent-2 {
    border-left: 1px solid #448aff!important
}

.border-right-blue.border-right-accent-2 {
    border-right: 1px solid #448aff!important
}

.btn-blue.btn-accent-3,.btn-blue.btn-accent-3:hover {
    border-color: #1976d2!important
}

.overlay-blue.overlay-accent-2 {
    background: #448aff;
    background: rgba(68,138,255,.8)
}

.bg-blue.bg-accent-3,.btn-blue.btn-accent-3 {
    background-color: #2979ff!important
}

.blue.accent-3 {
    color: #2979ff!important
}

.bg-blue.bg-accent-3.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(41,121,255,.6)
}

.btn-blue.btn-accent-3:hover {
    background-color: #1565c0!important
}

.btn-blue.btn-accent-3:active,.btn-blue.btn-accent-3:focus {
    border-color: #1565c0!important;
    background-color: #0d47a1!important
}

.btn-outline-blue.btn-outline-accent-3 {
    border-color: #2979ff!important;
    color: #2979ff!important
}

.btn-outline-blue.btn-outline-accent-3:hover {
    background-color: #2979ff!important
}

.border-blue.border-accent-3 {
    border: 1px solid #2979ff!important
}

.border-top-blue.border-top-accent-3 {
    border-top: 1px solid #2979ff!important
}

.border-bottom-blue.border-bottom-accent-3 {
    border-bottom: 1px solid #2979ff!important
}

.border-left-blue.border-left-accent-3 {
    border-left: 1px solid #2979ff!important
}

.border-right-blue.border-right-accent-3 {
    border-right: 1px solid #2979ff!important
}

.btn-blue.btn-accent-4,.btn-blue.btn-accent-4:hover {
    border-color: #1976d2!important
}

.overlay-blue.overlay-accent-3 {
    background: #2979ff;
    background: rgba(41,121,255,.8)
}

.bg-blue.bg-accent-4,.btn-blue.btn-accent-4 {
    background-color: #2962ff!important
}

.blue.accent-4 {
    color: #2962ff!important
}

.bg-blue.bg-accent-4.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(41,98,255,.6)
}

.btn-blue.btn-accent-4:hover {
    background-color: #1565c0!important
}

.btn-blue.btn-accent-4:active,.btn-blue.btn-accent-4:focus {
    border-color: #1565c0!important;
    background-color: #0d47a1!important
}

.btn-outline-blue.btn-outline-accent-4 {
    border-color: #2962ff!important;
    color: #2962ff!important
}

.btn-outline-blue.btn-outline-accent-4:hover {
    background-color: #2962ff!important
}

input:focus~.bg-blue {
    box-shadow: 0 0 0 .075rem #fff,0 0 0 .21rem #2962ff!important
}

.border-blue.border-accent-4 {
    border: 1px solid #2962ff!important
}

.border-top-blue.border-top-accent-4 {
    border-top: 1px solid #2962ff!important
}

.border-bottom-blue.border-bottom-accent-4 {
    border-bottom: 1px solid #2962ff!important
}

.border-left-blue.border-left-accent-4 {
    border-left: 1px solid #2962ff!important
}

.border-right-blue.border-right-accent-4 {
    border-right: 1px solid #2962ff!important
}

.btn-cyan.btn-lighten-5,.btn-cyan.btn-lighten-5:hover {
    border-color: #0097a7!important
}

.overlay-blue.overlay-accent-4 {
    background: #2962ff;
    background: rgba(41,98,255,.8)
}

.bg-cyan.bg-lighten-5,.btn-cyan.btn-lighten-5 {
    background-color: #e0f7fa!important
}

.cyan.lighten-5 {
    color: #e0f7fa!important
}

.bg-cyan.bg-lighten-5.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(224,247,250,.6)
}

.btn-cyan.btn-lighten-5:hover {
    background-color: #00838f!important
}

.btn-cyan.btn-lighten-5:active,.btn-cyan.btn-lighten-5:focus {
    border-color: #00838f!important;
    background-color: #006064!important
}

.btn-outline-cyan.btn-outline-lighten-5 {
    border-color: #e0f7fa!important;
    color: #e0f7fa!important
}

.btn-outline-cyan.btn-outline-lighten-5:hover {
    background-color: #e0f7fa!important
}

.border-cyan.border-lighten-5 {
    border: 1px solid #e0f7fa!important
}

.border-top-cyan.border-top-lighten-5 {
    border-top: 1px solid #e0f7fa!important
}

.border-bottom-cyan.border-bottom-lighten-5 {
    border-bottom: 1px solid #e0f7fa!important
}

.border-left-cyan.border-left-lighten-5 {
    border-left: 1px solid #e0f7fa!important
}

.border-right-cyan.border-right-lighten-5 {
    border-right: 1px solid #e0f7fa!important
}

.btn-cyan.btn-lighten-4,.btn-cyan.btn-lighten-4:hover {
    border-color: #0097a7!important
}

.overlay-cyan.overlay-lighten-5 {
    background: #e0f7fa;
    background: rgba(224,247,250,.8)
}

.bg-cyan.bg-lighten-4,.btn-cyan.btn-lighten-4 {
    background-color: #b2ebf2!important
}

.cyan.lighten-4 {
    color: #b2ebf2!important
}

.bg-cyan.bg-lighten-4.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(178,235,242,.6)
}

.btn-cyan.btn-lighten-4:hover {
    background-color: #00838f!important
}

.btn-cyan.btn-lighten-4:active,.btn-cyan.btn-lighten-4:focus {
    border-color: #00838f!important;
    background-color: #006064!important
}

.btn-outline-cyan.btn-outline-lighten-4 {
    border-color: #b2ebf2!important;
    color: #b2ebf2!important
}

.btn-outline-cyan.btn-outline-lighten-4:hover {
    background-color: #b2ebf2!important
}

.border-cyan.border-lighten-4 {
    border: 1px solid #b2ebf2!important
}

.border-top-cyan.border-top-lighten-4 {
    border-top: 1px solid #b2ebf2!important
}

.border-bottom-cyan.border-bottom-lighten-4 {
    border-bottom: 1px solid #b2ebf2!important
}

.border-left-cyan.border-left-lighten-4 {
    border-left: 1px solid #b2ebf2!important
}

.border-right-cyan.border-right-lighten-4 {
    border-right: 1px solid #b2ebf2!important
}

.btn-cyan.btn-lighten-3,.btn-cyan.btn-lighten-3:hover {
    border-color: #0097a7!important
}

.overlay-cyan.overlay-lighten-4 {
    background: #b2ebf2;
    background: rgba(178,235,242,.8)
}

.bg-cyan.bg-lighten-3,.btn-cyan.btn-lighten-3 {
    background-color: #80deea!important
}

.cyan.lighten-3 {
    color: #80deea!important
}

.bg-cyan.bg-lighten-3.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(128,222,234,.6)
}

.btn-cyan.btn-lighten-3:hover {
    background-color: #00838f!important
}

.btn-cyan.btn-lighten-3:active,.btn-cyan.btn-lighten-3:focus {
    border-color: #00838f!important;
    background-color: #006064!important
}

.btn-outline-cyan.btn-outline-lighten-3 {
    border-color: #80deea!important;
    color: #80deea!important
}

.btn-outline-cyan.btn-outline-lighten-3:hover {
    background-color: #80deea!important
}

.border-cyan.border-lighten-3 {
    border: 1px solid #80deea!important
}

.border-top-cyan.border-top-lighten-3 {
    border-top: 1px solid #80deea!important
}

.border-bottom-cyan.border-bottom-lighten-3 {
    border-bottom: 1px solid #80deea!important
}

.border-left-cyan.border-left-lighten-3 {
    border-left: 1px solid #80deea!important
}

.border-right-cyan.border-right-lighten-3 {
    border-right: 1px solid #80deea!important
}

.btn-cyan.btn-lighten-2,.btn-cyan.btn-lighten-2:hover {
    border-color: #0097a7!important
}

.overlay-cyan.overlay-lighten-3 {
    background: #80deea;
    background: rgba(128,222,234,.8)
}

.bg-cyan.bg-lighten-2,.btn-cyan.btn-lighten-2 {
    background-color: #4dd0e1!important
}

.cyan.lighten-2 {
    color: #4dd0e1!important
}

.bg-cyan.bg-lighten-2.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(77,208,225,.6)
}

.btn-cyan.btn-lighten-2:hover {
    background-color: #00838f!important
}

.btn-cyan.btn-lighten-2:active,.btn-cyan.btn-lighten-2:focus {
    border-color: #00838f!important;
    background-color: #006064!important
}

.btn-outline-cyan.btn-outline-lighten-2 {
    border-color: #4dd0e1!important;
    color: #4dd0e1!important
}

.btn-outline-cyan.btn-outline-lighten-2:hover {
    background-color: #4dd0e1!important
}

.border-cyan.border-lighten-2 {
    border: 1px solid #4dd0e1!important
}

.border-top-cyan.border-top-lighten-2 {
    border-top: 1px solid #4dd0e1!important
}

.border-bottom-cyan.border-bottom-lighten-2 {
    border-bottom: 1px solid #4dd0e1!important
}

.border-left-cyan.border-left-lighten-2 {
    border-left: 1px solid #4dd0e1!important
}

.border-right-cyan.border-right-lighten-2 {
    border-right: 1px solid #4dd0e1!important
}

.btn-cyan.btn-lighten-1,.btn-cyan.btn-lighten-1:hover {
    border-color: #0097a7!important
}

.overlay-cyan.overlay-lighten-2 {
    background: #4dd0e1;
    background: rgba(77,208,225,.8)
}

.bg-cyan.bg-lighten-1,.btn-cyan.btn-lighten-1 {
    background-color: #26c6da!important
}

.cyan.lighten-1 {
    color: #26c6da!important
}

.bg-cyan.bg-lighten-1.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(38,198,218,.6)
}

.bg-cyan.bg-glow,.btn-cyan.btn-glow,.btn-outline-cyan.btn-glow {
    box-shadow: 0 1px 20px 1px rgba(0,188,212,.6)
}

.btn-cyan.btn-lighten-1:hover {
    background-color: #00838f!important
}

.btn-cyan.btn-lighten-1:active,.btn-cyan.btn-lighten-1:focus {
    border-color: #00838f!important;
    background-color: #006064!important
}

.btn-outline-cyan.btn-outline-lighten-1 {
    border-color: #26c6da!important;
    color: #26c6da!important
}

.btn-outline-cyan.btn-outline-lighten-1:hover {
    background-color: #26c6da!important
}

.border-cyan.border-lighten-1 {
    border: 1px solid #26c6da!important
}

.border-top-cyan.border-top-lighten-1 {
    border-top: 1px solid #26c6da!important
}

.border-bottom-cyan.border-bottom-lighten-1 {
    border-bottom: 1px solid #26c6da!important
}

.border-left-cyan.border-left-lighten-1 {
    border-left: 1px solid #26c6da!important
}

.border-right-cyan.border-right-lighten-1 {
    border-right: 1px solid #26c6da!important
}

.alert-cyan,.btn-cyan {
    border-color: #00bcd4!important
}

.overlay-cyan.overlay-lighten-1 {
    background: #26c6da;
    background: rgba(38,198,218,.8)
}

.cyan {
    color: #00bcd4!important
}

.bg-cyan {
    background-color: #00bcd4!important
}

.bg-cyan .card-footer,.bg-cyan .card-header {
    background-color: transparent
}

.selectboxit.selectboxit-btn.bg-cyan {
    background-color: #00bcd4!important
}

.alert-cyan {
    background-color: #1ce5ff!important;
    color: #001e22!important
}

.alert-cyan .alert-link {
    color: #000!important
}

.overlay-cyan {
    background: #00bcd4;
    background: rgba(0,188,212,.8)
}

.btn-cyan,.color-info.cyan {
    background-color: #00bcd4!important
}

.btn-cyan {
    color: #FFF
}

.btn-cyan:hover {
    border-color: #26c6da!important;
    background-color: #26c6da!important;
    color: #FFF!important
}

.btn-cyan:active,.btn-cyan:focus {
    border-color: #00838f!important;
    background-color: #00838f!important;
    color: #FFF!important
}

.btn-outline-cyan {
    border-color: #00bcd4;
    background-color: transparent;
    color: #00bcd4
}

.btn-outline-cyan:hover {
    background-color: #00bcd4;
    color: #FFF!important
}

input[type=checkbox].bg-cyan+.custom-control-label:before,input[type=radio].bg-cyan+.custom-control-label:before {
    background-color: #00bcd4!important
}

.border-cyan {
    border: 1px solid #00bcd4!important
}

.border-top-cyan {
    border-top: 1px solid #00bcd4
}

.border-bottom-cyan {
    border-bottom: 1px solid #00bcd4
}

.border-left-cyan {
    border-left: 1px solid #00bcd4
}

.border-right-cyan {
    border-right: 1px solid #00bcd4
}

.btn-cyan.btn-darken-1,.btn-cyan.btn-darken-1:hover {
    border-color: #0097a7!important
}

.bullet.bullet-cyan {
    background-color: #00bcd4
}

.bg-cyan.bg-darken-1,.btn-cyan.btn-darken-1 {
    background-color: #00acc1!important
}

.cyan.darken-1 {
    color: #00acc1!important
}

.bg-cyan.bg-darken-1.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(0,172,193,.6)
}

.btn-cyan.btn-darken-1:hover {
    background-color: #00838f!important
}

.btn-cyan.btn-darken-1:active,.btn-cyan.btn-darken-1:focus {
    border-color: #00838f!important;
    background-color: #006064!important
}

.btn-outline-cyan.btn-outline-darken-1 {
    border-color: #00acc1!important;
    color: #00acc1!important
}

.btn-outline-cyan.btn-outline-darken-1:hover {
    background-color: #00acc1!important
}

.border-cyan.border-darken-1 {
    border: 1px solid #00acc1!important
}

.border-top-cyan.border-top-darken-1 {
    border-top: 1px solid #00acc1!important
}

.border-bottom-cyan.border-bottom-darken-1 {
    border-bottom: 1px solid #00acc1!important
}

.border-left-cyan.border-left-darken-1 {
    border-left: 1px solid #00acc1!important
}

.border-right-cyan.border-right-darken-1 {
    border-right: 1px solid #00acc1!important
}

.btn-cyan.btn-darken-2,.btn-cyan.btn-darken-2:hover {
    border-color: #0097a7!important
}

.overlay-cyan.overlay-darken-1 {
    background: #00acc1;
    background: rgba(0,172,193,.8)
}

.bg-cyan.bg-darken-2,.btn-cyan.btn-darken-2 {
    background-color: #0097a7!important
}

.cyan.darken-2 {
    color: #0097a7!important
}

.bg-cyan.bg-darken-2.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(0,151,167,.6)
}

.btn-cyan.btn-darken-2:hover {
    background-color: #00838f!important
}

.btn-cyan.btn-darken-2:active,.btn-cyan.btn-darken-2:focus {
    border-color: #00838f!important;
    background-color: #006064!important
}

.btn-outline-cyan.btn-outline-darken-2 {
    border-color: #0097a7!important;
    color: #0097a7!important
}

.btn-outline-cyan.btn-outline-darken-2:hover {
    background-color: #0097a7!important
}

.border-cyan.border-darken-2 {
    border: 1px solid #0097a7!important
}

.border-top-cyan.border-top-darken-2 {
    border-top: 1px solid #0097a7!important
}

.border-bottom-cyan.border-bottom-darken-2 {
    border-bottom: 1px solid #0097a7!important
}

.border-left-cyan.border-left-darken-2 {
    border-left: 1px solid #0097a7!important
}

.border-right-cyan.border-right-darken-2 {
    border-right: 1px solid #0097a7!important
}

.overlay-cyan.overlay-darken-2 {
    background: #0097a7;
    background: rgba(0,151,167,.8)
}

.cyan.darken-3 {
    color: #00838f!important
}

.bg-cyan.bg-darken-3 {
    background-color: #00838f!important
}

.bg-cyan.bg-darken-3.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(0,131,143,.6)
}

.btn-cyan.btn-darken-3,.btn-cyan.btn-darken-3:hover {
    border-color: #0097a7!important;
    background-color: #00838f!important
}

.btn-cyan.btn-darken-3:active,.btn-cyan.btn-darken-3:focus {
    border-color: #00838f!important;
    background-color: #006064!important
}

.btn-outline-cyan.btn-outline-darken-3 {
    border-color: #00838f!important;
    color: #00838f!important
}

.btn-outline-cyan.btn-outline-darken-3:hover {
    background-color: #00838f!important
}

.border-cyan.border-darken-3 {
    border: 1px solid #00838f!important
}

.border-top-cyan.border-top-darken-3 {
    border-top: 1px solid #00838f!important
}

.border-bottom-cyan.border-bottom-darken-3 {
    border-bottom: 1px solid #00838f!important
}

.border-left-cyan.border-left-darken-3 {
    border-left: 1px solid #00838f!important
}

.border-right-cyan.border-right-darken-3 {
    border-right: 1px solid #00838f!important
}

.btn-cyan.btn-darken-4,.btn-cyan.btn-darken-4:hover {
    border-color: #0097a7!important
}

.overlay-cyan.overlay-darken-3 {
    background: #00838f;
    background: rgba(0,131,143,.8)
}

.bg-cyan.bg-darken-4,.btn-cyan.btn-darken-4 {
    background-color: #006064!important
}

.cyan.darken-4 {
    color: #006064!important
}

.bg-cyan.bg-darken-4.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(0,96,100,.6)
}

.btn-cyan.btn-darken-4:hover {
    background-color: #00838f!important
}

.btn-cyan.btn-darken-4:active,.btn-cyan.btn-darken-4:focus {
    border-color: #00838f!important;
    background-color: #006064!important
}

.btn-outline-cyan.btn-outline-darken-4 {
    border-color: #006064!important;
    color: #006064!important
}

.btn-outline-cyan.btn-outline-darken-4:hover {
    background-color: #006064!important
}

.border-cyan.border-darken-4 {
    border: 1px solid #006064!important
}

.border-top-cyan.border-top-darken-4 {
    border-top: 1px solid #006064!important
}

.border-bottom-cyan.border-bottom-darken-4 {
    border-bottom: 1px solid #006064!important
}

.border-left-cyan.border-left-darken-4 {
    border-left: 1px solid #006064!important
}

.border-right-cyan.border-right-darken-4 {
    border-right: 1px solid #006064!important
}

.btn-cyan.btn-accent-1,.btn-cyan.btn-accent-1:hover {
    border-color: #0097a7!important
}

.overlay-cyan.overlay-darken-4 {
    background: #006064;
    background: rgba(0,96,100,.8)
}

.bg-cyan.bg-accent-1,.btn-cyan.btn-accent-1 {
    background-color: #84ffff!important
}

.cyan.accent-1 {
    color: #84ffff!important
}

.bg-cyan.bg-accent-1.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(132,255,255,.6)
}

.btn-cyan.btn-accent-1:hover {
    background-color: #00838f!important
}

.btn-cyan.btn-accent-1:active,.btn-cyan.btn-accent-1:focus {
    border-color: #00838f!important;
    background-color: #006064!important
}

.btn-outline-cyan.btn-outline-accent-1 {
    border-color: #84ffff!important;
    color: #84ffff!important
}

.btn-outline-cyan.btn-outline-accent-1:hover {
    background-color: #84ffff!important
}

.border-cyan.border-accent-1 {
    border: 1px solid #84ffff!important
}

.border-top-cyan.border-top-accent-1 {
    border-top: 1px solid #84ffff!important
}

.border-bottom-cyan.border-bottom-accent-1 {
    border-bottom: 1px solid #84ffff!important
}

.border-left-cyan.border-left-accent-1 {
    border-left: 1px solid #84ffff!important
}

.border-right-cyan.border-right-accent-1 {
    border-right: 1px solid #84ffff!important
}

.btn-cyan.btn-accent-2,.btn-cyan.btn-accent-2:hover {
    border-color: #0097a7!important
}

.overlay-cyan.overlay-accent-1 {
    background: #84ffff;
    background: rgba(132,255,255,.8)
}

.bg-cyan.bg-accent-2,.btn-cyan.btn-accent-2 {
    background-color: #18ffff!important
}

.cyan.accent-2 {
    color: #18ffff!important
}

.bg-cyan.bg-accent-2.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(24,255,255,.6)
}

.btn-cyan.btn-accent-2:hover {
    background-color: #00838f!important
}

.btn-cyan.btn-accent-2:active,.btn-cyan.btn-accent-2:focus {
    border-color: #00838f!important;
    background-color: #006064!important
}

.btn-outline-cyan.btn-outline-accent-2 {
    border-color: #18ffff!important;
    color: #18ffff!important
}

.btn-outline-cyan.btn-outline-accent-2:hover {
    background-color: #18ffff!important
}

.border-cyan.border-accent-2 {
    border: 1px solid #18ffff!important
}

.border-top-cyan.border-top-accent-2 {
    border-top: 1px solid #18ffff!important
}

.border-bottom-cyan.border-bottom-accent-2 {
    border-bottom: 1px solid #18ffff!important
}

.border-left-cyan.border-left-accent-2 {
    border-left: 1px solid #18ffff!important
}

.border-right-cyan.border-right-accent-2 {
    border-right: 1px solid #18ffff!important
}

.btn-cyan.btn-accent-3,.btn-cyan.btn-accent-3:hover {
    border-color: #0097a7!important
}

.overlay-cyan.overlay-accent-2 {
    background: #18ffff;
    background: rgba(24,255,255,.8)
}

.bg-cyan.bg-accent-3,.btn-cyan.btn-accent-3 {
    background-color: #00e5ff!important
}

.cyan.accent-3 {
    color: #00e5ff!important
}

.bg-cyan.bg-accent-3.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(0,229,255,.6)
}

.btn-cyan.btn-accent-3:hover {
    background-color: #00838f!important
}

.btn-cyan.btn-accent-3:active,.btn-cyan.btn-accent-3:focus {
    border-color: #00838f!important;
    background-color: #006064!important
}

.btn-outline-cyan.btn-outline-accent-3 {
    border-color: #00e5ff!important;
    color: #00e5ff!important
}

.btn-outline-cyan.btn-outline-accent-3:hover {
    background-color: #00e5ff!important
}

.border-cyan.border-accent-3 {
    border: 1px solid #00e5ff!important
}

.border-top-cyan.border-top-accent-3 {
    border-top: 1px solid #00e5ff!important
}

.border-bottom-cyan.border-bottom-accent-3 {
    border-bottom: 1px solid #00e5ff!important
}

.border-left-cyan.border-left-accent-3 {
    border-left: 1px solid #00e5ff!important
}

.border-right-cyan.border-right-accent-3 {
    border-right: 1px solid #00e5ff!important
}

.btn-cyan.btn-accent-4,.btn-cyan.btn-accent-4:hover {
    border-color: #0097a7!important
}

.overlay-cyan.overlay-accent-3 {
    background: #00e5ff;
    background: rgba(0,229,255,.8)
}

.bg-cyan.bg-accent-4,.btn-cyan.btn-accent-4 {
    background-color: #00b8d4!important
}

.cyan.accent-4 {
    color: #00b8d4!important
}

.bg-cyan.bg-accent-4.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(0,184,212,.6)
}

.btn-cyan.btn-accent-4:hover {
    background-color: #00838f!important
}

.btn-cyan.btn-accent-4:active,.btn-cyan.btn-accent-4:focus {
    border-color: #00838f!important;
    background-color: #006064!important
}

.btn-outline-cyan.btn-outline-accent-4 {
    border-color: #00b8d4!important;
    color: #00b8d4!important
}

.btn-outline-cyan.btn-outline-accent-4:hover {
    background-color: #00b8d4!important
}

input:focus~.bg-cyan {
    box-shadow: 0 0 0 .075rem #fff,0 0 0 .21rem #00b8d4!important
}

.border-cyan.border-accent-4 {
    border: 1px solid #00b8d4!important
}

.border-top-cyan.border-top-accent-4 {
    border-top: 1px solid #00b8d4!important
}

.border-bottom-cyan.border-bottom-accent-4 {
    border-bottom: 1px solid #00b8d4!important
}

.border-left-cyan.border-left-accent-4 {
    border-left: 1px solid #00b8d4!important
}

.border-right-cyan.border-right-accent-4 {
    border-right: 1px solid #00b8d4!important
}

.btn-teal.btn-lighten-5,.btn-teal.btn-lighten-5:hover {
    border-color: #00796b!important
}

.overlay-cyan.overlay-accent-4 {
    background: #00b8d4;
    background: rgba(0,184,212,.8)
}

.bg-teal.bg-lighten-5,.btn-teal.btn-lighten-5 {
    background-color: #e0f2f1!important
}

.teal.lighten-5 {
    color: #e0f2f1!important
}

.bg-teal.bg-lighten-5.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(224,242,241,.6)
}

.btn-teal.btn-lighten-5:hover {
    background-color: #00695c!important
}

.btn-teal.btn-lighten-5:active,.btn-teal.btn-lighten-5:focus {
    border-color: #00695c!important;
    background-color: #004d40!important
}

.btn-outline-teal.btn-outline-lighten-5 {
    border-color: #e0f2f1!important;
    color: #e0f2f1!important
}

.btn-outline-teal.btn-outline-lighten-5:hover {
    background-color: #e0f2f1!important
}

.border-teal.border-lighten-5 {
    border: 1px solid #e0f2f1!important
}

.border-top-teal.border-top-lighten-5 {
    border-top: 1px solid #e0f2f1!important
}

.border-bottom-teal.border-bottom-lighten-5 {
    border-bottom: 1px solid #e0f2f1!important
}

.border-left-teal.border-left-lighten-5 {
    border-left: 1px solid #e0f2f1!important
}

.border-right-teal.border-right-lighten-5 {
    border-right: 1px solid #e0f2f1!important
}

.btn-teal.btn-lighten-4,.btn-teal.btn-lighten-4:hover {
    border-color: #00796b!important
}

.overlay-teal.overlay-lighten-5 {
    background: #e0f2f1;
    background: rgba(224,242,241,.8)
}

.bg-teal.bg-lighten-4,.btn-teal.btn-lighten-4 {
    background-color: #b2dfdb!important
}

.teal.lighten-4 {
    color: #b2dfdb!important
}

.bg-teal.bg-lighten-4.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(178,223,219,.6)
}

.btn-teal.btn-lighten-4:hover {
    background-color: #00695c!important
}

.btn-teal.btn-lighten-4:active,.btn-teal.btn-lighten-4:focus {
    border-color: #00695c!important;
    background-color: #004d40!important
}

.btn-outline-teal.btn-outline-lighten-4 {
    border-color: #b2dfdb!important;
    color: #b2dfdb!important
}

.btn-outline-teal.btn-outline-lighten-4:hover {
    background-color: #b2dfdb!important
}

.border-teal.border-lighten-4 {
    border: 1px solid #b2dfdb!important
}

.border-top-teal.border-top-lighten-4 {
    border-top: 1px solid #b2dfdb!important
}

.border-bottom-teal.border-bottom-lighten-4 {
    border-bottom: 1px solid #b2dfdb!important
}

.border-left-teal.border-left-lighten-4 {
    border-left: 1px solid #b2dfdb!important
}

.border-right-teal.border-right-lighten-4 {
    border-right: 1px solid #b2dfdb!important
}

.btn-teal.btn-lighten-3,.btn-teal.btn-lighten-3:hover {
    border-color: #00796b!important
}

.overlay-teal.overlay-lighten-4 {
    background: #b2dfdb;
    background: rgba(178,223,219,.8)
}

.bg-teal.bg-lighten-3,.btn-teal.btn-lighten-3 {
    background-color: #80cbc4!important
}

.teal.lighten-3 {
    color: #80cbc4!important
}

.bg-teal.bg-lighten-3.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(128,203,196,.6)
}

.btn-teal.btn-lighten-3:hover {
    background-color: #00695c!important
}

.btn-teal.btn-lighten-3:active,.btn-teal.btn-lighten-3:focus {
    border-color: #00695c!important;
    background-color: #004d40!important
}

.btn-outline-teal.btn-outline-lighten-3 {
    border-color: #80cbc4!important;
    color: #80cbc4!important
}

.btn-outline-teal.btn-outline-lighten-3:hover {
    background-color: #80cbc4!important
}

.border-teal.border-lighten-3 {
    border: 1px solid #80cbc4!important
}

.border-top-teal.border-top-lighten-3 {
    border-top: 1px solid #80cbc4!important
}

.border-bottom-teal.border-bottom-lighten-3 {
    border-bottom: 1px solid #80cbc4!important
}

.border-left-teal.border-left-lighten-3 {
    border-left: 1px solid #80cbc4!important
}

.border-right-teal.border-right-lighten-3 {
    border-right: 1px solid #80cbc4!important
}

.btn-teal.btn-lighten-2,.btn-teal.btn-lighten-2:hover {
    border-color: #00796b!important
}

.overlay-teal.overlay-lighten-3 {
    background: #80cbc4;
    background: rgba(128,203,196,.8)
}

.bg-teal.bg-lighten-2,.btn-teal.btn-lighten-2 {
    background-color: #4db6ac!important
}

.teal.lighten-2 {
    color: #4db6ac!important
}

.bg-teal.bg-lighten-2.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(77,182,172,.6)
}

.btn-teal.btn-lighten-2:hover {
    background-color: #00695c!important
}

.btn-teal.btn-lighten-2:active,.btn-teal.btn-lighten-2:focus {
    border-color: #00695c!important;
    background-color: #004d40!important
}

.btn-outline-teal.btn-outline-lighten-2 {
    border-color: #4db6ac!important;
    color: #4db6ac!important
}

.btn-outline-teal.btn-outline-lighten-2:hover {
    background-color: #4db6ac!important
}

.border-teal.border-lighten-2 {
    border: 1px solid #4db6ac!important
}

.border-top-teal.border-top-lighten-2 {
    border-top: 1px solid #4db6ac!important
}

.border-bottom-teal.border-bottom-lighten-2 {
    border-bottom: 1px solid #4db6ac!important
}

.border-left-teal.border-left-lighten-2 {
    border-left: 1px solid #4db6ac!important
}

.border-right-teal.border-right-lighten-2 {
    border-right: 1px solid #4db6ac!important
}

.btn-teal.btn-lighten-1,.btn-teal.btn-lighten-1:hover {
    border-color: #00796b!important
}

.overlay-teal.overlay-lighten-2 {
    background: #4db6ac;
    background: rgba(77,182,172,.8)
}

.bg-teal.bg-lighten-1,.btn-teal.btn-lighten-1 {
    background-color: #26a69a!important
}

.teal.lighten-1 {
    color: #26a69a!important
}

.bg-teal.bg-lighten-1.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(38,166,154,.6)
}

.bg-teal.bg-glow,.btn-outline-teal.btn-glow,.btn-teal.btn-glow {
    box-shadow: 0 1px 20px 1px rgba(0,150,136,.6)
}

.btn-teal.btn-lighten-1:hover {
    background-color: #00695c!important
}

.btn-teal.btn-lighten-1:active,.btn-teal.btn-lighten-1:focus {
    border-color: #00695c!important;
    background-color: #004d40!important
}

.btn-outline-teal.btn-outline-lighten-1 {
    border-color: #26a69a!important;
    color: #26a69a!important
}

.btn-outline-teal.btn-outline-lighten-1:hover {
    background-color: #26a69a!important
}

.border-teal.border-lighten-1 {
    border: 1px solid #26a69a!important
}

.border-top-teal.border-top-lighten-1 {
    border-top: 1px solid #26a69a!important
}

.border-bottom-teal.border-bottom-lighten-1 {
    border-bottom: 1px solid #26a69a!important
}

.border-left-teal.border-left-lighten-1 {
    border-left: 1px solid #26a69a!important
}

.border-right-teal.border-right-lighten-1 {
    border-right: 1px solid #26a69a!important
}

.alert-teal,.btn-teal {
    border-color: #009688!important
}

.overlay-teal.overlay-lighten-1 {
    background: #26a69a;
    background: rgba(38,166,154,.8)
}

.teal {
    color: #009688!important
}

.alert-teal,.alert-teal .alert-link {
    color: #000!important
}

.bg-teal {
    background-color: #009688!important
}

.bg-teal .card-footer,.bg-teal .card-header {
    background-color: transparent
}

.selectboxit.selectboxit-btn.bg-teal {
    background-color: #009688!important
}

.alert-teal {
    background-color: #00ddc9!important
}

.overlay-teal {
    background: #009688;
    background: rgba(0,150,136,.8)
}

.btn-teal,.color-info.teal {
    background-color: #009688!important
}

.btn-teal {
    color: #FFF
}

.btn-teal:hover {
    border-color: #26a69a!important;
    background-color: #26a69a!important;
    color: #FFF!important
}

.btn-teal:active,.btn-teal:focus {
    border-color: #00695c!important;
    background-color: #00695c!important;
    color: #FFF!important
}

.btn-outline-teal {
    border-color: #009688;
    background-color: transparent;
    color: #009688
}

.btn-outline-teal:hover {
    background-color: #009688;
    color: #FFF!important
}

input[type=checkbox].bg-teal+.custom-control-label:before,input[type=radio].bg-teal+.custom-control-label:before {
    background-color: #009688!important
}

.border-teal {
    border: 1px solid #009688!important
}

.border-top-teal {
    border-top: 1px solid #009688
}

.border-bottom-teal {
    border-bottom: 1px solid #009688
}

.border-left-teal {
    border-left: 1px solid #009688
}

.border-right-teal {
    border-right: 1px solid #009688
}

.btn-teal.btn-darken-1,.btn-teal.btn-darken-1:hover {
    border-color: #00796b!important
}

.bullet.bullet-teal {
    background-color: #009688
}

.bg-teal.bg-darken-1,.btn-teal.btn-darken-1 {
    background-color: #00897b!important
}

.teal.darken-1 {
    color: #00897b!important
}

.bg-teal.bg-darken-1.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(0,137,123,.6)
}

.btn-teal.btn-darken-1:hover {
    background-color: #00695c!important
}

.btn-teal.btn-darken-1:active,.btn-teal.btn-darken-1:focus {
    border-color: #00695c!important;
    background-color: #004d40!important
}

.btn-outline-teal.btn-outline-darken-1 {
    border-color: #00897b!important;
    color: #00897b!important
}

.btn-outline-teal.btn-outline-darken-1:hover {
    background-color: #00897b!important
}

.border-teal.border-darken-1 {
    border: 1px solid #00897b!important
}

.border-top-teal.border-top-darken-1 {
    border-top: 1px solid #00897b!important
}

.border-bottom-teal.border-bottom-darken-1 {
    border-bottom: 1px solid #00897b!important
}

.border-left-teal.border-left-darken-1 {
    border-left: 1px solid #00897b!important
}

.border-right-teal.border-right-darken-1 {
    border-right: 1px solid #00897b!important
}

.btn-teal.btn-darken-2,.btn-teal.btn-darken-2:hover {
    border-color: #00796b!important
}

.overlay-teal.overlay-darken-1 {
    background: #00897b;
    background: rgba(0,137,123,.8)
}

.bg-teal.bg-darken-2,.btn-teal.btn-darken-2 {
    background-color: #00796b!important
}

.teal.darken-2 {
    color: #00796b!important
}

.bg-teal.bg-darken-2.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(0,121,107,.6)
}

.btn-teal.btn-darken-2:hover {
    background-color: #00695c!important
}

.btn-teal.btn-darken-2:active,.btn-teal.btn-darken-2:focus {
    border-color: #00695c!important;
    background-color: #004d40!important
}

.btn-outline-teal.btn-outline-darken-2 {
    border-color: #00796b!important;
    color: #00796b!important
}

.btn-outline-teal.btn-outline-darken-2:hover {
    background-color: #00796b!important
}

.border-teal.border-darken-2 {
    border: 1px solid #00796b!important
}

.border-top-teal.border-top-darken-2 {
    border-top: 1px solid #00796b!important
}

.border-bottom-teal.border-bottom-darken-2 {
    border-bottom: 1px solid #00796b!important
}

.border-left-teal.border-left-darken-2 {
    border-left: 1px solid #00796b!important
}

.border-right-teal.border-right-darken-2 {
    border-right: 1px solid #00796b!important
}

.overlay-teal.overlay-darken-2 {
    background: #00796b;
    background: rgba(0,121,107,.8)
}

.teal.darken-3 {
    color: #00695c!important
}

.bg-teal.bg-darken-3 {
    background-color: #00695c!important
}

.bg-teal.bg-darken-3.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(0,105,92,.6)
}

.btn-teal.btn-darken-3,.btn-teal.btn-darken-3:hover {
    border-color: #00796b!important;
    background-color: #00695c!important
}

.btn-teal.btn-darken-3:active,.btn-teal.btn-darken-3:focus {
    border-color: #00695c!important;
    background-color: #004d40!important
}

.btn-outline-teal.btn-outline-darken-3 {
    border-color: #00695c!important;
    color: #00695c!important
}

.btn-outline-teal.btn-outline-darken-3:hover {
    background-color: #00695c!important
}

.border-teal.border-darken-3 {
    border: 1px solid #00695c!important
}

.border-top-teal.border-top-darken-3 {
    border-top: 1px solid #00695c!important
}

.border-bottom-teal.border-bottom-darken-3 {
    border-bottom: 1px solid #00695c!important
}

.border-left-teal.border-left-darken-3 {
    border-left: 1px solid #00695c!important
}

.border-right-teal.border-right-darken-3 {
    border-right: 1px solid #00695c!important
}

.btn-teal.btn-darken-4,.btn-teal.btn-darken-4:hover {
    border-color: #00796b!important
}

.overlay-teal.overlay-darken-3 {
    background: #00695c;
    background: rgba(0,105,92,.8)
}

.bg-teal.bg-darken-4,.btn-teal.btn-darken-4 {
    background-color: #004d40!important
}

.teal.darken-4 {
    color: #004d40!important
}

.bg-teal.bg-darken-4.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(0,77,64,.6)
}

.btn-teal.btn-darken-4:hover {
    background-color: #00695c!important
}

.btn-teal.btn-darken-4:active,.btn-teal.btn-darken-4:focus {
    border-color: #00695c!important;
    background-color: #004d40!important
}

.btn-outline-teal.btn-outline-darken-4 {
    border-color: #004d40!important;
    color: #004d40!important
}

.btn-outline-teal.btn-outline-darken-4:hover {
    background-color: #004d40!important
}

.border-teal.border-darken-4 {
    border: 1px solid #004d40!important
}

.border-top-teal.border-top-darken-4 {
    border-top: 1px solid #004d40!important
}

.border-bottom-teal.border-bottom-darken-4 {
    border-bottom: 1px solid #004d40!important
}

.border-left-teal.border-left-darken-4 {
    border-left: 1px solid #004d40!important
}

.border-right-teal.border-right-darken-4 {
    border-right: 1px solid #004d40!important
}

.btn-teal.btn-accent-1,.btn-teal.btn-accent-1:hover {
    border-color: #00796b!important
}

.overlay-teal.overlay-darken-4 {
    background: #004d40;
    background: rgba(0,77,64,.8)
}

.bg-teal.bg-accent-1,.btn-teal.btn-accent-1 {
    background-color: #a7ffeb!important
}

.teal.accent-1 {
    color: #a7ffeb!important
}

.bg-teal.bg-accent-1.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(167,255,235,.6)
}

.btn-teal.btn-accent-1:hover {
    background-color: #00695c!important
}

.btn-teal.btn-accent-1:active,.btn-teal.btn-accent-1:focus {
    border-color: #00695c!important;
    background-color: #004d40!important
}

.btn-outline-teal.btn-outline-accent-1 {
    border-color: #a7ffeb!important;
    color: #a7ffeb!important
}

.btn-outline-teal.btn-outline-accent-1:hover {
    background-color: #a7ffeb!important
}

.border-teal.border-accent-1 {
    border: 1px solid #a7ffeb!important
}

.border-top-teal.border-top-accent-1 {
    border-top: 1px solid #a7ffeb!important
}

.border-bottom-teal.border-bottom-accent-1 {
    border-bottom: 1px solid #a7ffeb!important
}

.border-left-teal.border-left-accent-1 {
    border-left: 1px solid #a7ffeb!important
}

.border-right-teal.border-right-accent-1 {
    border-right: 1px solid #a7ffeb!important
}

.btn-teal.btn-accent-2,.btn-teal.btn-accent-2:hover {
    border-color: #00796b!important
}

.overlay-teal.overlay-accent-1 {
    background: #a7ffeb;
    background: rgba(167,255,235,.8)
}

.bg-teal.bg-accent-2,.btn-teal.btn-accent-2 {
    background-color: #64ffda!important
}

.teal.accent-2 {
    color: #64ffda!important
}

.bg-teal.bg-accent-2.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(100,255,218,.6)
}

.btn-teal.btn-accent-2:hover {
    background-color: #00695c!important
}

.btn-teal.btn-accent-2:active,.btn-teal.btn-accent-2:focus {
    border-color: #00695c!important;
    background-color: #004d40!important
}

.btn-outline-teal.btn-outline-accent-2 {
    border-color: #64ffda!important;
    color: #64ffda!important
}

.btn-outline-teal.btn-outline-accent-2:hover {
    background-color: #64ffda!important
}

.border-teal.border-accent-2 {
    border: 1px solid #64ffda!important
}

.border-top-teal.border-top-accent-2 {
    border-top: 1px solid #64ffda!important
}

.border-bottom-teal.border-bottom-accent-2 {
    border-bottom: 1px solid #64ffda!important
}

.border-left-teal.border-left-accent-2 {
    border-left: 1px solid #64ffda!important
}

.border-right-teal.border-right-accent-2 {
    border-right: 1px solid #64ffda!important
}

.btn-teal.btn-accent-3,.btn-teal.btn-accent-3:hover {
    border-color: #00796b!important
}

.overlay-teal.overlay-accent-2 {
    background: #64ffda;
    background: rgba(100,255,218,.8)
}

.bg-teal.bg-accent-3,.btn-teal.btn-accent-3 {
    background-color: #1de9b6!important
}

.teal.accent-3 {
    color: #1de9b6!important
}

.bg-teal.bg-accent-3.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(29,233,182,.6)
}

.btn-teal.btn-accent-3:hover {
    background-color: #00695c!important
}

.btn-teal.btn-accent-3:active,.btn-teal.btn-accent-3:focus {
    border-color: #00695c!important;
    background-color: #004d40!important
}

.btn-outline-teal.btn-outline-accent-3 {
    border-color: #1de9b6!important;
    color: #1de9b6!important
}

.btn-outline-teal.btn-outline-accent-3:hover {
    background-color: #1de9b6!important
}

.border-teal.border-accent-3 {
    border: 1px solid #1de9b6!important
}

.border-top-teal.border-top-accent-3 {
    border-top: 1px solid #1de9b6!important
}

.border-bottom-teal.border-bottom-accent-3 {
    border-bottom: 1px solid #1de9b6!important
}

.border-left-teal.border-left-accent-3 {
    border-left: 1px solid #1de9b6!important
}

.border-right-teal.border-right-accent-3 {
    border-right: 1px solid #1de9b6!important
}

.btn-teal.btn-accent-4,.btn-teal.btn-accent-4:hover {
    border-color: #00796b!important
}

.overlay-teal.overlay-accent-3 {
    background: #1de9b6;
    background: rgba(29,233,182,.8)
}

.bg-teal.bg-accent-4,.btn-teal.btn-accent-4 {
    background-color: #00bfa5!important
}

.teal.accent-4 {
    color: #00bfa5!important
}

.bg-teal.bg-accent-4.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(0,191,165,.6)
}

.btn-teal.btn-accent-4:hover {
    background-color: #00695c!important
}

.btn-teal.btn-accent-4:active,.btn-teal.btn-accent-4:focus {
    border-color: #00695c!important;
    background-color: #004d40!important
}

.btn-outline-teal.btn-outline-accent-4 {
    border-color: #00bfa5!important;
    color: #00bfa5!important
}

.btn-outline-teal.btn-outline-accent-4:hover {
    background-color: #00bfa5!important
}

input:focus~.bg-teal {
    box-shadow: 0 0 0 .075rem #fff,0 0 0 .21rem #00bfa5!important
}

.border-teal.border-accent-4 {
    border: 1px solid #00bfa5!important
}

.border-top-teal.border-top-accent-4 {
    border-top: 1px solid #00bfa5!important
}

.border-bottom-teal.border-bottom-accent-4 {
    border-bottom: 1px solid #00bfa5!important
}

.border-left-teal.border-left-accent-4 {
    border-left: 1px solid #00bfa5!important
}

.border-right-teal.border-right-accent-4 {
    border-right: 1px solid #00bfa5!important
}

.overlay-teal.overlay-accent-4 {
    background: #00bfa5;
    background: rgba(0,191,165,.8)
}

.bg-yellow.bg-lighten-5,.btn-yellow.btn-lighten-5 {
    background-color: #fffde7!important
}

.yellow.lighten-5 {
    color: #fffde7!important
}

.bg-yellow.bg-lighten-5.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(255,253,231,.6)
}

.btn-yellow.btn-lighten-5 {
    border-color: #fbc02d!important
}

.btn-yellow.btn-lighten-5:hover {
    border-color: #fbc02d!important;
    background-color: #f9a825!important
}

.btn-yellow.btn-lighten-5:active,.btn-yellow.btn-lighten-5:focus {
    border-color: #f9a825!important;
    background-color: #f57f17!important
}

.btn-outline-yellow.btn-outline-lighten-5 {
    border-color: #fffde7!important;
    color: #fffde7!important
}

.btn-outline-yellow.btn-outline-lighten-5:hover {
    background-color: #fffde7!important
}

.border-yellow.border-lighten-5 {
    border: 1px solid #fffde7!important
}

.border-top-yellow.border-top-lighten-5 {
    border-top: 1px solid #fffde7!important
}

.border-bottom-yellow.border-bottom-lighten-5 {
    border-bottom: 1px solid #fffde7!important
}

.border-left-yellow.border-left-lighten-5 {
    border-left: 1px solid #fffde7!important
}

.border-right-yellow.border-right-lighten-5 {
    border-right: 1px solid #fffde7!important
}

.overlay-yellow.overlay-lighten-5 {
    background: #fffde7;
    background: rgba(255,253,231,.8)
}

.bg-yellow.bg-lighten-4,.btn-yellow.btn-lighten-4 {
    background-color: #fff9c4!important
}

.yellow.lighten-4 {
    color: #fff9c4!important
}

.bg-yellow.bg-lighten-4.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(255,249,196,.6)
}

.btn-yellow.btn-lighten-4 {
    border-color: #fbc02d!important
}

.btn-yellow.btn-lighten-4:hover {
    border-color: #fbc02d!important;
    background-color: #f9a825!important
}

.btn-yellow.btn-lighten-4:active,.btn-yellow.btn-lighten-4:focus {
    border-color: #f9a825!important;
    background-color: #f57f17!important
}

.btn-outline-yellow.btn-outline-lighten-4 {
    border-color: #fff9c4!important;
    color: #fff9c4!important
}

.btn-outline-yellow.btn-outline-lighten-4:hover {
    background-color: #fff9c4!important
}

.border-yellow.border-lighten-4 {
    border: 1px solid #fff9c4!important
}

.border-top-yellow.border-top-lighten-4 {
    border-top: 1px solid #fff9c4!important
}

.border-bottom-yellow.border-bottom-lighten-4 {
    border-bottom: 1px solid #fff9c4!important
}

.border-left-yellow.border-left-lighten-4 {
    border-left: 1px solid #fff9c4!important
}

.border-right-yellow.border-right-lighten-4 {
    border-right: 1px solid #fff9c4!important
}

.overlay-yellow.overlay-lighten-4 {
    background: #fff9c4;
    background: rgba(255,249,196,.8)
}

.bg-yellow.bg-lighten-3,.btn-yellow.btn-lighten-3 {
    background-color: #fff59d!important
}

.yellow.lighten-3 {
    color: #fff59d!important
}

.bg-yellow.bg-lighten-3.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(255,245,157,.6)
}

.btn-yellow.btn-lighten-3 {
    border-color: #fbc02d!important
}

.btn-yellow.btn-lighten-3:hover {
    border-color: #fbc02d!important;
    background-color: #f9a825!important
}

.btn-yellow.btn-lighten-3:active,.btn-yellow.btn-lighten-3:focus {
    border-color: #f9a825!important;
    background-color: #f57f17!important
}

.btn-outline-yellow.btn-outline-lighten-3 {
    border-color: #fff59d!important;
    color: #fff59d!important
}

.btn-outline-yellow.btn-outline-lighten-3:hover {
    background-color: #fff59d!important
}

.border-yellow.border-lighten-3 {
    border: 1px solid #fff59d!important
}

.border-top-yellow.border-top-lighten-3 {
    border-top: 1px solid #fff59d!important
}

.border-bottom-yellow.border-bottom-lighten-3 {
    border-bottom: 1px solid #fff59d!important
}

.border-left-yellow.border-left-lighten-3 {
    border-left: 1px solid #fff59d!important
}

.border-right-yellow.border-right-lighten-3 {
    border-right: 1px solid #fff59d!important
}

.overlay-yellow.overlay-lighten-3 {
    background: #fff59d;
    background: rgba(255,245,157,.8)
}

.bg-yellow.bg-lighten-2,.btn-yellow.btn-lighten-2 {
    background-color: #fff176!important
}

.yellow.lighten-2 {
    color: #fff176!important
}

.bg-yellow.bg-lighten-2.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(255,241,118,.6)
}

.btn-yellow.btn-lighten-2 {
    border-color: #fbc02d!important
}

.btn-yellow.btn-lighten-2:hover {
    border-color: #fbc02d!important;
    background-color: #f9a825!important
}

.btn-yellow.btn-lighten-2:active,.btn-yellow.btn-lighten-2:focus {
    border-color: #f9a825!important;
    background-color: #f57f17!important
}

.btn-outline-yellow.btn-outline-lighten-2 {
    border-color: #fff176!important;
    color: #fff176!important
}

.btn-outline-yellow.btn-outline-lighten-2:hover {
    background-color: #fff176!important
}

.border-yellow.border-lighten-2 {
    border: 1px solid #fff176!important
}

.border-top-yellow.border-top-lighten-2 {
    border-top: 1px solid #fff176!important
}

.border-bottom-yellow.border-bottom-lighten-2 {
    border-bottom: 1px solid #fff176!important
}

.border-left-yellow.border-left-lighten-2 {
    border-left: 1px solid #fff176!important
}

.border-right-yellow.border-right-lighten-2 {
    border-right: 1px solid #fff176!important
}

.overlay-yellow.overlay-lighten-2 {
    background: #fff176;
    background: rgba(255,241,118,.8)
}

.bg-yellow.bg-lighten-1,.btn-yellow.btn-lighten-1 {
    background-color: #ffee58!important
}

.yellow.lighten-1 {
    color: #ffee58!important
}

.bg-yellow.bg-lighten-1.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(255,238,88,.6)
}

.bg-yellow.bg-glow,.btn-outline-yellow.btn-glow,.btn-yellow.btn-glow {
    box-shadow: 0 1px 20px 1px rgba(255,235,59,.6)
}

.btn-yellow.btn-lighten-1 {
    border-color: #fbc02d!important
}

.btn-yellow.btn-lighten-1:hover {
    border-color: #fbc02d!important;
    background-color: #f9a825!important
}

.btn-yellow.btn-lighten-1:active,.btn-yellow.btn-lighten-1:focus {
    border-color: #f9a825!important;
    background-color: #f57f17!important
}

.btn-outline-yellow.btn-outline-lighten-1 {
    border-color: #ffee58!important;
    color: #ffee58!important
}

.btn-outline-yellow.btn-outline-lighten-1:hover {
    background-color: #ffee58!important
}

.border-yellow.border-lighten-1 {
    border: 1px solid #ffee58!important
}

.border-top-yellow.border-top-lighten-1 {
    border-top: 1px solid #ffee58!important
}

.border-bottom-yellow.border-bottom-lighten-1 {
    border-bottom: 1px solid #ffee58!important
}

.border-left-yellow.border-left-lighten-1 {
    border-left: 1px solid #ffee58!important
}

.border-right-yellow.border-right-lighten-1 {
    border-right: 1px solid #ffee58!important
}

.alert-yellow,.btn-yellow {
    border-color: #ffeb3b!important
}

.overlay-yellow.overlay-lighten-1 {
    background: #ffee58;
    background: rgba(255,238,88,.8)
}

.yellow {
    color: #ffeb3b!important
}

.bg-yellow {
    background-color: #ffeb3b!important
}

.bg-yellow .card-footer,.bg-yellow .card-header {
    background-color: transparent
}

.selectboxit.selectboxit-btn.bg-yellow {
    background-color: #ffeb3b!important
}

.alert-yellow {
    background-color: #fff282!important;
    color: #887a00!important
}

.alert-yellow .alert-link {
    color: #5f5500!important
}

.overlay-yellow {
    background: #ffeb3b;
    background: rgba(255,235,59,.8)
}

.btn-yellow,.color-info.yellow {
    background-color: #ffeb3b!important
}

.btn-yellow {
    color: #FFF
}

.btn-yellow:hover {
    border-color: #ffee58!important;
    background-color: #ffee58!important;
    color: #FFF!important
}

.btn-yellow:active,.btn-yellow:focus {
    border-color: #f9a825!important;
    background-color: #f9a825!important;
    color: #FFF!important
}

.btn-outline-yellow {
    border-color: #ffeb3b;
    background-color: transparent;
    color: #ffeb3b
}

.btn-outline-yellow:hover {
    background-color: #ffeb3b;
    color: #FFF!important
}

input[type=checkbox].bg-yellow+.custom-control-label:before,input[type=radio].bg-yellow+.custom-control-label:before {
    background-color: #ffeb3b!important
}

.border-yellow {
    border: 1px solid #ffeb3b!important
}

.border-top-yellow {
    border-top: 1px solid #ffeb3b
}

.border-bottom-yellow {
    border-bottom: 1px solid #ffeb3b
}

.border-left-yellow {
    border-left: 1px solid #ffeb3b
}

.border-right-yellow {
    border-right: 1px solid #ffeb3b
}

.btn-yellow.btn-darken-1,.btn-yellow.btn-darken-1:hover {
    border-color: #fbc02d!important
}

.bullet.bullet-yellow {
    background-color: #ffeb3b
}

.bg-yellow.bg-darken-1,.btn-yellow.btn-darken-1 {
    background-color: #fdd835!important
}

.yellow.darken-1 {
    color: #fdd835!important
}

.bg-yellow.bg-darken-1.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(253,216,53,.6)
}

.btn-yellow.btn-darken-1:hover {
    background-color: #f9a825!important
}

.btn-yellow.btn-darken-1:active,.btn-yellow.btn-darken-1:focus {
    border-color: #f9a825!important;
    background-color: #f57f17!important
}

.btn-outline-yellow.btn-outline-darken-1 {
    border-color: #fdd835!important;
    color: #fdd835!important
}

.btn-outline-yellow.btn-outline-darken-1:hover {
    background-color: #fdd835!important
}

.border-yellow.border-darken-1 {
    border: 1px solid #fdd835!important
}

.border-top-yellow.border-top-darken-1 {
    border-top: 1px solid #fdd835!important
}

.border-bottom-yellow.border-bottom-darken-1 {
    border-bottom: 1px solid #fdd835!important
}

.border-left-yellow.border-left-darken-1 {
    border-left: 1px solid #fdd835!important
}

.border-right-yellow.border-right-darken-1 {
    border-right: 1px solid #fdd835!important
}

.btn-yellow.btn-darken-2,.btn-yellow.btn-darken-2:hover {
    border-color: #fbc02d!important
}

.overlay-yellow.overlay-darken-1 {
    background: #fdd835;
    background: rgba(253,216,53,.8)
}

.bg-yellow.bg-darken-2,.btn-yellow.btn-darken-2 {
    background-color: #fbc02d!important
}

.yellow.darken-2 {
    color: #fbc02d!important
}

.bg-yellow.bg-darken-2.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(251,192,45,.6)
}

.btn-yellow.btn-darken-2:hover {
    background-color: #f9a825!important
}

.btn-yellow.btn-darken-2:active,.btn-yellow.btn-darken-2:focus {
    border-color: #f9a825!important;
    background-color: #f57f17!important
}

.btn-outline-yellow.btn-outline-darken-2 {
    border-color: #fbc02d!important;
    color: #fbc02d!important
}

.btn-outline-yellow.btn-outline-darken-2:hover {
    background-color: #fbc02d!important
}

.border-yellow.border-darken-2 {
    border: 1px solid #fbc02d!important
}

.border-top-yellow.border-top-darken-2 {
    border-top: 1px solid #fbc02d!important
}

.border-bottom-yellow.border-bottom-darken-2 {
    border-bottom: 1px solid #fbc02d!important
}

.border-left-yellow.border-left-darken-2 {
    border-left: 1px solid #fbc02d!important
}

.border-right-yellow.border-right-darken-2 {
    border-right: 1px solid #fbc02d!important
}

.overlay-yellow.overlay-darken-2 {
    background: #fbc02d;
    background: rgba(251,192,45,.8)
}

.yellow.darken-3 {
    color: #f9a825!important
}

.bg-yellow.bg-darken-3 {
    background-color: #f9a825!important
}

.bg-yellow.bg-darken-3.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(249,168,37,.6)
}

.btn-yellow.btn-darken-3,.btn-yellow.btn-darken-3:hover {
    border-color: #fbc02d!important;
    background-color: #f9a825!important
}

.btn-yellow.btn-darken-3:active,.btn-yellow.btn-darken-3:focus {
    border-color: #f9a825!important;
    background-color: #f57f17!important
}

.btn-outline-yellow.btn-outline-darken-3 {
    border-color: #f9a825!important;
    color: #f9a825!important
}

.btn-outline-yellow.btn-outline-darken-3:hover {
    background-color: #f9a825!important
}

.border-yellow.border-darken-3 {
    border: 1px solid #f9a825!important
}

.border-top-yellow.border-top-darken-3 {
    border-top: 1px solid #f9a825!important
}

.border-bottom-yellow.border-bottom-darken-3 {
    border-bottom: 1px solid #f9a825!important
}

.border-left-yellow.border-left-darken-3 {
    border-left: 1px solid #f9a825!important
}

.border-right-yellow.border-right-darken-3 {
    border-right: 1px solid #f9a825!important
}

.btn-yellow.btn-darken-4,.btn-yellow.btn-darken-4:hover {
    border-color: #fbc02d!important
}

.overlay-yellow.overlay-darken-3 {
    background: #f9a825;
    background: rgba(249,168,37,.8)
}

.bg-yellow.bg-darken-4,.btn-yellow.btn-darken-4 {
    background-color: #f57f17!important
}

.yellow.darken-4 {
    color: #f57f17!important
}

.bg-yellow.bg-darken-4.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(245,127,23,.6)
}

.btn-yellow.btn-darken-4:hover {
    background-color: #f9a825!important
}

.btn-yellow.btn-darken-4:active,.btn-yellow.btn-darken-4:focus {
    border-color: #f9a825!important;
    background-color: #f57f17!important
}

.btn-outline-yellow.btn-outline-darken-4 {
    border-color: #f57f17!important;
    color: #f57f17!important
}

.btn-outline-yellow.btn-outline-darken-4:hover {
    background-color: #f57f17!important
}

.border-yellow.border-darken-4 {
    border: 1px solid #f57f17!important
}

.border-top-yellow.border-top-darken-4 {
    border-top: 1px solid #f57f17!important
}

.border-bottom-yellow.border-bottom-darken-4 {
    border-bottom: 1px solid #f57f17!important
}

.border-left-yellow.border-left-darken-4 {
    border-left: 1px solid #f57f17!important
}

.border-right-yellow.border-right-darken-4 {
    border-right: 1px solid #f57f17!important
}

.btn-yellow.btn-accent-1,.btn-yellow.btn-accent-1:hover {
    border-color: #fbc02d!important
}

.overlay-yellow.overlay-darken-4 {
    background: #f57f17;
    background: rgba(245,127,23,.8)
}

.bg-yellow.bg-accent-1,.btn-yellow.btn-accent-1 {
    background-color: #ffff8d!important
}

.yellow.accent-1 {
    color: #ffff8d!important
}

.bg-yellow.bg-accent-1.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(255,255,141,.6)
}

.btn-yellow.btn-accent-1:hover {
    background-color: #f9a825!important
}

.btn-yellow.btn-accent-1:active,.btn-yellow.btn-accent-1:focus {
    border-color: #f9a825!important;
    background-color: #f57f17!important
}

.btn-outline-yellow.btn-outline-accent-1 {
    border-color: #ffff8d!important;
    color: #ffff8d!important
}

.btn-outline-yellow.btn-outline-accent-1:hover {
    background-color: #ffff8d!important
}

.border-yellow.border-accent-1 {
    border: 1px solid #ffff8d!important
}

.border-top-yellow.border-top-accent-1 {
    border-top: 1px solid #ffff8d!important
}

.border-bottom-yellow.border-bottom-accent-1 {
    border-bottom: 1px solid #ffff8d!important
}

.border-left-yellow.border-left-accent-1 {
    border-left: 1px solid #ffff8d!important
}

.border-right-yellow.border-right-accent-1 {
    border-right: 1px solid #ffff8d!important
}

.btn-yellow.btn-accent-2,.btn-yellow.btn-accent-2:hover {
    border-color: #fbc02d!important
}

.overlay-yellow.overlay-accent-1 {
    background: #ffff8d;
    background: rgba(255,255,141,.8)
}

.bg-yellow.bg-accent-2,.btn-yellow.btn-accent-2 {
    background-color: #ff0!important
}

.yellow.accent-2 {
    color: #ff0!important
}

.bg-yellow.bg-accent-2.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(255,255,0,.6)
}

.btn-yellow.btn-accent-2:hover {
    background-color: #f9a825!important
}

.btn-yellow.btn-accent-2:active,.btn-yellow.btn-accent-2:focus {
    border-color: #f9a825!important;
    background-color: #f57f17!important
}

.btn-outline-yellow.btn-outline-accent-2 {
    border-color: #ff0!important;
    color: #ff0!important
}

.btn-outline-yellow.btn-outline-accent-2:hover {
    background-color: #ff0!important
}

.border-yellow.border-accent-2 {
    border: 1px solid #ff0!important
}

.border-top-yellow.border-top-accent-2 {
    border-top: 1px solid #ff0!important
}

.border-bottom-yellow.border-bottom-accent-2 {
    border-bottom: 1px solid #ff0!important
}

.border-left-yellow.border-left-accent-2 {
    border-left: 1px solid #ff0!important
}

.border-right-yellow.border-right-accent-2 {
    border-right: 1px solid #ff0!important
}

.btn-yellow.btn-accent-3,.btn-yellow.btn-accent-3:hover {
    border-color: #fbc02d!important
}

.overlay-yellow.overlay-accent-2 {
    background: #ff0;
    background: rgba(255,255,0,.8)
}

.bg-yellow.bg-accent-3,.btn-yellow.btn-accent-3 {
    background-color: #ffea00!important
}

.yellow.accent-3 {
    color: #ffea00!important
}

.bg-yellow.bg-accent-3.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(255,234,0,.6)
}

.btn-yellow.btn-accent-3:hover {
    background-color: #f9a825!important
}

.btn-yellow.btn-accent-3:active,.btn-yellow.btn-accent-3:focus {
    border-color: #f9a825!important;
    background-color: #f57f17!important
}

.btn-outline-yellow.btn-outline-accent-3 {
    border-color: #ffea00!important;
    color: #ffea00!important
}

.btn-outline-yellow.btn-outline-accent-3:hover {
    background-color: #ffea00!important
}

.border-yellow.border-accent-3 {
    border: 1px solid #ffea00!important
}

.border-top-yellow.border-top-accent-3 {
    border-top: 1px solid #ffea00!important
}

.border-bottom-yellow.border-bottom-accent-3 {
    border-bottom: 1px solid #ffea00!important
}

.border-left-yellow.border-left-accent-3 {
    border-left: 1px solid #ffea00!important
}

.border-right-yellow.border-right-accent-3 {
    border-right: 1px solid #ffea00!important
}

.btn-yellow.btn-accent-4,.btn-yellow.btn-accent-4:hover {
    border-color: #fbc02d!important
}

.overlay-yellow.overlay-accent-3 {
    background: #ffea00;
    background: rgba(255,234,0,.8)
}

.bg-yellow.bg-accent-4,.btn-yellow.btn-accent-4 {
    background-color: #ffd600!important
}

.yellow.accent-4 {
    color: #ffd600!important
}

.bg-yellow.bg-accent-4.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(255,214,0,.6)
}

.btn-yellow.btn-accent-4:hover {
    background-color: #f9a825!important
}

.btn-yellow.btn-accent-4:active,.btn-yellow.btn-accent-4:focus {
    border-color: #f9a825!important;
    background-color: #f57f17!important
}

.btn-outline-yellow.btn-outline-accent-4 {
    border-color: #ffd600!important;
    color: #ffd600!important
}

.btn-outline-yellow.btn-outline-accent-4:hover {
    background-color: #ffd600!important
}

input:focus~.bg-yellow {
    box-shadow: 0 0 0 .075rem #fff,0 0 0 .21rem #ffd600!important
}

.border-yellow.border-accent-4 {
    border: 1px solid #ffd600!important
}

.border-top-yellow.border-top-accent-4 {
    border-top: 1px solid #ffd600!important
}

.border-bottom-yellow.border-bottom-accent-4 {
    border-bottom: 1px solid #ffd600!important
}

.border-left-yellow.border-left-accent-4 {
    border-left: 1px solid #ffd600!important
}

.border-right-yellow.border-right-accent-4 {
    border-right: 1px solid #ffd600!important
}

.btn-amber.btn-lighten-5,.btn-amber.btn-lighten-5:hover {
    border-color: #ffa000!important
}

.overlay-yellow.overlay-accent-4 {
    background: #ffd600;
    background: rgba(255,214,0,.8)
}

.bg-amber.bg-lighten-5,.btn-amber.btn-lighten-5 {
    background-color: #fff8e1!important
}

.amber.lighten-5 {
    color: #fff8e1!important
}

.bg-amber.bg-lighten-5.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(255,248,225,.6)
}

.btn-amber.btn-lighten-5:hover {
    background-color: #ff8f00!important
}

.btn-amber.btn-lighten-5:active,.btn-amber.btn-lighten-5:focus {
    border-color: #ff8f00!important;
    background-color: #ff6f00!important
}

.btn-outline-amber.btn-outline-lighten-5 {
    border-color: #fff8e1!important;
    color: #fff8e1!important
}

.btn-outline-amber.btn-outline-lighten-5:hover {
    background-color: #fff8e1!important
}

.border-amber.border-lighten-5 {
    border: 1px solid #fff8e1!important
}

.border-top-amber.border-top-lighten-5 {
    border-top: 1px solid #fff8e1!important
}

.border-bottom-amber.border-bottom-lighten-5 {
    border-bottom: 1px solid #fff8e1!important
}

.border-left-amber.border-left-lighten-5 {
    border-left: 1px solid #fff8e1!important
}

.border-right-amber.border-right-lighten-5 {
    border-right: 1px solid #fff8e1!important
}

.btn-amber.btn-lighten-4,.btn-amber.btn-lighten-4:hover {
    border-color: #ffa000!important
}

.overlay-amber.overlay-lighten-5 {
    background: #fff8e1;
    background: rgba(255,248,225,.8)
}

.bg-amber.bg-lighten-4,.btn-amber.btn-lighten-4 {
    background-color: #ffecb3!important
}

.amber.lighten-4 {
    color: #ffecb3!important
}

.bg-amber.bg-lighten-4.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(255,236,179,.6)
}

.btn-amber.btn-lighten-4:hover {
    background-color: #ff8f00!important
}

.btn-amber.btn-lighten-4:active,.btn-amber.btn-lighten-4:focus {
    border-color: #ff8f00!important;
    background-color: #ff6f00!important
}

.btn-outline-amber.btn-outline-lighten-4 {
    border-color: #ffecb3!important;
    color: #ffecb3!important
}

.btn-outline-amber.btn-outline-lighten-4:hover {
    background-color: #ffecb3!important
}

.border-amber.border-lighten-4 {
    border: 1px solid #ffecb3!important
}

.border-top-amber.border-top-lighten-4 {
    border-top: 1px solid #ffecb3!important
}

.border-bottom-amber.border-bottom-lighten-4 {
    border-bottom: 1px solid #ffecb3!important
}

.border-left-amber.border-left-lighten-4 {
    border-left: 1px solid #ffecb3!important
}

.border-right-amber.border-right-lighten-4 {
    border-right: 1px solid #ffecb3!important
}

.btn-amber.btn-lighten-3,.btn-amber.btn-lighten-3:hover {
    border-color: #ffa000!important
}

.overlay-amber.overlay-lighten-4 {
    background: #ffecb3;
    background: rgba(255,236,179,.8)
}

.bg-amber.bg-lighten-3,.btn-amber.btn-lighten-3 {
    background-color: #ffe082!important
}

.amber.lighten-3 {
    color: #ffe082!important
}

.bg-amber.bg-lighten-3.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(255,224,130,.6)
}

.btn-amber.btn-lighten-3:hover {
    background-color: #ff8f00!important
}

.btn-amber.btn-lighten-3:active,.btn-amber.btn-lighten-3:focus {
    border-color: #ff8f00!important;
    background-color: #ff6f00!important
}

.btn-outline-amber.btn-outline-lighten-3 {
    border-color: #ffe082!important;
    color: #ffe082!important
}

.btn-outline-amber.btn-outline-lighten-3:hover {
    background-color: #ffe082!important
}

.border-amber.border-lighten-3 {
    border: 1px solid #ffe082!important
}

.border-top-amber.border-top-lighten-3 {
    border-top: 1px solid #ffe082!important
}

.border-bottom-amber.border-bottom-lighten-3 {
    border-bottom: 1px solid #ffe082!important
}

.border-left-amber.border-left-lighten-3 {
    border-left: 1px solid #ffe082!important
}

.border-right-amber.border-right-lighten-3 {
    border-right: 1px solid #ffe082!important
}

.btn-amber.btn-lighten-2,.btn-amber.btn-lighten-2:hover {
    border-color: #ffa000!important
}

.overlay-amber.overlay-lighten-3 {
    background: #ffe082;
    background: rgba(255,224,130,.8)
}

.bg-amber.bg-lighten-2,.btn-amber.btn-lighten-2 {
    background-color: #ffd54f!important
}

.amber.lighten-2 {
    color: #ffd54f!important
}

.bg-amber.bg-lighten-2.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(255,213,79,.6)
}

.btn-amber.btn-lighten-2:hover {
    background-color: #ff8f00!important
}

.btn-amber.btn-lighten-2:active,.btn-amber.btn-lighten-2:focus {
    border-color: #ff8f00!important;
    background-color: #ff6f00!important
}

.btn-outline-amber.btn-outline-lighten-2 {
    border-color: #ffd54f!important;
    color: #ffd54f!important
}

.btn-outline-amber.btn-outline-lighten-2:hover {
    background-color: #ffd54f!important
}

.border-amber.border-lighten-2 {
    border: 1px solid #ffd54f!important
}

.border-top-amber.border-top-lighten-2 {
    border-top: 1px solid #ffd54f!important
}

.border-bottom-amber.border-bottom-lighten-2 {
    border-bottom: 1px solid #ffd54f!important
}

.border-left-amber.border-left-lighten-2 {
    border-left: 1px solid #ffd54f!important
}

.border-right-amber.border-right-lighten-2 {
    border-right: 1px solid #ffd54f!important
}

.btn-amber.btn-lighten-1,.btn-amber.btn-lighten-1:hover {
    border-color: #ffa000!important
}

.overlay-amber.overlay-lighten-2 {
    background: #ffd54f;
    background: rgba(255,213,79,.8)
}

.bg-amber.bg-lighten-1,.btn-amber.btn-lighten-1 {
    background-color: #ffca28!important
}

.amber.lighten-1 {
    color: #ffca28!important
}

.bg-amber.bg-lighten-1.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(255,202,40,.6)
}

.bg-amber.bg-glow,.btn-amber.btn-glow,.btn-outline-amber.btn-glow {
    box-shadow: 0 1px 20px 1px rgba(255,193,7,.6)
}

.btn-amber.btn-lighten-1:hover {
    background-color: #ff8f00!important
}

.btn-amber.btn-lighten-1:active,.btn-amber.btn-lighten-1:focus {
    border-color: #ff8f00!important;
    background-color: #ff6f00!important
}

.btn-outline-amber.btn-outline-lighten-1 {
    border-color: #ffca28!important;
    color: #ffca28!important
}

.btn-outline-amber.btn-outline-lighten-1:hover {
    background-color: #ffca28!important
}

.border-amber.border-lighten-1 {
    border: 1px solid #ffca28!important
}

.border-top-amber.border-top-lighten-1 {
    border-top: 1px solid #ffca28!important
}

.border-bottom-amber.border-bottom-lighten-1 {
    border-bottom: 1px solid #ffca28!important
}

.border-left-amber.border-left-lighten-1 {
    border-left: 1px solid #ffca28!important
}

.border-right-amber.border-right-lighten-1 {
    border-right: 1px solid #ffca28!important
}

.alert-amber,.btn-amber {
    border-color: #ffc107!important
}

.overlay-amber.overlay-lighten-1 {
    background: #ffca28;
    background: rgba(255,202,40,.8)
}

.amber {
    color: #ffc107!important
}

.bg-amber {
    background-color: #ffc107!important
}

.bg-amber .card-footer,.bg-amber .card-header {
    background-color: transparent
}

.selectboxit.selectboxit-btn.bg-amber {
    background-color: #ffc107!important
}

.alert-amber {
    background-color: #ffd34e!important;
    color: #543f00!important
}

.alert-amber .alert-link {
    color: #2b2000!important
}

.overlay-amber {
    background: #ffc107;
    background: rgba(255,193,7,.8)
}

.btn-amber,.color-info.amber {
    background-color: #ffc107!important
}

.btn-amber {
    color: #FFF
}

.btn-amber:hover {
    border-color: #ffca28!important;
    background-color: #ffca28!important;
    color: #FFF!important
}

.btn-amber:active,.btn-amber:focus {
    border-color: #ff8f00!important;
    background-color: #ff8f00!important;
    color: #FFF!important
}

.btn-outline-amber {
    border-color: #ffc107;
    background-color: transparent;
    color: #ffc107
}

.btn-outline-amber:hover {
    background-color: #ffc107;
    color: #FFF!important
}

input[type=checkbox].bg-amber+.custom-control-label:before,input[type=radio].bg-amber+.custom-control-label:before {
    background-color: #ffc107!important
}

.border-amber {
    border: 1px solid #ffc107!important
}

.border-top-amber {
    border-top: 1px solid #ffc107
}

.border-bottom-amber {
    border-bottom: 1px solid #ffc107
}

.border-left-amber {
    border-left: 1px solid #ffc107
}

.border-right-amber {
    border-right: 1px solid #ffc107
}

.btn-amber.btn-darken-1,.btn-amber.btn-darken-1:hover {
    border-color: #ffa000!important
}

.bullet.bullet-amber {
    background-color: #ffc107
}

.bg-amber.bg-darken-1,.btn-amber.btn-darken-1 {
    background-color: #ffb300!important
}

.amber.darken-1 {
    color: #ffb300!important
}

.bg-amber.bg-darken-1.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(255,179,0,.6)
}

.btn-amber.btn-darken-1:hover {
    background-color: #ff8f00!important
}

.btn-amber.btn-darken-1:active,.btn-amber.btn-darken-1:focus {
    border-color: #ff8f00!important;
    background-color: #ff6f00!important
}

.btn-outline-amber.btn-outline-darken-1 {
    border-color: #ffb300!important;
    color: #ffb300!important
}

.btn-outline-amber.btn-outline-darken-1:hover {
    background-color: #ffb300!important
}

.border-amber.border-darken-1 {
    border: 1px solid #ffb300!important
}

.border-top-amber.border-top-darken-1 {
    border-top: 1px solid #ffb300!important
}

.border-bottom-amber.border-bottom-darken-1 {
    border-bottom: 1px solid #ffb300!important
}

.border-left-amber.border-left-darken-1 {
    border-left: 1px solid #ffb300!important
}

.border-right-amber.border-right-darken-1 {
    border-right: 1px solid #ffb300!important
}

.btn-amber.btn-darken-2,.btn-amber.btn-darken-2:hover {
    border-color: #ffa000!important
}

.overlay-amber.overlay-darken-1 {
    background: #ffb300;
    background: rgba(255,179,0,.8)
}

.bg-amber.bg-darken-2,.btn-amber.btn-darken-2 {
    background-color: #ffa000!important
}

.amber.darken-2 {
    color: #ffa000!important
}

.bg-amber.bg-darken-2.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(255,160,0,.6)
}

.btn-amber.btn-darken-2:hover {
    background-color: #ff8f00!important
}

.btn-amber.btn-darken-2:active,.btn-amber.btn-darken-2:focus {
    border-color: #ff8f00!important;
    background-color: #ff6f00!important
}

.btn-outline-amber.btn-outline-darken-2 {
    border-color: #ffa000!important;
    color: #ffa000!important
}

.btn-outline-amber.btn-outline-darken-2:hover {
    background-color: #ffa000!important
}

.border-amber.border-darken-2 {
    border: 1px solid #ffa000!important
}

.border-top-amber.border-top-darken-2 {
    border-top: 1px solid #ffa000!important
}

.border-bottom-amber.border-bottom-darken-2 {
    border-bottom: 1px solid #ffa000!important
}

.border-left-amber.border-left-darken-2 {
    border-left: 1px solid #ffa000!important
}

.border-right-amber.border-right-darken-2 {
    border-right: 1px solid #ffa000!important
}

.overlay-amber.overlay-darken-2 {
    background: #ffa000;
    background: rgba(255,160,0,.8)
}

.amber.darken-3 {
    color: #ff8f00!important
}

.bg-amber.bg-darken-3 {
    background-color: #ff8f00!important
}

.bg-amber.bg-darken-3.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(255,143,0,.6)
}

.btn-amber.btn-darken-3,.btn-amber.btn-darken-3:hover {
    border-color: #ffa000!important;
    background-color: #ff8f00!important
}

.btn-amber.btn-darken-3:active,.btn-amber.btn-darken-3:focus {
    border-color: #ff8f00!important;
    background-color: #ff6f00!important
}

.btn-outline-amber.btn-outline-darken-3 {
    border-color: #ff8f00!important;
    color: #ff8f00!important
}

.btn-outline-amber.btn-outline-darken-3:hover {
    background-color: #ff8f00!important
}

.border-amber.border-darken-3 {
    border: 1px solid #ff8f00!important
}

.border-top-amber.border-top-darken-3 {
    border-top: 1px solid #ff8f00!important
}

.border-bottom-amber.border-bottom-darken-3 {
    border-bottom: 1px solid #ff8f00!important
}

.border-left-amber.border-left-darken-3 {
    border-left: 1px solid #ff8f00!important
}

.border-right-amber.border-right-darken-3 {
    border-right: 1px solid #ff8f00!important
}

.btn-amber.btn-darken-4,.btn-amber.btn-darken-4:hover {
    border-color: #ffa000!important
}

.overlay-amber.overlay-darken-3 {
    background: #ff8f00;
    background: rgba(255,143,0,.8)
}

.bg-amber.bg-darken-4,.btn-amber.btn-darken-4 {
    background-color: #ff6f00!important
}

.amber.darken-4 {
    color: #ff6f00!important
}

.bg-amber.bg-darken-4.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(255,111,0,.6)
}

.btn-amber.btn-darken-4:hover {
    background-color: #ff8f00!important
}

.btn-amber.btn-darken-4:active,.btn-amber.btn-darken-4:focus {
    border-color: #ff8f00!important;
    background-color: #ff6f00!important
}

.btn-outline-amber.btn-outline-darken-4 {
    border-color: #ff6f00!important;
    color: #ff6f00!important
}

.btn-outline-amber.btn-outline-darken-4:hover {
    background-color: #ff6f00!important
}

.border-amber.border-darken-4 {
    border: 1px solid #ff6f00!important
}

.border-top-amber.border-top-darken-4 {
    border-top: 1px solid #ff6f00!important
}

.border-bottom-amber.border-bottom-darken-4 {
    border-bottom: 1px solid #ff6f00!important
}

.border-left-amber.border-left-darken-4 {
    border-left: 1px solid #ff6f00!important
}

.border-right-amber.border-right-darken-4 {
    border-right: 1px solid #ff6f00!important
}

.btn-amber.btn-accent-1,.btn-amber.btn-accent-1:hover {
    border-color: #ffa000!important
}

.overlay-amber.overlay-darken-4 {
    background: #ff6f00;
    background: rgba(255,111,0,.8)
}

.bg-amber.bg-accent-1,.btn-amber.btn-accent-1 {
    background-color: #ffe57f!important
}

.amber.accent-1 {
    color: #ffe57f!important
}

.bg-amber.bg-accent-1.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(255,229,127,.6)
}

.btn-amber.btn-accent-1:hover {
    background-color: #ff8f00!important
}

.btn-amber.btn-accent-1:active,.btn-amber.btn-accent-1:focus {
    border-color: #ff8f00!important;
    background-color: #ff6f00!important
}

.btn-outline-amber.btn-outline-accent-1 {
    border-color: #ffe57f!important;
    color: #ffe57f!important
}

.btn-outline-amber.btn-outline-accent-1:hover {
    background-color: #ffe57f!important
}

.border-amber.border-accent-1 {
    border: 1px solid #ffe57f!important
}

.border-top-amber.border-top-accent-1 {
    border-top: 1px solid #ffe57f!important
}

.border-bottom-amber.border-bottom-accent-1 {
    border-bottom: 1px solid #ffe57f!important
}

.border-left-amber.border-left-accent-1 {
    border-left: 1px solid #ffe57f!important
}

.border-right-amber.border-right-accent-1 {
    border-right: 1px solid #ffe57f!important
}

.btn-amber.btn-accent-2,.btn-amber.btn-accent-2:hover {
    border-color: #ffa000!important
}

.overlay-amber.overlay-accent-1 {
    background: #ffe57f;
    background: rgba(255,229,127,.8)
}

.bg-amber.bg-accent-2,.btn-amber.btn-accent-2 {
    background-color: #ffd740!important
}

.amber.accent-2 {
    color: #ffd740!important
}

.bg-amber.bg-accent-2.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(255,215,64,.6)
}

.btn-amber.btn-accent-2:hover {
    background-color: #ff8f00!important
}

.btn-amber.btn-accent-2:active,.btn-amber.btn-accent-2:focus {
    border-color: #ff8f00!important;
    background-color: #ff6f00!important
}

.btn-outline-amber.btn-outline-accent-2 {
    border-color: #ffd740!important;
    color: #ffd740!important
}

.btn-outline-amber.btn-outline-accent-2:hover {
    background-color: #ffd740!important
}

.border-amber.border-accent-2 {
    border: 1px solid #ffd740!important
}

.border-top-amber.border-top-accent-2 {
    border-top: 1px solid #ffd740!important
}

.border-bottom-amber.border-bottom-accent-2 {
    border-bottom: 1px solid #ffd740!important
}

.border-left-amber.border-left-accent-2 {
    border-left: 1px solid #ffd740!important
}

.border-right-amber.border-right-accent-2 {
    border-right: 1px solid #ffd740!important
}

.btn-amber.btn-accent-3,.btn-amber.btn-accent-3:hover {
    border-color: #ffa000!important
}

.overlay-amber.overlay-accent-2 {
    background: #ffd740;
    background: rgba(255,215,64,.8)
}

.bg-amber.bg-accent-3,.btn-amber.btn-accent-3 {
    background-color: #ffc400!important
}

.amber.accent-3 {
    color: #ffc400!important
}

.bg-amber.bg-accent-3.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(255,196,0,.6)
}

.btn-amber.btn-accent-3:hover {
    background-color: #ff8f00!important
}

.btn-amber.btn-accent-3:active,.btn-amber.btn-accent-3:focus {
    border-color: #ff8f00!important;
    background-color: #ff6f00!important
}

.btn-outline-amber.btn-outline-accent-3 {
    border-color: #ffc400!important;
    color: #ffc400!important
}

.btn-outline-amber.btn-outline-accent-3:hover {
    background-color: #ffc400!important
}

.border-amber.border-accent-3 {
    border: 1px solid #ffc400!important
}

.border-top-amber.border-top-accent-3 {
    border-top: 1px solid #ffc400!important
}

.border-bottom-amber.border-bottom-accent-3 {
    border-bottom: 1px solid #ffc400!important
}

.border-left-amber.border-left-accent-3 {
    border-left: 1px solid #ffc400!important
}

.border-right-amber.border-right-accent-3 {
    border-right: 1px solid #ffc400!important
}

.btn-amber.btn-accent-4,.btn-amber.btn-accent-4:hover {
    border-color: #ffa000!important
}

.overlay-amber.overlay-accent-3 {
    background: #ffc400;
    background: rgba(255,196,0,.8)
}

.bg-amber.bg-accent-4,.btn-amber.btn-accent-4 {
    background-color: #ffab00!important
}

.amber.accent-4 {
    color: #ffab00!important
}

.bg-amber.bg-accent-4.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(255,171,0,.6)
}

.btn-amber.btn-accent-4:hover {
    background-color: #ff8f00!important
}

.btn-amber.btn-accent-4:active,.btn-amber.btn-accent-4:focus {
    border-color: #ff8f00!important;
    background-color: #ff6f00!important
}

.btn-outline-amber.btn-outline-accent-4 {
    border-color: #ffab00!important;
    color: #ffab00!important
}

.btn-outline-amber.btn-outline-accent-4:hover {
    background-color: #ffab00!important
}

input:focus~.bg-amber {
    box-shadow: 0 0 0 .075rem #fff,0 0 0 .21rem #ffab00!important
}

.border-amber.border-accent-4 {
    border: 1px solid #ffab00!important
}

.border-top-amber.border-top-accent-4 {
    border-top: 1px solid #ffab00!important
}

.border-bottom-amber.border-bottom-accent-4 {
    border-bottom: 1px solid #ffab00!important
}

.border-left-amber.border-left-accent-4 {
    border-left: 1px solid #ffab00!important
}

.border-right-amber.border-right-accent-4 {
    border-right: 1px solid #ffab00!important
}

.overlay-amber.overlay-accent-4 {
    background: #ffab00;
    background: rgba(255,171,0,.8)
}

.bg-blue-grey.bg-lighten-5,.btn-blue-grey.btn-lighten-5 {
    background-color: #eceff1!important
}

.blue-grey.lighten-5 {
    color: #eceff1!important
}

.bg-blue-grey.bg-lighten-5.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(236,239,241,.6)
}

.btn-blue-grey.btn-lighten-5 {
    border-color: #455a64!important
}

.btn-blue-grey.btn-lighten-5:hover {
    border-color: #455a64!important;
    background-color: #37474f!important
}

.btn-blue-grey.btn-lighten-5:active,.btn-blue-grey.btn-lighten-5:focus {
    border-color: #37474f!important;
    background-color: #263238!important
}

.btn-outline-blue-grey.btn-outline-lighten-5 {
    border-color: #eceff1!important;
    color: #eceff1!important
}

.btn-outline-blue-grey.btn-outline-lighten-5:hover {
    background-color: #eceff1!important
}

.border-blue-grey.border-lighten-5 {
    border: 1px solid #eceff1!important
}

.border-top-blue-grey.border-top-lighten-5 {
    border-top: 1px solid #eceff1!important
}

.border-bottom-blue-grey.border-bottom-lighten-5 {
    border-bottom: 1px solid #eceff1!important
}

.border-left-blue-grey.border-left-lighten-5 {
    border-left: 1px solid #eceff1!important
}

.border-right-blue-grey.border-right-lighten-5 {
    border-right: 1px solid #eceff1!important
}

.overlay-blue-grey.overlay-lighten-5 {
    background: #eceff1;
    background: rgba(236,239,241,.8)
}

.bg-blue-grey.bg-lighten-4,.btn-blue-grey.btn-lighten-4 {
    background-color: #cfd8dc!important
}

.blue-grey.lighten-4 {
    color: #cfd8dc!important
}

.bg-blue-grey.bg-lighten-4.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(207,216,220,.6)
}

.btn-blue-grey.btn-lighten-4 {
    border-color: #455a64!important
}

.btn-blue-grey.btn-lighten-4:hover {
    border-color: #455a64!important;
    background-color: #37474f!important
}

.btn-blue-grey.btn-lighten-4:active,.btn-blue-grey.btn-lighten-4:focus {
    border-color: #37474f!important;
    background-color: #263238!important
}

.btn-outline-blue-grey.btn-outline-lighten-4 {
    border-color: #cfd8dc!important;
    color: #cfd8dc!important
}

.btn-outline-blue-grey.btn-outline-lighten-4:hover {
    background-color: #cfd8dc!important
}

.border-blue-grey.border-lighten-4 {
    border: 1px solid #cfd8dc!important
}

.border-top-blue-grey.border-top-lighten-4 {
    border-top: 1px solid #cfd8dc!important
}

.border-bottom-blue-grey.border-bottom-lighten-4 {
    border-bottom: 1px solid #cfd8dc!important
}

.border-left-blue-grey.border-left-lighten-4 {
    border-left: 1px solid #cfd8dc!important
}

.border-right-blue-grey.border-right-lighten-4 {
    border-right: 1px solid #cfd8dc!important
}

.overlay-blue-grey.overlay-lighten-4 {
    background: #cfd8dc;
    background: rgba(207,216,220,.8)
}

.bg-blue-grey.bg-lighten-3,.btn-blue-grey.btn-lighten-3 {
    background-color: #b0bec5!important
}

.blue-grey.lighten-3 {
    color: #b0bec5!important
}

.bg-blue-grey.bg-lighten-3.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(176,190,197,.6)
}

.btn-blue-grey.btn-lighten-3 {
    border-color: #455a64!important
}

.btn-blue-grey.btn-lighten-3:hover {
    border-color: #455a64!important;
    background-color: #37474f!important
}

.btn-blue-grey.btn-lighten-3:active,.btn-blue-grey.btn-lighten-3:focus {
    border-color: #37474f!important;
    background-color: #263238!important
}

.btn-outline-blue-grey.btn-outline-lighten-3 {
    border-color: #b0bec5!important;
    color: #b0bec5!important
}

.btn-outline-blue-grey.btn-outline-lighten-3:hover {
    background-color: #b0bec5!important
}

.border-blue-grey.border-lighten-3 {
    border: 1px solid #b0bec5!important
}

.border-top-blue-grey.border-top-lighten-3 {
    border-top: 1px solid #b0bec5!important
}

.border-bottom-blue-grey.border-bottom-lighten-3 {
    border-bottom: 1px solid #b0bec5!important
}

.border-left-blue-grey.border-left-lighten-3 {
    border-left: 1px solid #b0bec5!important
}

.border-right-blue-grey.border-right-lighten-3 {
    border-right: 1px solid #b0bec5!important
}

.overlay-blue-grey.overlay-lighten-3 {
    background: #b0bec5;
    background: rgba(176,190,197,.8)
}

.bg-blue-grey.bg-lighten-2,.btn-blue-grey.btn-lighten-2 {
    background-color: #90a4ae!important
}

.blue-grey.lighten-2 {
    color: #90a4ae!important
}

.bg-blue-grey.bg-lighten-2.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(144,164,174,.6)
}

.btn-blue-grey.btn-lighten-2 {
    border-color: #455a64!important
}

.btn-blue-grey.btn-lighten-2:hover {
    border-color: #455a64!important;
    background-color: #37474f!important
}

.btn-blue-grey.btn-lighten-2:active,.btn-blue-grey.btn-lighten-2:focus {
    border-color: #37474f!important;
    background-color: #263238!important
}

.btn-outline-blue-grey.btn-outline-lighten-2 {
    border-color: #90a4ae!important;
    color: #90a4ae!important
}

.btn-outline-blue-grey.btn-outline-lighten-2:hover {
    background-color: #90a4ae!important
}

.border-blue-grey.border-lighten-2 {
    border: 1px solid #90a4ae!important
}

.border-top-blue-grey.border-top-lighten-2 {
    border-top: 1px solid #90a4ae!important
}

.border-bottom-blue-grey.border-bottom-lighten-2 {
    border-bottom: 1px solid #90a4ae!important
}

.border-left-blue-grey.border-left-lighten-2 {
    border-left: 1px solid #90a4ae!important
}

.border-right-blue-grey.border-right-lighten-2 {
    border-right: 1px solid #90a4ae!important
}

.overlay-blue-grey.overlay-lighten-2 {
    background: #90a4ae;
    background: rgba(144,164,174,.8)
}

.bg-blue-grey.bg-lighten-1,.btn-blue-grey.btn-lighten-1 {
    background-color: #78909c!important
}

.blue-grey.lighten-1 {
    color: #78909c!important
}

.bg-blue-grey.bg-lighten-1.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(120,144,156,.6)
}

.bg-blue-grey.bg-glow,.btn-blue-grey.btn-glow,.btn-outline-blue-grey.btn-glow {
    box-shadow: 0 1px 20px 1px rgba(96,125,139,.6)
}

.btn-blue-grey.btn-lighten-1 {
    border-color: #455a64!important
}

.btn-blue-grey.btn-lighten-1:hover {
    border-color: #455a64!important;
    background-color: #37474f!important
}

.btn-blue-grey.btn-lighten-1:active,.btn-blue-grey.btn-lighten-1:focus {
    border-color: #37474f!important;
    background-color: #263238!important
}

.btn-outline-blue-grey.btn-outline-lighten-1 {
    border-color: #78909c!important;
    color: #78909c!important
}

.btn-outline-blue-grey.btn-outline-lighten-1:hover {
    background-color: #78909c!important
}

.border-blue-grey.border-lighten-1 {
    border: 1px solid #78909c!important
}

.border-top-blue-grey.border-top-lighten-1 {
    border-top: 1px solid #78909c!important
}

.border-bottom-blue-grey.border-bottom-lighten-1 {
    border-bottom: 1px solid #78909c!important
}

.border-left-blue-grey.border-left-lighten-1 {
    border-left: 1px solid #78909c!important
}

.border-right-blue-grey.border-right-lighten-1 {
    border-right: 1px solid #78909c!important
}

.overlay-blue-grey.overlay-lighten-1 {
    background: #78909c;
    background: rgba(120,144,156,.8)
}

.blue-grey {
    color: #607d8b!important
}

.bg-blue-grey {
    background-color: #607d8b!important
}

.bg-blue-grey .card-footer,.bg-blue-grey .card-header {
    background-color: transparent
}

.selectboxit.selectboxit-btn.bg-blue-grey {
    background-color: #607d8b!important
}

.alert-blue-grey {
    border-color: #607d8b!important;
    background-color: #87a0ac!important;
    color: #171e21!important
}

.alert-blue-grey .alert-link {
    color: #060809!important
}

.overlay-blue-grey {
    background: #607d8b;
    background: rgba(96,125,139,.8)
}

.btn-blue-grey,.color-info.blue-grey {
    background-color: #607d8b!important
}

.btn-blue-grey {
    border-color: #607d8b!important;
    color: #FFF
}

.btn-blue-grey:hover {
    border-color: #78909c!important;
    background-color: #78909c!important;
    color: #FFF!important
}

.btn-blue-grey:active,.btn-blue-grey:focus {
    border-color: #37474f!important;
    background-color: #37474f!important;
    color: #FFF!important
}

.btn-outline-blue-grey {
    border-color: #607d8b;
    background-color: transparent;
    color: #607d8b
}

.btn-outline-blue-grey:hover {
    background-color: #607d8b;
    color: #FFF!important
}

input[type=checkbox].bg-blue-grey+.custom-control-label:before,input[type=radio].bg-blue-grey+.custom-control-label:before {
    background-color: #607d8b!important
}

.border-blue-grey {
    border: 1px solid #607d8b!important
}

.border-top-blue-grey {
    border-top: 1px solid #607d8b
}

.border-bottom-blue-grey {
    border-bottom: 1px solid #607d8b
}

.border-left-blue-grey {
    border-left: 1px solid #607d8b
}

.border-right-blue-grey {
    border-right: 1px solid #607d8b
}

.bullet.bullet-blue-grey {
    background-color: #607d8b
}

.bg-blue-grey.bg-darken-1,.btn-blue-grey.btn-darken-1 {
    background-color: #546e7a!important
}

.blue-grey.darken-1 {
    color: #546e7a!important
}

.bg-blue-grey.bg-darken-1.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(84,110,122,.6)
}

.btn-blue-grey.btn-darken-1 {
    border-color: #455a64!important
}

.btn-blue-grey.btn-darken-1:hover {
    border-color: #455a64!important;
    background-color: #37474f!important
}

.btn-blue-grey.btn-darken-1:active,.btn-blue-grey.btn-darken-1:focus {
    border-color: #37474f!important;
    background-color: #263238!important
}

.btn-outline-blue-grey.btn-outline-darken-1 {
    border-color: #546e7a!important;
    color: #546e7a!important
}

.btn-outline-blue-grey.btn-outline-darken-1:hover {
    background-color: #546e7a!important
}

.border-blue-grey.border-darken-1 {
    border: 1px solid #546e7a!important
}

.border-top-blue-grey.border-top-darken-1 {
    border-top: 1px solid #546e7a!important
}

.border-bottom-blue-grey.border-bottom-darken-1 {
    border-bottom: 1px solid #546e7a!important
}

.border-left-blue-grey.border-left-darken-1 {
    border-left: 1px solid #546e7a!important
}

.border-right-blue-grey.border-right-darken-1 {
    border-right: 1px solid #546e7a!important
}

.overlay-blue-grey.overlay-darken-1 {
    background: #546e7a;
    background: rgba(84,110,122,.8)
}

.bg-blue-grey.bg-darken-2,.btn-blue-grey.btn-darken-2 {
    background-color: #455a64!important
}

.blue-grey.darken-2 {
    color: #455a64!important
}

.bg-blue-grey.bg-darken-2.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(69,90,100,.6)
}

.btn-blue-grey.btn-darken-2 {
    border-color: #455a64!important
}

.btn-blue-grey.btn-darken-2:hover {
    border-color: #455a64!important;
    background-color: #37474f!important
}

.btn-blue-grey.btn-darken-2:active,.btn-blue-grey.btn-darken-2:focus {
    border-color: #37474f!important;
    background-color: #263238!important
}

.btn-outline-blue-grey.btn-outline-darken-2 {
    border-color: #455a64!important;
    color: #455a64!important
}

.btn-outline-blue-grey.btn-outline-darken-2:hover {
    background-color: #455a64!important
}

.border-blue-grey.border-darken-2 {
    border: 1px solid #455a64!important
}

.border-top-blue-grey.border-top-darken-2 {
    border-top: 1px solid #455a64!important
}

.border-bottom-blue-grey.border-bottom-darken-2 {
    border-bottom: 1px solid #455a64!important
}

.border-left-blue-grey.border-left-darken-2 {
    border-left: 1px solid #455a64!important
}

.border-right-blue-grey.border-right-darken-2 {
    border-right: 1px solid #455a64!important
}

.overlay-blue-grey.overlay-darken-2 {
    background: #455a64;
    background: rgba(69,90,100,.8)
}

.blue-grey.darken-3 {
    color: #37474f!important
}

.bg-blue-grey.bg-darken-3 {
    background-color: #37474f!important
}

.bg-blue-grey.bg-darken-3.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(55,71,79,.6)
}

.btn-blue-grey.btn-darken-3,.btn-blue-grey.btn-darken-3:hover {
    border-color: #455a64!important;
    background-color: #37474f!important
}

.btn-blue-grey.btn-darken-3:active,.btn-blue-grey.btn-darken-3:focus {
    border-color: #37474f!important;
    background-color: #263238!important
}

.btn-outline-blue-grey.btn-outline-darken-3 {
    border-color: #37474f!important;
    color: #37474f!important
}

.btn-outline-blue-grey.btn-outline-darken-3:hover {
    background-color: #37474f!important
}

.border-blue-grey.border-darken-3 {
    border: 1px solid #37474f!important
}

.border-top-blue-grey.border-top-darken-3 {
    border-top: 1px solid #37474f!important
}

.border-bottom-blue-grey.border-bottom-darken-3 {
    border-bottom: 1px solid #37474f!important
}

.border-left-blue-grey.border-left-darken-3 {
    border-left: 1px solid #37474f!important
}

.border-right-blue-grey.border-right-darken-3 {
    border-right: 1px solid #37474f!important
}

.overlay-blue-grey.overlay-darken-3 {
    background: #37474f;
    background: rgba(55,71,79,.8)
}

.bg-blue-grey.bg-darken-4,.btn-blue-grey.btn-darken-4 {
    background-color: #263238!important
}

.blue-grey.darken-4 {
    color: #263238!important
}

.bg-blue-grey.bg-darken-4.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(38,50,56,.6)
}

.btn-blue-grey.btn-darken-4 {
    border-color: #455a64!important
}

.btn-blue-grey.btn-darken-4:hover {
    border-color: #455a64!important;
    background-color: #37474f!important
}

.btn-blue-grey.btn-darken-4:active,.btn-blue-grey.btn-darken-4:focus {
    border-color: #37474f!important;
    background-color: #263238!important
}

.btn-outline-blue-grey.btn-outline-darken-4 {
    border-color: #263238!important;
    color: #263238!important
}

.btn-outline-blue-grey.btn-outline-darken-4:hover {
    background-color: #263238!important
}

input:focus~.bg-blue-grey {
    box-shadow: 0 0 0 .075rem #fff,0 0 0 .21rem #263238!important
}

.border-blue-grey.border-darken-4 {
    border: 1px solid #263238!important
}

.border-top-blue-grey.border-top-darken-4 {
    border-top: 1px solid #263238!important
}

.border-bottom-blue-grey.border-bottom-darken-4 {
    border-bottom: 1px solid #263238!important
}

.border-left-blue-grey.border-left-darken-4 {
    border-left: 1px solid #263238!important
}

.border-right-blue-grey.border-right-darken-4 {
    border-right: 1px solid #263238!important
}

.overlay-blue-grey.overlay-darken-4 {
    background: #263238;
    background: rgba(38,50,56,.8)
}

.bg-grey-blue.bg-lighten-5,.btn-grey-blue.btn-lighten-5 {
    background-color: #eceff1!important
}

.grey-blue.lighten-5 {
    color: #eceff1!important
}

.bg-grey-blue.bg-lighten-5.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(236,239,241,.6)
}

.btn-grey-blue.btn-lighten-5 {
    border-color: #2c303b!important
}

.btn-grey-blue.btn-lighten-5:hover {
    border-color: #2c303b!important;
    background-color: #37474f!important
}

.btn-grey-blue.btn-lighten-5:active,.btn-grey-blue.btn-lighten-5:focus {
    border-color: #37474f!important;
    background-color: #263238!important
}

.btn-outline-grey-blue.btn-outline-lighten-5 {
    border-color: #eceff1!important;
    color: #eceff1!important
}

.btn-outline-grey-blue.btn-outline-lighten-5:hover {
    background-color: #eceff1!important
}

.border-grey-blue.border-lighten-5 {
    border: 1px solid #eceff1!important
}

.border-top-grey-blue.border-top-lighten-5 {
    border-top: 1px solid #eceff1!important
}

.border-bottom-grey-blue.border-bottom-lighten-5 {
    border-bottom: 1px solid #eceff1!important
}

.border-left-grey-blue.border-left-lighten-5 {
    border-left: 1px solid #eceff1!important
}

.border-right-grey-blue.border-right-lighten-5 {
    border-right: 1px solid #eceff1!important
}

.overlay-grey-blue.overlay-lighten-5 {
    background: #eceff1;
    background: rgba(236,239,241,.8)
}

.bg-grey-blue.bg-lighten-4,.btn-grey-blue.btn-lighten-4 {
    background-color: #cfd8dc!important
}

.grey-blue.lighten-4 {
    color: #cfd8dc!important
}

.bg-grey-blue.bg-lighten-4.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(207,216,220,.6)
}

.btn-grey-blue.btn-lighten-4 {
    border-color: #2c303b!important
}

.btn-grey-blue.btn-lighten-4:hover {
    border-color: #2c303b!important;
    background-color: #37474f!important
}

.btn-grey-blue.btn-lighten-4:active,.btn-grey-blue.btn-lighten-4:focus {
    border-color: #37474f!important;
    background-color: #263238!important
}

.btn-outline-grey-blue.btn-outline-lighten-4 {
    border-color: #cfd8dc!important;
    color: #cfd8dc!important
}

.btn-outline-grey-blue.btn-outline-lighten-4:hover {
    background-color: #cfd8dc!important
}

.border-grey-blue.border-lighten-4 {
    border: 1px solid #cfd8dc!important
}

.border-top-grey-blue.border-top-lighten-4 {
    border-top: 1px solid #cfd8dc!important
}

.border-bottom-grey-blue.border-bottom-lighten-4 {
    border-bottom: 1px solid #cfd8dc!important
}

.border-left-grey-blue.border-left-lighten-4 {
    border-left: 1px solid #cfd8dc!important
}

.border-right-grey-blue.border-right-lighten-4 {
    border-right: 1px solid #cfd8dc!important
}

.overlay-grey-blue.overlay-lighten-4 {
    background: #cfd8dc;
    background: rgba(207,216,220,.8)
}

.bg-grey-blue.bg-lighten-3,.btn-grey-blue.btn-lighten-3 {
    background-color: #b0bec5!important
}

.grey-blue.lighten-3 {
    color: #b0bec5!important
}

.bg-grey-blue.bg-lighten-3.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(176,190,197,.6)
}

.btn-grey-blue.btn-lighten-3 {
    border-color: #2c303b!important
}

.btn-grey-blue.btn-lighten-3:hover {
    border-color: #2c303b!important;
    background-color: #37474f!important
}

.btn-grey-blue.btn-lighten-3:active,.btn-grey-blue.btn-lighten-3:focus {
    border-color: #37474f!important;
    background-color: #263238!important
}

.btn-outline-grey-blue.btn-outline-lighten-3 {
    border-color: #b0bec5!important;
    color: #b0bec5!important
}

.btn-outline-grey-blue.btn-outline-lighten-3:hover {
    background-color: #b0bec5!important
}

.border-grey-blue.border-lighten-3 {
    border: 1px solid #b0bec5!important
}

.border-top-grey-blue.border-top-lighten-3 {
    border-top: 1px solid #b0bec5!important
}

.border-bottom-grey-blue.border-bottom-lighten-3 {
    border-bottom: 1px solid #b0bec5!important
}

.border-left-grey-blue.border-left-lighten-3 {
    border-left: 1px solid #b0bec5!important
}

.border-right-grey-blue.border-right-lighten-3 {
    border-right: 1px solid #b0bec5!important
}

.overlay-grey-blue.overlay-lighten-3 {
    background: #b0bec5;
    background: rgba(176,190,197,.8)
}

.bg-grey-blue.bg-lighten-2,.btn-grey-blue.btn-lighten-2 {
    background-color: #6f85ad!important
}

.grey-blue.lighten-2 {
    color: #6f85ad!important
}

.bg-grey-blue.bg-lighten-2.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(111,133,173,.6)
}

.btn-grey-blue.btn-lighten-2 {
    border-color: #2c303b!important
}

.btn-grey-blue.btn-lighten-2:hover {
    border-color: #2c303b!important;
    background-color: #37474f!important
}

.btn-grey-blue.btn-lighten-2:active,.btn-grey-blue.btn-lighten-2:focus {
    border-color: #37474f!important;
    background-color: #263238!important
}

.btn-outline-grey-blue.btn-outline-lighten-2 {
    border-color: #6f85ad!important;
    color: #6f85ad!important
}

.btn-outline-grey-blue.btn-outline-lighten-2:hover {
    background-color: #6f85ad!important
}

.border-grey-blue.border-lighten-2 {
    border: 1px solid #6f85ad!important
}

.border-top-grey-blue.border-top-lighten-2 {
    border-top: 1px solid #6f85ad!important
}

.border-bottom-grey-blue.border-bottom-lighten-2 {
    border-bottom: 1px solid #6f85ad!important
}

.border-left-grey-blue.border-left-lighten-2 {
    border-left: 1px solid #6f85ad!important
}

.border-right-grey-blue.border-right-lighten-2 {
    border-right: 1px solid #6f85ad!important
}

.overlay-grey-blue.overlay-lighten-2 {
    background: #6f85ad;
    background: rgba(111,133,173,.8)
}

.bg-grey-blue.bg-lighten-1,.btn-grey-blue.btn-lighten-1 {
    background-color: #78909c!important
}

.grey-blue.lighten-1 {
    color: #78909c!important
}

.bg-grey-blue.bg-lighten-1.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(120,144,156,.6)
}

.bg-grey-blue.bg-glow,.btn-grey-blue.btn-glow,.btn-outline-grey-blue.btn-glow {
    box-shadow: 0 1px 20px 1px rgba(27,41,66,.6)
}

.btn-grey-blue.btn-lighten-1 {
    border-color: #2c303b!important
}

.btn-grey-blue.btn-lighten-1:hover {
    border-color: #2c303b!important;
    background-color: #37474f!important
}

.btn-grey-blue.btn-lighten-1:active,.btn-grey-blue.btn-lighten-1:focus {
    border-color: #37474f!important;
    background-color: #263238!important
}

.btn-outline-grey-blue.btn-outline-lighten-1 {
    border-color: #78909c!important;
    color: #78909c!important
}

.btn-outline-grey-blue.btn-outline-lighten-1:hover {
    background-color: #78909c!important
}

.border-grey-blue.border-lighten-1 {
    border: 1px solid #78909c!important
}

.border-top-grey-blue.border-top-lighten-1 {
    border-top: 1px solid #78909c!important
}

.border-bottom-grey-blue.border-bottom-lighten-1 {
    border-bottom: 1px solid #78909c!important
}

.border-left-grey-blue.border-left-lighten-1 {
    border-left: 1px solid #78909c!important
}

.border-right-grey-blue.border-right-lighten-1 {
    border-right: 1px solid #78909c!important
}

.overlay-grey-blue.overlay-lighten-1 {
    background: #78909c;
    background: rgba(120,144,156,.8)
}

.grey-blue {
    color: #1b2942!important
}

.alert-grey-blue,.alert-grey-blue .alert-link {
    color: #000!important
}

.bg-grey-blue {
    background-color: #1b2942!important
}

.bg-grey-blue .card-footer,.bg-grey-blue .card-header {
    background-color: transparent
}

.selectboxit.selectboxit-btn.bg-grey-blue {
    background-color: #1b2942!important
}

.alert-grey-blue {
    border-color: #1b2942!important;
    background-color: #304875!important
}

.overlay-grey-blue {
    background: #1b2942;
    background: rgba(27,41,66,.8)
}

.btn-grey-blue,.color-info.grey-blue {
    background-color: #1b2942!important
}

.btn-grey-blue {
    border-color: #1b2942!important;
    color: #FFF
}

.btn-grey-blue:hover {
    border-color: #78909c!important;
    background-color: #78909c!important;
    color: #FFF!important
}

.btn-grey-blue:active,.btn-grey-blue:focus {
    border-color: #37474f!important;
    background-color: #37474f!important;
    color: #FFF!important
}

.btn-outline-grey-blue {
    border-color: #1b2942;
    background-color: transparent;
    color: #1b2942
}

.btn-outline-grey-blue:hover {
    background-color: #1b2942;
    color: #FFF!important
}

input[type=checkbox].bg-grey-blue+.custom-control-label:before,input[type=radio].bg-grey-blue+.custom-control-label:before {
    background-color: #1b2942!important
}

.border-grey-blue {
    border: 1px solid #1b2942!important
}

.border-top-grey-blue {
    border-top: 1px solid #1b2942
}

.border-bottom-grey-blue {
    border-bottom: 1px solid #1b2942
}

.border-left-grey-blue {
    border-left: 1px solid #1b2942
}

.border-right-grey-blue {
    border-right: 1px solid #1b2942
}

.bullet.bullet-grey-blue {
    background-color: #1b2942
}

.bg-grey-blue.bg-darken-1,.btn-grey-blue.btn-darken-1 {
    background-color: #546e7a!important
}

.grey-blue.darken-1 {
    color: #546e7a!important
}

.bg-grey-blue.bg-darken-1.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(84,110,122,.6)
}

.btn-grey-blue.btn-darken-1 {
    border-color: #2c303b!important
}

.btn-grey-blue.btn-darken-1:hover {
    border-color: #2c303b!important;
    background-color: #37474f!important
}

.btn-grey-blue.btn-darken-1:active,.btn-grey-blue.btn-darken-1:focus {
    border-color: #37474f!important;
    background-color: #263238!important
}

.btn-outline-grey-blue.btn-outline-darken-1 {
    border-color: #546e7a!important;
    color: #546e7a!important
}

.btn-outline-grey-blue.btn-outline-darken-1:hover {
    background-color: #546e7a!important
}

.border-grey-blue.border-darken-1 {
    border: 1px solid #546e7a!important
}

.border-top-grey-blue.border-top-darken-1 {
    border-top: 1px solid #546e7a!important
}

.border-bottom-grey-blue.border-bottom-darken-1 {
    border-bottom: 1px solid #546e7a!important
}

.border-left-grey-blue.border-left-darken-1 {
    border-left: 1px solid #546e7a!important
}

.border-right-grey-blue.border-right-darken-1 {
    border-right: 1px solid #546e7a!important
}

.overlay-grey-blue.overlay-darken-1 {
    background: #546e7a;
    background: rgba(84,110,122,.8)
}

.bg-grey-blue.bg-darken-2,.btn-grey-blue.btn-darken-2 {
    background-color: #2c303b!important
}

.grey-blue.darken-2 {
    color: #2c303b!important
}

.bg-grey-blue.bg-darken-2.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(44,48,59,.6)
}

.btn-grey-blue.btn-darken-2 {
    border-color: #2c303b!important
}

.btn-grey-blue.btn-darken-2:hover {
    border-color: #2c303b!important;
    background-color: #37474f!important
}

.btn-grey-blue.btn-darken-2:active,.btn-grey-blue.btn-darken-2:focus {
    border-color: #37474f!important;
    background-color: #263238!important
}

.btn-outline-grey-blue.btn-outline-darken-2 {
    border-color: #2c303b!important;
    color: #2c303b!important
}

.btn-outline-grey-blue.btn-outline-darken-2:hover {
    background-color: #2c303b!important
}

.border-grey-blue.border-darken-2 {
    border: 1px solid #2c303b!important
}

.border-top-grey-blue.border-top-darken-2 {
    border-top: 1px solid #2c303b!important
}

.border-bottom-grey-blue.border-bottom-darken-2 {
    border-bottom: 1px solid #2c303b!important
}

.border-left-grey-blue.border-left-darken-2 {
    border-left: 1px solid #2c303b!important
}

.border-right-grey-blue.border-right-darken-2 {
    border-right: 1px solid #2c303b!important
}

.overlay-grey-blue.overlay-darken-2 {
    background: #2c303b;
    background: rgba(44,48,59,.8)
}

.grey-blue.darken-3 {
    color: #37474f!important
}

.bg-grey-blue.bg-darken-3 {
    background-color: #37474f!important
}

.bg-grey-blue.bg-darken-3.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(55,71,79,.6)
}

.btn-grey-blue.btn-darken-3,.btn-grey-blue.btn-darken-3:hover {
    border-color: #2c303b!important;
    background-color: #37474f!important
}

.btn-grey-blue.btn-darken-3:active,.btn-grey-blue.btn-darken-3:focus {
    border-color: #37474f!important;
    background-color: #263238!important
}

.btn-outline-grey-blue.btn-outline-darken-3 {
    border-color: #37474f!important;
    color: #37474f!important
}

.btn-outline-grey-blue.btn-outline-darken-3:hover {
    background-color: #37474f!important
}

.border-grey-blue.border-darken-3 {
    border: 1px solid #37474f!important
}

.border-top-grey-blue.border-top-darken-3 {
    border-top: 1px solid #37474f!important
}

.border-bottom-grey-blue.border-bottom-darken-3 {
    border-bottom: 1px solid #37474f!important
}

.border-left-grey-blue.border-left-darken-3 {
    border-left: 1px solid #37474f!important
}

.border-right-grey-blue.border-right-darken-3 {
    border-right: 1px solid #37474f!important
}

.overlay-grey-blue.overlay-darken-3 {
    background: #37474f;
    background: rgba(55,71,79,.8)
}

.bg-grey-blue.bg-darken-4,.btn-grey-blue.btn-darken-4 {
    background-color: #263238!important
}

.grey-blue.darken-4 {
    color: #263238!important
}

.bg-grey-blue.bg-darken-4.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(38,50,56,.6)
}

.btn-grey-blue.btn-darken-4 {
    border-color: #2c303b!important
}

.btn-grey-blue.btn-darken-4:hover {
    border-color: #2c303b!important;
    background-color: #37474f!important
}

.btn-grey-blue.btn-darken-4:active,.btn-grey-blue.btn-darken-4:focus {
    border-color: #37474f!important;
    background-color: #263238!important
}

.btn-outline-grey-blue.btn-outline-darken-4 {
    border-color: #263238!important;
    color: #263238!important
}

.btn-outline-grey-blue.btn-outline-darken-4:hover {
    background-color: #263238!important
}

input:focus~.bg-grey-blue {
    box-shadow: 0 0 0 .075rem #fff,0 0 0 .21rem #263238!important
}

.border-grey-blue.border-darken-4 {
    border: 1px solid #263238!important
}

.border-top-grey-blue.border-top-darken-4 {
    border-top: 1px solid #263238!important
}

.border-bottom-grey-blue.border-bottom-darken-4 {
    border-bottom: 1px solid #263238!important
}

.border-left-grey-blue.border-left-darken-4 {
    border-left: 1px solid #263238!important
}

.border-right-grey-blue.border-right-darken-4 {
    border-right: 1px solid #263238!important
}

.overlay-grey-blue.overlay-darken-4 {
    background: #263238;
    background: rgba(38,50,56,.8)
}

.bg-shades.bg-black,.btn-outline-shades.btn-outline-black:hover,.btn-shades.btn-black {
    background-color: #000!important
}

.shades.black {
    color: #000!important
}

.bg-shades.bg-black.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(0,0,0,.6)
}

.btn-outline-shades.btn-outline-black {
    border-color: #000!important;
    color: #000!important
}

.border-shades.border-black {
    border: 1px solid #000!important
}

.border-top-shades.border-top-black {
    border-top: 1px solid #000!important
}

.border-bottom-shades.border-bottom-black {
    border-bottom: 1px solid #000!important
}

.border-left-shades.border-left-black {
    border-left: 1px solid #000!important
}

.border-right-shades.border-right-black {
    border-right: 1px solid #000!important
}

.overlay-shades.overlay-black {
    background: #000;
    background: rgba(0,0,0,.8)
}

.bg-shades.bg-white,.btn-outline-shades.btn-outline-white:hover,.btn-shades.btn-white {
    background-color: #fff!important
}

.shades.white {
    color: #fff!important
}

.bg-shades.bg-white.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(255,255,255,.6)
}

.btn-outline-shades.btn-outline-white {
    border-color: #fff!important;
    color: #fff!important
}

.border-shades.border-white {
    border: 1px solid #fff!important
}

.border-top-shades.border-top-white {
    border-top: 1px solid #fff!important
}

.border-bottom-shades.border-bottom-white {
    border-bottom: 1px solid #fff!important
}

.border-left-shades.border-left-white {
    border-left: 1px solid #fff!important
}

.border-right-shades.border-right-white {
    border-right: 1px solid #fff!important
}

.overlay-shades.overlay-white {
    background: #fff;
    background: rgba(255,255,255,.8)
}

.bg-shades.bg-transparent,.btn-outline-shades.btn-outline-transparent:hover,.btn-shades.btn-transparent {
    background-color: transparent!important
}

.shades.transparent {
    color: transparent!important
}

.bg-shades.bg-transparent.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(0,0,0,.6)
}

.btn-outline-shades.btn-outline-transparent {
    border-color: transparent!important;
    color: transparent!important
}

input:focus~.bg-shades {
    box-shadow: 0 0 0 .075rem #fff,0 0 0 .21rem transparent!important
}

.border-shades.border-transparent {
    border: 1px solid transparent!important
}

.border-top-shades.border-top-transparent {
    border-top: 1px solid transparent!important
}

.border-bottom-shades.border-bottom-transparent {
    border-bottom: 1px solid transparent!important
}

.border-left-shades.border-left-transparent {
    border-left: 1px solid transparent!important
}

.border-right-shades.border-right-transparent {
    border-right: 1px solid transparent!important
}

.overlay-shades.overlay-transparent {
    background: 0 0;
    background: rgba(0,0,0,.8)
}

.bg-black.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(0,0,0,.6)
}

.border-top-black {
    border-top: 1px solid #000
}

.border-bottom-black {
    border-bottom: 1px solid #000
}

.border-left-black {
    border-left: 1px solid #000
}

.border-right-black {
    border-right: 1px solid #000
}

.bg-white.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(255,255,255,.6)
}

.border-top-white {
    border-top: 1px solid #fff
}

.border-bottom-white {
    border-bottom: 1px solid #fff
}

.border-left-white {
    border-left: 1px solid #fff
}

.border-right-white {
    border-right: 1px solid #fff
}

.transparent {
    color: transparent
}

.bg-transparent {
    background-color: transparent
}

.bg-transparent.bg-glow {
    box-shadow: 0 1px 20px 1px rgba(0,0,0,.6)
}

input:focus~.bg-transparent {
    box-shadow: 0 0 0 .075rem #fff,0 0 0 .21rem transparent
}

.border-transparent {
    border: 1px solid transparent
}

.border-top-transparent {
    border-top: 1px solid transparent
}

.border-bottom-transparent {
    border-bottom: 1px solid transparent
}

.border-left-transparent {
    border-left: 1px solid transparent
}

.border-right-transparent {
    border-right: 1px solid transparent
}
